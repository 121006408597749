import {
  Heading,
  Stack,
  Link,
  ListItem,
  OrderedList,
  Button,
  useToast,
} from "@chakra-ui/react";
import { DEFAULT_TOAST_TIME } from "configuration";
import { ButtonChangeEnrolment } from "components/user/ButtonChangeEnrolment";
import CountdownLoader from "components/CountdownLoader";
import React from "react";
import { useState } from "react";
import { LoaderBasic } from "components/LoaderBasic";

function NuovoWithoutReset({
  verifyEnrollment,
  globalEffectUpdate,
  canChangeEnrollmentType,
  sendMessage,
  buttonIsDisabled,
  setButtonIsDisabled,
}) {
  const toast = useToast();
  const [loading, setLoading] = useState(false);

  async function onClick() {
    setLoading(true);
    const { data, status } = await verifyEnrollment();
    if (status !== 200 && status === 400) {
      globalEffectUpdate();
    } else if (data.status) {
      globalEffectUpdate();
    } else {
      toast({
        title: "No se ha podido verificar la instalación de Nuovo",
        description:
          "Termina el proceso hasta que aparezca tu código de confirmación",
        status: "error",
        duration: 2 * DEFAULT_TOAST_TIME,
        isClosable: true,
      });
    }
    setLoading(false);
  }

  if (loading) return <LoaderBasic />;

  return (
    <Stack>
      <Heading size={"sm"} color="blue.700" fontWeight={"medium"}>
        Para continuar con tu solicitud es necesario instalar la aplicación
        Nuovo. Así tu celular actuará como una garantía y podremos ofrecerte
        tasas mucho más atractivas para tu préstamo <br />
      </Heading>
      <Heading size={"sm"} color="blue.700" fontWeight={"medium"}>
        Sigue estos pasos, te recomendamos que los leas todos antes de comenzar
      </Heading>
      <OrderedList>
        <ListItem>
          Descarga e instala la aplicación dando click{" "}
          <Link
            color={"blue.500"}
            href="https://dl.nuovopay.com/apk/?c=SUZxTjlUbzhsZ1BNQlNxcDFBQURsUT09Cg=="
          >
            aquí
          </Link>
        </ListItem>
        <ListItem>
          Abre la aplicación (esta puede estar en tus descargas) y selecciona
          "Enrolar con código"
        </ListItem>
        <ListItem>
          Ingresa el código que te enviamos por SMS para nuovo y completa la
          configuración aceptando todos los permisos
        </ListItem>
        <ListItem>
          Ya que te aparezca la pantalla de "Bienvenido a Bund" regresa aquí
        </ListItem>
        <ListItem>
          Una vez que hayas finalizado el enrolamiento, dá click en el siguiente
          botón
        </ListItem>
      </OrderedList>
      {buttonIsDisabled ? (
        <CountdownLoader
          onCountdownEnd={() => setButtonIsDisabled(false)}
          text="En caso de no recibir el mensaje, podrá solicitar el reenvió en unos instantes"
          timer={120}
        />
      ) : (
        <Button onClick={sendMessage} colorScheme={"blue"} variant="outline">
          Reenviar mensaje
        </Button>
      )}
      {canChangeEnrollmentType && (
        <ButtonChangeEnrolment globalEffectUpdate={globalEffectUpdate} />
      )}
      <Button onClick={onClick} colorScheme={"blue"} variant="outline">
        He instalado la aplicación de Nuovo
      </Button>
    </Stack>
  );
}

export default NuovoWithoutReset;
