import { connect } from "react-redux";
import {
  Stack,
  Flex,
  Button,
  Text,
  VStack,
  useBreakpointValue,
} from "@chakra-ui/react";
import React from "react";

function Aviso() {
  return (
    <Flex
      w={"full"}
      h={useBreakpointValue({ base: "80vh", md: "60vh" })}
      bgColor={"#EFF7FE"}
    >
      <VStack
        w={"full"}
        justify={"center"}
        px={useBreakpointValue({ base: 4, md: 8 })}
      >
        <Stack maxW={"2xl"} align={"center"} spacing={6}>
          <Button
            as={"a"}
            href={"/signup"}
            bg={"blue.500"}
            rounded={"full"}
            color={"white"}
            _hover={{ bg: "blue.700" }}
          >
            Comenzar
          </Button>
          <Text
            align={"center"}
            color={"#1d6dbc"}
            fontWeight={600}
            lineHeight={1.2}
            fontSize={useBreakpointValue({ base: "3xl", md: "4xl" })}
          >
            ¡Olvídate de los intereses moratorios!
            <br />
            <br />
            Tu dispositivo móvil se convierte en un colateral virtual para Bund.
            <br />
            Siendo puntual en tus pagos, tu teléfono siempre estará activo.
          </Text>
        </Stack>
      </VStack>
    </Flex>
  );
}

const mapStateToProp = () => ({});

export default connect(mapStateToProp, {})(Aviso);
