import { Route, Routes } from "react-router";
import DatosPersonales from "pages/user/PersonalDataPage";
import DatosBanc from "pages/user/BankDataPage";
import EnrolledNuovo from "pages/user/Enrollment";
import RequestAmount from "pages/user/RequestAmountPage";
import Contrato from "components/pageToRender/contrato";
import RequestOtherCredit from "pages/user/RequestOtherCreditPage";
import MoneyOnTheWay from "pages/user/MoneyOnTheWay";
import RechazadoPage from "pages/user/RejectedPage";
import CreditoPage from "pages/user/CreditPage";
import PendingEnrollmentPage from "pages/user/PendingEnrollment";
import redux from "reducers/utils/Redux";
import { useState, useEffect, useCallback } from "react";
import { StepDictionary } from "configuration";
import Loading from "components/Loading";
import { Navigate } from "react-router-dom";
import Footer from "components/navigation/Footer";
import NavbarCuenta from "components/navigation/NavbarCuenta";
import Layout from "components/layouts/Layout";
import PersonalDataPage from "pages/user/PersonalDataPage";
import { useNavigate } from "react-router-dom";
import ParametricModelPage from "pages/user/ParametricModelPage";
import OCRConfirmation from "components/nuovo/OCRConfirmation";
import { ScrollToTop } from "components/ScrollToTop";
import React from "react";
import { analytcEvent } from "analytics";
import * as Sentry from "@sentry/react";
import ContactFormPage from "./ContactFormPage";
import ValidateCodePage from "./ValidateCodePage";
import { BuroAuthorization } from "components/signup/BuroAuthorization";

const steps = StepDictionary;

function UserRouter({ loadClient, logout, confirmOCRInfo }) {
  const navigate = useNavigate();
  const [client, setClient] = useState(null);
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [update, setUpdate] = useState(0);

  const globalEffectUpdate = () => {
    setUpdate(update + 1);
  };

  const onLogout = useCallback(() => {
    logout();
    navigate("/");
    Sentry.setUser(null);
    Sentry.captureMessage("User logged out");
  }, [logout, navigate]);

  useEffect(() => {
    const load = async () => {
      const { data, status } = await loadClient();
      if (status === 200) {
        setClient(data);
        Sentry.setUser({ id: data.id });
        Sentry.captureMessage("Update user data");
      } else {
        Sentry.captureMessage("Error loading user data");
        onLogout();
      }
    };
    load();
  }, [loadClient, update, navigate]);

  if (!client) {
    return <Loading />;
  }
  if (client && !isAuthenticated) {
    setIsAuthenticated(true);
  }
  if (!isAuthenticated) {
    return <Navigate to="/login" />;
  }

  return (
    <Layout>
      <ScrollToTop />
      <NavbarCuenta onLogout={onLogout} user={client} />
      <Routes>
        <Route
          path="*"
          element={
            <Selector
              user={client}
              loadClient={loadClient}
              globalEffectUpdate={globalEffectUpdate}
              confirmOCRInfo={confirmOCRInfo}
            />
          }
        />

        <Route path="/money" element={<MoneyOnTheWay user={client} />} />
        <Route path="/credit" element={<CreditoPage user={client} />} />
      </Routes>
      <Footer />
    </Layout>
  );
}

function Selector({ user, globalEffectUpdate, confirmOCRInfo, loadClient }) {
  if (user.step === steps.CONFIRM_EMAIL) {
    return (
      <PersonalDataPage user={user} globalEffectUpdate={globalEffectUpdate} />
    );
  }
  if (user.step === steps.BANK_DATA) {
    analytcEvent("personalData", "bank_data");
    return <DatosBanc user={user} globalEffectUpdate={globalEffectUpdate} />;
  }
  if (user.step === steps.ENROLLED_PHONE) {
    return (
      <EnrolledNuovo user={user} globalEffectUpdate={globalEffectUpdate} />
    );
  }
  if (user.step === steps.REFERENCE_LOAN) {
    analytcEvent("personalData", "reference_loan");
    return <ContactFormPage globalEffectUpdate={globalEffectUpdate} />;
  }
  if (user.step === steps.CONFIRM_REFERENCE) {
    return <ValidateCodePage globalEffectUpdate={globalEffectUpdate} />;
  }
  if (user.step === steps.PENDING_PHONE_ENROLLMENT) {
    analytcEvent("personalData", "pending_enrollment");
    return (
      <PendingEnrollmentPage
        user={user}
        globalEffectUpdate={globalEffectUpdate}
      />
    );
  }
  if (user.step === steps.CREATE_LOAD) {
    analytcEvent("personalData", "request_amount");
    return (
      <RequestAmount user={user} globalEffectUpdate={globalEffectUpdate} />
    );
  }
  if (user.step === steps.SIGN_CONTRACT) {
    analytcEvent("personalData", "sign_contract");
    return <Contrato user={user} globalEffectUpdate={globalEffectUpdate} />;
  }
  if (user.step === steps.SEE_CREDIT) {
    analytcEvent("personalData", "see_credit");
    return <CreditoPage user={user} globalEffectUpdate={globalEffectUpdate} />;
  }
  if (user.step === steps.RENEW_LOAN) {
    analytcEvent("personalData", "renew_credit");
    return <CreditoPage user={user} globalEffectUpdate={globalEffectUpdate} />;
  }
  if (user.step === steps.NO_RENEWAL_LOAN) {
    analytcEvent("personalData", "renew_credit");
    return (
      <RequestOtherCredit user={user} globalEffectUpdate={globalEffectUpdate} />
    );
  }
  if (user.step === steps.SAVE_ADDRESS) {
    return (
      <PersonalDataPage user={user} globalEffectUpdate={globalEffectUpdate} />
    );
  }
  if (user.step === steps.VERIFY_RENEWAL_EMAIL_INFORMATION) {
    return (
      <PersonalDataPage user={user} globalEffectUpdate={globalEffectUpdate} />
    );
  }
  if (user.step === steps.CONFIRM_BANK_DATA) {
    return (
      <PersonalDataPage user={user} globalEffectUpdate={globalEffectUpdate} />
    );
  }
  if (user.step === steps.REFUSED) {
    return (
      <RechazadoPage user={user} globalEffectUpdate={globalEffectUpdate} />
    );
  }
  if (user.step === steps.PARAMETRIC_MODEL) {
    analytcEvent("personalData", "parametric_model");
    return (
      <ParametricModelPage
        globalEffectUpdate={globalEffectUpdate}
        user={user}
      />
    );
  }
  if (user.step === steps.CONFIRM_OCR_INFO) {
    analytcEvent("personalData", "ocr_confirm");
    return (
      <OCRConfirmation
        confirmOCRInfo={confirmOCRInfo}
        user={user}
        loadClient={loadClient}
        globalEffectUpdate={globalEffectUpdate}
      />
    );
  }
  if (user.step === steps.INE_MANUAL_RENEWAL) {
    return (
      <DatosPersonales user={user} globalEffectUpdate={globalEffectUpdate} />
    );
  } else {
    return (
      <DatosPersonales user={user} globalEffectUpdate={globalEffectUpdate} />
    );
  }
}

export default redux(UserRouter);
