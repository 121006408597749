import { combineReducers } from "redux";
import auth from "./auth";
import nubarium from "./nubarium";
import infoPersonal from "./infoPersonal";
import clientes from "./cliente";
import prestamos from "./prestamo";
import pagos from "./pago";
import deviceReducer from "./deviceReducer";

export default combineReducers({
  auth,
  nubarium,
  infoPersonal,
  clientes,
  prestamos,
  pagos,
  deviceReducer,
});
