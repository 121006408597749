import {
  SMS_SUCCESS,
  SMS_FAIL,
  UPDATE_INFO_SUCESS,
  UPDATE_INFO_FAIL,
} from "./types";

import axios from "axios";
import DeviceDetector from "device-detector-js";
const baseurl = process.env.REACT_APP_BASE_URL;
export const GET_DEVICE_INFO = "GET_DEVICE_INFO";

export const validate_phoneNumber = (number) => async (dispatch) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `JWT ${localStorage.getItem("access")}`,
    },
  };
  const body = JSON.stringify({
    phone: number,
  });

  try {
    const res = await axios.post(
      baseurl + "/api/usuarios/confirm/phone/",
      body,
      config
    );

    if (res.status === 200) {
      localStorage.setItem("tokensms", res.data.data.token);
      dispatch({
        type: SMS_SUCCESS,
        payload: res.data,
      });
      return res;
    } else {
      dispatch({ type: SMS_FAIL });
    }
  } catch (error) {
    dispatch({ type: SMS_FAIL });
    return error;
  }
};

export const userInfo = (dataForm) => async (dispatch) => {
  // const regexAcentos = /[áéíóúÁÉÍÓÚ]/;
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };

  const body = JSON.stringify({
    email: dataForm.email,
    nombre: dataForm.first_name,
    segundo_nombre: dataForm.second_name,
    apellidoP: dataForm.apellido,
    apellidoM: dataForm.materno,
    telefono: dataForm.telefono,
    has_bank_account: dataForm.cuenta,
    tipo_usuarios: "2",
    password: dataForm.password,
  });

  try {
    const res = await axios.post(baseurl + `/auth/users/`, body, config);
    if (res.status === 201) {
      dispatch({
        type: "USER_INFO",
        payload: res.data,
      });
      return res;
    }
  } catch (error) {
    dispatch({
      type: "USER_INFO_FAIL",
    });
    return error.response.data;
  }
};

export const updateInfoUser = (data) => async (dispatch) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `JWT ${localStorage.getItem("access")}`,
    },
  };
  const body = JSON.stringify({
    email: data.email,
    nombre: data.first_name,
    segundo_nombre: data.second_name,
    apellidoP: data.apellido,
    apellidoM: data.materno,
    telefono: data.telefono,
  });

  try {
    const res = await axios.put(
      baseurl + "/api/usuarios/renovar/credito",
      body,
      config
    );
    if (res.status === 200) {
      dispatch({
        type: UPDATE_INFO_SUCESS,
        payload: res.data,
      });
      return res;
    } else {
      dispatch({ type: UPDATE_INFO_FAIL });
    }
  } catch (error) {
    dispatch({ type: UPDATE_INFO_FAIL });
    return error;
  }
};

export const getDeviceInfo = () => {
  const deviceDetector = new DeviceDetector();
  const userAgent = navigator.userAgent;
  const parsedDeviceInfo = deviceDetector.parse(userAgent);

  return {
    type: GET_DEVICE_INFO,
    payload: parsedDeviceInfo,
  };
};

export const sendDeviceInfo = (phoneInfo) => async (dispatch) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };

  const body = JSON.stringify({
    marca: phoneInfo.brand,
    modelo: phoneInfo.model,
  });

  try {
    const res = await axios.post(
      baseurl + "/api/usuarios/telefono/",
      body,
      config
    );
    dispatch({
      type: "SEND_DEVICE_INFO",
      payload: res.data,
    });
    return res;
  } catch (error) {
    return error.response.data;
  }
};

export const sendVerificationEmailCode = (email, token) => async (dispatch) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };

  const body = JSON.stringify({
    email: email,
    token: token,
  });

  try {
    const res = await axios.post(
      baseurl + "/api/usuarios/validar/email/",
      body,
      config
    );
    dispatch({
      type: "SEND_VERIFICATION_EMAIL_CODE",
      payload: res.data,
    });
    return res.data;
  } catch (error) {
    return error.response.data;
  }
};

export const verificationEmailCode = (email, code) => async (dispatch) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };

  const body = JSON.stringify({
    email: email,
    codigo: code,
  });

  try {
    const res = await axios.post(
      baseurl + "/api/usuarios/validar/codigo/email/",
      body,
      config
    );
    dispatch({
      type: "VERIFICATION_EMAIL_CODE",
      payload: res.data,
    });
    return res.data;
  } catch (error) {
    return error.response.data;
  }
};

export const renovacionSendVerificationEmailCode =
  (email) => async (dispatch) => {
    const config = {
      headers: {
        Authorization: `JWT ${localStorage.getItem("access")}`,
        "Content-Type": "application/json",
      },
    };

    const body = JSON.stringify({
      email: email,
      // token:token
    });

    try {
      const res = await axios.post(
        baseurl + "/api/usuarios/validar/email/renovacion",
        body,
        config
      );
      dispatch({
        type: "RENOVACION_SEND_VERIFICATION_EMAIL_CODE",
        payload: res.data,
      });
      return res.data;
    } catch (error) {
      return error.response.data;
    }
  };

export const renovacionVerificationEmailCode =
  (email, code) => async (dispatch) => {
    const config = {
      headers: {
        Authorization: `JWT ${localStorage.getItem("access")}`,
        "Content-Type": "application/json",
      },
    };

    const body = JSON.stringify({
      email: email,
      codigo: code,
    });

    try {
      const res = await axios.post(
        baseurl + "/api/usuarios/validar/codigo/email/renovacion",
        body,
        config
      );
      dispatch({
        type: "RENOVACION_VERIFICATION_EMAIL_CODE",
        payload: res.data,
      });

      return res.data;
    } catch (error) {
      return error.response.data;
    }
  };

export const confirmName = (data) => async (dispatch) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `JWT ${localStorage.getItem("access")}`,
    },
  };
  const body = JSON.stringify({
    nombre: data.first_name,
    segundo_nombre: data.second_name,
    apellidoP: data.apellido,
    apellidoM: data.materno,
  });

  try {
    const res = await axios.post(
      baseurl + "/api/usuarios/modificar/",
      body,
      config
    );
    dispatch({
      type: "CONFIRM_NAME",
      payload: res.data,
    });

    return res;
  } catch (error) {
    return error;
  }
};

export const renovarCredito =
  (data, dataForm, alcaldia, ciudad, estado) => async (dispatch) => {
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `JWT ${localStorage.getItem("access")}`,
      },
    };
    const body = JSON.stringify({
      email: dataForm.email,
      telefono: dataForm.telefono,
      calle: data.calle,
      no_exterior: parseInt(data.exterior),
      no_interior: data.interior === "" ? null : parseInt(data.interior),
      cp: data.cp,
      alcaldia,
      colonia: data.colonia,
      ciudad,
      estado,
      // nip:parseInt(data.nip),
      // step: 4
    });
    try {
      const res = await axios.put(
        `${baseurl}/api/usuarios/renovar/credito`,
        body,
        config
      );
      dispatch({
        type: "RENOVAR_CREDITO",
        payload: res,
      });
      return res;
    } catch (error) {
      // alert("Ocurrió un error");
      return error;
    }
  };
