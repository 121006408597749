import Actions from "./Actions";

class Create extends Actions {
  state;

  constructor() {
    super();
    this.state = {};
  }

  sendVerificationEmailCode() {
    return this.post("/users/email/verify", {}, SEND_EMAIL_VERIFICATION_OK);
  }

  verifyVerificationEmailCode(code) {
    const body = {
      value: code,
    };
    return this.post(
      "/users/email/verify/validate",
      body,
      EMAIL_VERIFICATION_OK
    );
  }

  saveUserInfo(dataForm) {
    const body = {
      email: dataForm.email,
      nombre: dataForm.first_name,
      segundo_nombre: dataForm.second_name,
      apellidoP: dataForm.apellido,
      apellidoM: dataForm.materno,
      telefono: dataForm.telefono,
      has_bank_account: dataForm.cuenta,
      password: dataForm.password,
      token: dataForm.token,
    };
    return this.post("/clients/", body, "USER_INFO");
  }

  // client/phone/code/renew
  sendSMSRenew() {
    return this.post("/clients/phone/code/renew", {}, SEND_SMS_OK);
  }
  validateNipRenew(nip) {
    const body = {
      code: nip,
    };
    return this.post(
      "/clients/phone/code/renewal/validate",
      body,
      PHONE_VERIFICATION_OK
    );
  }

  validateNip(nip) {
    const body = {
      code: nip,
    };
    return this.post(
      "/users/phone/verify/validate",
      body,
      PHONE_VERIFICATION_OK
    );
  }

  sendSMS() {
    return this.post("/users/phone/verify", {}, SEND_SMS_OK);
  }
  saveBankData(data) {
    return this.post("/clients/bank-data", data, BANK_DATA_OK);
  }

  confirmBankData(data) {
    return this.post("/clients/bank_data/confirm", data, BANK_DATA_OK);
  }

  getBankData() {
    return this.get("/clients/bank_data_info", BANK_DATA_OK);
  }

  getBinInformation(bin) {
    const body = {
      bin: bin,
    };
    return this.post("/clients/bin-information", body, BIN_INFORMATION_OK);
  }

  requestAmount(body) {
    return this.post("/clients/loan", body, REQUEST_AMOUNT_OK);
  }

  getContractTicket() {
    return this.get("/clients/contract-ticket", CONTRACT_TICKET_OK);
  }

  validateFAD() {
    return this.get("/clients/contract/verify", FAD_VALIDATION_OK);
  }
}
export default Create;

export const SEND_EMAIL_VERIFICATION_ERROR = "SEND_EMAIL_VERIFICATION_ERROR";
export const SEND_EMAIL_VERIFICATION_OK = "SEND_EMAIL_VERIFICATION_OK";
export const EMAIL_VERIFICATION_ERROR = "EMAIL_VERIFICATION_ERROR";
export const EMAIL_VERIFICATION_OK = "EMAIL_VERIFICATION_OK";

export const PHONE_VERIFICATION_ERROR = "EMAIL_VERIFICATION_ERROR";
export const PHONE_VERIFICATION_OK = "EMAIL_VERIFICATION_OK";

export const SEND_SMS_ERROR = "SEND_SMS_ERROR";
export const SEND_SMS_OK = "SEND_SMS_OK";

export const BANK_DATA_OK = "BANK_DATA_OK";
export const BANK_DATA_FAIL = "BANK_DATA_FAIL";

export const BIN_INFORMATION_OK = "BIN_INFORMATION_OK";
export const BIN_INFORMATION_FAIL = "BIN_INFORMATION_FAIL";

export const REQUEST_AMOUNT_OK = "REQUEST_AMOUNT_OK";
export const REQUEST_AMOUNT_FAIL = "REQUEST_AMOUNT_FAIL";

export const CONTRACT_TICKET_OK = "CONTRACT_TICKET_OK";
export const CONTRACT_TICKET_FAIL = "CONTRACT_TICKET_FAIL";

export const FAD_VALIDATION_OK = "FAD_VALIDATION_OK";
export const FAD_VALIDATION_FAIL = "FAD_VALIDATION_FAIL";
