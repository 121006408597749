import { RfcAndCurp } from "components/user/RfcAndCurp";
import React, { useState } from "react";
import redux from "reducers/utils/Redux";
import { useToast } from "@chakra-ui/react";
import { LoaderBasic } from "components/LoaderBasic";
import { DEFAULT_TOAST_TIME } from "configuration";

const RfcAndCurpPage = ({ user, globalEffectUpdate, rfcAndCurp }) => {
  const [loading, setLoading] = useState(false);
  const toast = useToast();

  const userData = {
    name: user.name,
    second_name: user.second_name,
    first_surname: user.first_surname,
    second_surname: user.second_surname,
    birth_date: user.birth_date,
  };

  const onSubmit = async (dataForm) => {
    setLoading(true);

    const formattedData = {
      rfc: dataForm.rfc,
      curp: dataForm.curp,
      nombre: dataForm.name,
      segundo_nombre: dataForm.secondName,
      apellidoP: dataForm.firstSurname,
      apellidoM: dataForm.secondSurname,
      ocupacion: dataForm.ocupacion,
    };

    const res = await rfcAndCurp(formattedData);
    const { status, data } = res;

    if (status === 200 && data.status) {
      globalEffectUpdate();
      return;
    }

    if (data.errorCode === 1) {
      toast({
        title: "El RFC no es válido",
        status: "error",
        duration: DEFAULT_TOAST_TIME,
        isClosable: true,
      });
    } else if (data.errorCode === 2) {
      toast({
        title: "El CURP no es válido",
        status: "error",
        duration: DEFAULT_TOAST_TIME,
        isClosable: true,
      });
    } else if (data.errorCode === 3) {
      toast({
        title: "Error en las validaciones",
        description: "Revisa los campos del formulario",
        status: "error",
        duration: DEFAULT_TOAST_TIME,
        isClosable: true,
      });
    } else if (data.errorCode === 4) {
      toast({
        title: "La persona es menor de edad",
        status: "error",
        duration: DEFAULT_TOAST_TIME,
        isClosable: true,
      });
    } else if (data.errorCode === 5) {
      toast({
        title: "RFC y CURP no coinciden",
        status: "error",
        duration: DEFAULT_TOAST_TIME,
        isClosable: true,
      });
    } else if (data.errorCode === 6) {
      toast({
        title: "El CURP ya existe",
        status: "error",
        duration: DEFAULT_TOAST_TIME,
        isClosable: true,
      });
      globalEffectUpdate();
    }

    setLoading(false);
  };

  if (loading) return <LoaderBasic />;

  return <RfcAndCurp user={userData} onSubmit={onSubmit} />;
};

export default redux(RfcAndCurpPage);
