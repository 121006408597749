import {
  Container,
  Center,
  Accordion,
  AccordionButton,
  AccordionItem,
  AccordionIcon,
  Box,
  AccordionPanel,
} from "@chakra-ui/react";
import { connect } from "react-redux";
import React from "react";

function FormasDePago() {
  return (
    <Container py={5} minW={"95vw"}>
      <Center color={"blue.700"} fontSize={"3xl"} fontWeight={"bold"}>
        Formas de Pago
      </Center>
      <Accordion py="10" allowMultiple>
        <AccordionItem>
          <h2>
            <AccordionButton>
              <Box color={"blue.600"} as="span" flex="1" textAlign="left">
                Domiciliación
              </Box>
              <AccordionIcon />
            </AccordionButton>
          </h2>
          <AccordionPanel pb={4}>
            Es el método predeterminado de cobro en Bund. Nosotros realizaremos
            el cargo automático de manera semanal en tu día de pago. Lo único
            que debes hacer es tener el dinero disponible en la cuenta en la
            cual se te depositó tu crédito.
          </AccordionPanel>
        </AccordionItem>
        <AccordionItem>
          <h2>
            <AccordionButton>
              <Box color={"blue.600"} as="span" flex="1" textAlign="left">
                Transferencia electrónica
              </Box>
              <AccordionIcon />
            </AccordionButton>
          </h2>
          <AccordionPanel pb={4}>
            Puedes realizar tu pago semanal mediante transferencia electrónica
            SPEI en tu aplicación o portal bancario. Dado que la domiciliación
            (cobro automático) es el método predeterminado, necesitaremos tener
            tu transferencia un día antes de tu fecha de pago, de lo contrario,
            se realizará la domiciliación. De todas maneras, ese pago será
            contemplado como abono a tu deuda.
            <br /> *Es de suma importancia que coloques tu referencia asignada
            en el concepto de la transferencia para que la podamos ubicar
          </AccordionPanel>
        </AccordionItem>
        <AccordionItem>
          <h2>
            <AccordionButton>
              <Box color={"blue.600"} as="span" flex="1" textAlign="left">
                Depósito en sucursales Banorte y tiendas de autoservicio
              </Box>
              <AccordionIcon />
            </AccordionButton>
          </h2>
          <AccordionPanel pb={4}>
            Puedes realizar tu pago semanal en sucursales Banorte y tiendas de
            autoservicio a nuestra cuenta del banco Banorte, la cual puedes
            encontrar en tu contrato de crédito. <br />
            *Es de suma importancia que coloques tu referencia asignada en el
            concepto del depósito para que lo podamos ubicar <br /> **Las
            tiendas de autoservicio pueden cobrar comisiones por el depósito
          </AccordionPanel>
        </AccordionItem>
      </Accordion>
    </Container>
  );
}

const mapStateToProp = () => ({});

export default connect(mapStateToProp, {})(FormasDePago);
