import {
  GET_MY_CREDIT_SUCCESS,
  GET_MY_CREDIT_FAIL,
  GET_LOANS_LIST_SUCCESS,
  GET_LOANS_LIST_FAIL,
} from "redux/actions/prestamos/types";
const initialState = {
  misPrestamos: null,
  prestamos_list: null,
};

export default function prestamos(state = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case GET_MY_CREDIT_SUCCESS:
      return {
        ...state,
        misPrestamos: payload,
      };
    case GET_MY_CREDIT_FAIL:
      return {
        ...state,
        misPrestamos: null,
      };
    case GET_LOANS_LIST_SUCCESS:
      return {
        ...state,
        prestamos_list: payload,
      };
    case GET_LOANS_LIST_FAIL:
      return {
        ...state,
        prestamos_list: null,
      };
    default:
      return state;
  }
}
