import React, { useState, useEffect } from "react";
import { Heading, Text, Box, Flex, Button } from "@chakra-ui/react";
import { ScrollToTop } from "./ScrollToTop";
import { ERROR_INTERVAL } from "configuration";

export const LoaderBasic = () => {
  const [timeout, setTimeout] = useState(false);

  useEffect(() => {
    setInterval(() => {
      setTimeout(true);
    }, ERROR_INTERVAL);
  }, []);

  if (timeout) {
    return (
      <>
        <ScrollToTop />
        <Box mt="80" bgColor="white" alignItems="center" mx="10" my="10">
          <Flex direction="column" align="center" mx="10" my="10">
            <Heading as="h2" size="xl">
              Error
            </Heading>
            <Text alignText="center" color={"gray.500"}>
              Ha ocurrido un error, por favor refresque la página.
            </Text>
            <Button
              mt="5"
              colorScheme="blue"
              onClick={() => window.location.reload()}
            >
              Recargar
            </Button>
          </Flex>
        </Box>
      </>
    );
  }

  return (
    <>
      <ScrollToTop />
      <Box mt="80" bgColor="white" alignItems="center" mx="10" my="10">
        <Flex direction="column" align="center" mx="10" my="10">
          <Heading as="h2" size="xl">
            Espere un momento
          </Heading>
          <div className="ploader"></div>
          <Text alignText="center" color={"gray.500"}>
            Su información está siendo procesada, por favor espere.
            <Text fontSize="sm">Este proceso puede tomar varios minutos.</Text>
          </Text>
        </Flex>
      </Box>
    </>
  );
};
