import { Container, Center, Text } from "@chakra-ui/react";
import React  from 'react';

function CookiesPage() {
  return (
    <>
      <Center m={5} fontSize={"4xl"} color={"blue.700"} fontWeight="normal">
        ¿QUÉ SON LAS COOKIES?
      </Center>
      <Container mb={10} minW={"95vw"}>
        <Text align={"justify"}>
          Las cookies son archivos de texto que son descargadas automáticamente
          y almacenados en el disco duro del equipo de cómputo del usuario al
          navegar en una página de internet específica que, permiten recordar al
          servidor de internet algunos datos sobre el usuario, ente ellos, sus
          preferencias para la visualización de las páginas en ese servidor,
          nombre y contraseña.
          <br /> <br />
          <Text as="b">BUND</Text> a través del uso de su sitio web bund.com.mx
          utiliza cookies para recabar su dirección IP, la duración del tiempo
          de interacción en el sitio web y el tipo de navegador utilizado.
          Dichos datos serán tratados únicamente con la finalidad de análisis
          del tráfico en redes, análisis de datos para el mejoramiento del
          sistema y servicio web, para fines de autenticación y prevención
          frente al robo de identidad o vulneración de la seguridad de los
          datos, así como para autenticar o identificar usuarios o terceros que
          utilicen nuestro sitio web. No se realizará transferencia alguna de
          estos datos. <br /> <br />
          En caso de que no esté de acuerdo con el uso de esta tecnología por
          favor deshabilite las cookies desde su navegador o absténgase de usar
          el sitio web referido.
        </Text>
      </Container>
    </>
  );
}

export default CookiesPage;
