import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  Text,
  Spinner,
} from "@chakra-ui/react";
import ReCAPTCHA from "react-google-recaptcha";
import { SITE_KEY } from "configuration";
import { useState, useRef } from "react";
import React from "react";

export const Captcha = ({ onClose, isOpen, loading }) => {
  const [captchaIsInvalid, setCaptchaIsInvalid] = useState(false);
  const captcha = useRef(null);

  const onChange = async () => {
    if (!captcha.current.getValue()) {
      setCaptchaIsInvalid(true);
      return;
    }
    await onClose(captcha.current.getValue());
  };

  return (
    <>
      {isOpen && (
        <Modal size={"xl"} isOpen={true}>
          <ModalOverlay />
          <ModalContent>
            <ModalHeader>¿Eres un robot?</ModalHeader>
            <ModalBody>
              <ReCAPTCHA ref={captcha} sitekey={SITE_KEY} onChange={onChange} />
              {!captchaIsInvalid && (
                <Text fontSize="xs" color="red.500">
                  Por favor, complete el captcha
                </Text>
              )}
              {loading && <Spinner />}
            </ModalBody>
          </ModalContent>
        </Modal>
      )}
    </>
  );
};
