import {
  Heading,
  Flex,
  FormControl,
  FormLabel,
  Stack,
  Input,
  Button,
  Center,
  Text,
  useToast,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
  Box,
} from "@chakra-ui/react";
import { useForm } from "react-hook-form";
import { clabe } from "clabe-validator";
import { DEFAULT_TOAST_TIME, BankDictionary } from "configuration";
import { LoaderBasic } from "components/LoaderBasic";
import React, { useState, useEffect } from "react";
import redux from "reducers/utils/Redux";
import { StepDictionary } from "configuration";

function BankDataPage({ user, saveBankData, globalEffectUpdate }) {
  const { step } = user;
  const steps = StepDictionary;
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { register, handleSubmit, setValue } = useForm();
  const toast = useToast();

  const [bank, setBank] = useState("");
  const [account, setAccount] = useState("");
  const [loading, setLoading] = useState(false);

  const updateBancoOptions = (codigo) => {
    const clabeCheck = clabe.validate(codigo);
    if (!clabeCheck.ok) {
      toast({
        position: "top",
        title: "Datos no válidos",
        description: "Ingresa una cuenta CLABE válida",
        status: "error",
        duration: DEFAULT_TOAST_TIME,
        isClosable: true,
      });
      return;
    }
    const codigoBanco = codigo.slice(0, 3);
    if (!BankDictionary[codigoBanco]) {
      toast({
        position: "top",
        title: "Datos no válidos",
        description: "El banco ingresado no es válido",
        status: "error",
        duration: DEFAULT_TOAST_TIME,
        isClosable: true,
      });
    } else {
      setBank(BankDictionary[codigoBanco]);
    }
  };

  const handleAccountChange = (e) => {
    let value = e.target.value;
    if (value.length === 18) {
      updateBancoOptions(value);
    }
    setAccount(value);
  };

  const onSubmit = async (dataForm) => {
    setLoading(true);
    const accountForm = document.getElementById("no_cuenta");

    handleAccountChange({ target: { value: accountForm.value } });

    const { data, status } = await saveBankData({
      number: dataForm.no_cuenta,
      banco: bank,
    });
    if (status === 200 || status === 401) {
      status === 200
        ? toast({
            position: "top",
            title: "Cuenta CLABE registrada con éxito",
            status: "success",
            duration: DEFAULT_TOAST_TIME,
            isClosable: true,
          })
        : toast({
            position: "top",
            title: "Redirección al paso correcto",
            description: "Espere un momento por favor",
            status: "warning",
            duration: DEFAULT_TOAST_TIME,
            isClosable: true,
          });
      setLoading(false);
      globalEffectUpdate();
    } else if (data.error_code) {
      toast({
        position: "top",
        title: "Error al registrar la cuenta",
        description: "Por favor, intenta nuevamente",
        status: "error",
        duration: DEFAULT_TOAST_TIME,
        isClosable: true,
      });
      setLoading(false);
    }
  };

  useEffect(() => {
    if (step === steps.BANK_DATA && !account) {
      onOpen();
    }
  }, [step, account, onOpen, steps.BANK_DATA]);

  if (loading) {
    return <LoaderBasic />;
  }

  return (
    <>
      <Modal isOpen={isOpen} onClose={onClose} isCentered>
        <ModalOverlay />
        <ModalContent borderRadius="lg" boxShadow="lg" padding="4">
          <ModalHeader
            textAlign="center"
            fontSize="xl"
            fontWeight="bold"
            color="blue.700"
          >
            ¡Buenas noticias!
          </ModalHeader>
          <ModalBody textAlign="center">
            <Text fontSize="lg" color="gray.600">
              Hemos revisado tu solicitud y estás pre-aprobado. Ahora solo
              faltan unos pasos para obtener tu préstamo.
            </Text>
            <Box mt={4}>
              <Button colorScheme="blue" size="md" onClick={onClose}>
                Continuar
              </Button>
            </Box>
          </ModalBody>
        </ModalContent>
      </Modal>
      <Flex p={8} flex={1} align={"center"} justify={"center"}>
        <Stack spacing={4} w={"full"} maxW={"md"}>
          <form onSubmit={handleSubmit(onSubmit)}>
            <Heading
              color={"blue.700"}
              w="100%"
              textAlign={"center"}
              fontWeight="normal"
              mb="2%"
            >
              Datos Bancarios
            </Heading>
            <Heading
              color={"blue.700"}
              w="100%"
              textAlign={"center"}
              fontWeight="bold"
              my="2%"
              fontSize={"md"}
            >
              Es indispensable que la cuenta bancaria que proporciones esté a tu
              nombre. En caso de que la cuenta esté a nombre de una persona
              diferente, tu cuenta será bloqueada y tu crédito no será
              depositado.
            </Heading>
            <FormControl isRequired id="cuentaBancaria">
              <FormLabel>Ingresa tu cuenta CLABE</FormLabel>
              <Input
                id="no_cuenta"
                {...register("no_cuenta")}
                size="sm"
                value={account}
                onChange={handleAccountChange}
                rounded="md"
                required
                minLength={18}
                maxLength={18}
              />
            </FormControl>
            <FormControl id="banco">
              <FormLabel>Banco al cual pertenece</FormLabel>
              <Input
                id="banco"
                {...register("banco")}
                value={bank}
                size="sm"
                rounded="md"
                required
                w={"100%"}
                disabled
              />
            </FormControl>
            <Text fontSize={"md"} color={"blue.700"} textAlign={"center"}>
              No participan instituciones como Nu, Caja Libertad, Finsus,
              Crediclub, MercadoPago, Spin, Klar, entre otras.
            </Text>
            <Center>
              <Button
                mt={"2%"}
                colorScheme={"blue"}
                type="submit"
                isDisabled={bank === "" || account === ""}
              >
                Guardar
              </Button>
            </Center>
          </form>
        </Stack>
      </Flex>
    </>
  );
}

export default redux(BankDataPage);
