import {
  Box,
  Container,
  Link,
  SimpleGrid,
  Stack,
  Text,
  Flex,
  useColorModeValue,
  Icon,
} from "@chakra-ui/react";
import logo from "assets/img/bund.webp";
import { BsFacebook, BsInstagram } from "react-icons/bs";
import { SiTiktok } from "react-icons/si";
import Logos from "components/inicio/Logos";
import React from "react";

const Logo = (props) => {
  return <img alt={"Bund"} width={100} src={logo}></img>;
};

const ListHeader = ({ children }) => {
  return (
    <Text fontWeight={"500"} fontSize={"lg"} mb={2}>
      {children}
    </Text>
  );
};

function Footer() {
  const year = new Date().getFullYear();

  return (
    <Box
      bg={useColorModeValue("gray.50", "gray.900")}
      color={useColorModeValue("gray.700", "gray.200")}
    >
      <Container as={Stack} maxW={"5xl"} py={10}>
        <SimpleGrid columns={{ base: 1, md: 3 }} spacing={8}>
          <Stack align={"center"}>
            <ListHeader>Ayuda</ListHeader>
            <Link href={"/index/formasDePago"}>Formas de Pago</Link>
            <Link href={"/index/preguntasFrecuentes"}>
              Preguntas Frecuentes
            </Link>
            <Link href={"/index/tutoriales"}>Tutoriales</Link>
          </Stack>
          <Stack align={"center"}>
            <ListHeader>Legal</ListHeader>
            <Link href={"/index/cookies"}>Política de Cookies</Link>
            <Link href={"/index/avisoPrivacidad"}>Aviso de Privacidad</Link>
            <Link href={"/index/terminosYcondiciones"}>
              Términos y Condiciones
            </Link>
            <Link href={"/index/cat"}>CAT</Link>
          </Stack>
          <Stack align={"center"}>
            <ListHeader>Síguenos</ListHeader>
            <Link
              target={"_blank"}
              href={"https://www.facebook.com/profile.php?id=100081833874638"}
            >
              <Icon as={BsFacebook} w={4} h={4} /> Facebook
            </Link>
            <Link
              target={"_blank"}
              href={"https://www.instagram.com/prestamos_bund/"}
            >
              <Icon as={BsInstagram} w={4} h={4} /> Instagram
            </Link>
          </Stack>
        </SimpleGrid>
      </Container>
      <Logos />
      <Box py={10}>
        <Flex
          align={"center"}
          _before={{
            content: '""',
            borderBottom: "1px solid",
            borderColor: useColorModeValue("gray.200", "gray.700"),
            flexGrow: 1,
            mr: 8,
          }}
          _after={{
            content: '""',
            borderBottom: "1px solid",
            borderColor: useColorModeValue("gray.200", "gray.700"),
            flexGrow: 1,
            ml: 8,
          }}
        >
          <Logo />
        </Flex>
        <Text pt={6} fontSize={"sm"} textAlign={"center"}>
          © {year} Promotora Bund SAPI de CV. Todos los derechos reservados
          <br />
          Gutenberg 46, Verónica Anzures, Miguel Hidalgo, CDMX, CP 11300
        </Text>
      </Box>
    </Box>
  );
}

export default Footer;
