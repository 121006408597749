import Actions from "./Actions";

class Credit extends Actions {
  state;

  constructor() {
    super();
    this.state = {};
  }

  getMyCredit() {
    return this.get("/clients/loan", GET_MY_CREDIT_SUCCESS);
  }

  creditRenewal(renew_credit) {
    const body = {
      renew_credit: renew_credit,
    };
    return this.post("/clients/renew/loan/", body, CREDIT_RENEWAL_SUCCES);
  }

  loanReference(formData) {
    const body = {
      first_name: formData.first_name,
      second_name: formData.second_name,
      apellidoP: formData.apellidoP,
      apellidoM: formData.apellidoM,
      telefono: formData.telefono,
      email: formData.email,
    };
    return this.post("/clients/reference/", body, GET_MY_PAYMENTS_SUCCESS);
  }

  sendCodeReference() {
    return this.post(
      "/clients/phone/code/reference",
      {},
      GET_MY_PAYMENTS_SUCCESS
    );
  }

  validateCodeReference(code) {
    const body = {
      code: code,
    };
    return this.post(
      "/clients/phone/code/reference/validate",
      body,
      GET_MY_PAYMENTS_SUCCESS
    );
  }

  renewContract() {
    return this.post("/clients/renew/contract", {}, CREDIT_RENEWAL_SUCCES);
  }
}

export default Credit;

export const GET_MY_CREDIT_SUCCESS = "GET_MY_CREDIT_SUCCESS";
export const GET_MY_CREDIT_ERROR = "GET_MY_CREDIT_ERROR";

export const GET_MY_PAYMENTS_SUCCESS = "GET_MY_PAYMENTS_SUCCESS";
export const GET_MY_PAYMENTS_FAIL = "GET_MY_PAYMENTS_FAIL";

export const CREDIT_RENEWAL_SUCCES = "CREDIT_RENEWAL_SUCCES";
export const CREDIT_RENEWAL_FAIL = "CREDIT_RENEWAL_FAIL";
