import {
  Button,
  FormControl,
  FormLabel,
  Input,
  Center,
} from "@chakra-ui/react";
import { useForm } from "react-hook-form";
import React from "react";

export const RequestAmountForm = ({ onSubmit, buttonLoader }) => {
  const { register, handleSubmit } = useForm();
  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <FormControl id="monto">
        <FormLabel>Ingresa el monto</FormLabel>
        <Input
          id="monto"
          {...register("monto")}
          size="sm"
          rounded="md"
          required
          type={"number"}
          min="2000"
          max="3000"
        />
      </FormControl>
      <Center>
        {buttonLoader ? (
          <></>
        ) : (
          <Button
            disabled={buttonLoader}
            mt={"2%"}
            colorScheme={"blue"}
            type="submit"
          >
            Guardar
          </Button>
        )}
      </Center>
    </form>
  );
};
