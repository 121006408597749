import Contrato from "components/nuovo/Contrato";
import redux from "reducers/utils/Redux";
import React  from 'react';

function ContratoUse({ user, globalEffectUpdate }) {
  return (
    <>
      <Contrato user={user} globalEffectUpdate={globalEffectUpdate} />
    </>
  );
}

export default redux(ContratoUse);
