import {
  Heading,
  Stack,
  Link,
  ListItem,
  OrderedList,
  Center,
  Button,
  useToast,
} from "@chakra-ui/react";
import { QRCodeSVG } from "qrcode.react";
import { DEFAULT_TOAST_TIME } from "configuration";
import { ButtonChangeEnrolment } from "components/user/ButtonChangeEnrolment";
import CountdownLoader from "components/CountdownLoader";
import React from "react";
import { useState } from "react";

import { LoaderBasic } from "components/LoaderBasic";

function NuovoWithReset({
  qr,
  verifyEnrollment,
  globalEffectUpdate,
  canChangeEnrollmentType,
  sendMessage,
  buttonIsDisabled,
  setButtonIsDisabled,
}) {
  const [loading, setLoading] = useState(false);
  const toast = useToast();

  async function onClick() {
    setLoading(true);
    const res = await verifyEnrollment();
    if (res.status !== 200 && res.status === 400) {
      globalEffectUpdate();
    } else if (res.data.status) {
      globalEffectUpdate();
    } else {
      toast({
        title: "No se ha podido verificar la instalación de Nuovo",
        description:
          "Termina el proceso hasta que aparezca tu código de confirmación",
        status: "error",
        duration: 2 * DEFAULT_TOAST_TIME,
        isClosable: true,
      });
    }
    setLoading(false);
  }

  if (loading) return <LoaderBasic />;

  return (
    <Stack pt={"2%"}>
      <Heading size={"sm"} color="blue.700" fontWeight={"medium"}>
        Para continuar con tu solicitud es necesario que restablezcas tu celular
        para poder instalar la aplicación Nuovo. Así tu celular actuará como una
        garantía y podremos ofrecerte tasas mucho más atractivas para tu
        préstamo <br />
        <br />
        Vas a tener que escanear con tu celular el código QR que viene en esta
        pantalla, por lo que te sugerimos que inicies sesión desde algún otro
        dispositivo para poder visualizar esta misma pantalla.
      </Heading>
      <Heading size={"sm"} color="blue.700" fontWeight={"medium"}>
        Una vez que tengas el código QR disponible en un diferente diferente al
        que vas a restablecer, sigue estos pasos en dicho dispositivo:
      </Heading>
      <OrderedList>
        <ListItem>
          Haz un respaldo de tu información.{" "}
          <Link
            color={"blue"}
            target="_blank"
            href="https://recoverit.wondershare.es/phone-recovery/recover-photos-after-factory-reset-android.html#:~:text=Para%20recuperar%20los%20datos%20despu%C3%A9s,de%20restablecer%20el%20tel%C3%A9fono%20Android"
          >
            ¿Cómo lo hago?
          </Link>
        </ListItem>
        <ListItem>
          Ve a configuración y restablece el teléfono a las condiciones de
          fábrica.
        </ListItem>
        <ListItem>
          Una vez que finalice el restablecimiento del teléfono, enciéndalo.
        </ListItem>
        <ListItem>
          Te aparecerá un mensaje para iniciar la configuración del dispositivo,
          ignóralo y presiona 6 veces en cualquier lugar de la pantalla, esto
          abrirá un escáner de QR.
          <br /> Nota: Es muy importante que no termines la configuración del
          teléfono antes de hacer el proceso mencionado, ya que tendrías que
          volver al paso 2.
        </ListItem>
        <ListItem>
          Una vez abierto el escáner, escanea este código QR para descargar e
          instalar la aplicación de Nuovo:
          <Center pt={"16%"}>
            <QRCodeSVG
              level="Q"
              style={{ transform: "scale(1.5)" }}
              value={qr}
            />
          </Center>
        </ListItem>
        <ListItem pt={"16%"}>
          Finaliza la configuración de tu teléfono.
        </ListItem>
        <ListItem>
          Ingresa a tu cuenta de Bund desde tu teléfono con tu usuario y
          contraseña.
        </ListItem>
        <ListItem>Da click en el siguiente botón</ListItem>
      </OrderedList>
      {buttonIsDisabled ? (
        <CountdownLoader
          onCountdownEnd={() => setButtonIsDisabled(false)}
          text="En caso de no recibir el mensaje, podrá solicitar el reenvió en unos instantes"
          timer={120}
        />
      ) : (
        <Button onClick={sendMessage} colorScheme={"blue"} variant="outline">
          Reenviar mensaje
        </Button>
      )}
      {canChangeEnrollmentType && (
        <ButtonChangeEnrolment globalEffectUpdate={globalEffectUpdate} />
      )}
      <Button onClick={onClick} colorScheme={"blue"} variant="outline">
        He instalado la aplicación de Nuovo
      </Button>
    </Stack>
  );
}

export default NuovoWithReset;
