import {
  Heading,
  Flex,
  FormControl,
  FormLabel,
  Stack,
  Select,
  Button,
  Input,
  Text,
} from "@chakra-ui/react";
import { useForm } from "react-hook-form";
import React from "react";

function NuovoReference({
  onSubmit,
  setMarcaTel = () => {},
  setShow = () => {},
  setModelo = () => {},
  marcaTel = null,
  step = null,
}) {
  const { register, handleSubmit } = useForm();

  if (step !== null) {
    return (
      <>
        <Stack
          alignContent="center"
          justifyContent="center"
          spacing={4}
          maxW="100%"
        >
          <form onSubmit={handleSubmit(onSubmit)}>
            <Heading
              color={"blue.700"}
              w="100%"
              textAlign={"center"}
              fontWeight="normal"
              mb="2%"
            >
              Compártenos una referencia personal
            </Heading>
            <FormControl my={5} mr="5%">
              <FormLabel
                htmlFor="first_name"
                fontSize={"small"}
                fontWeight={"semibold"}
              >
                Nombres
              </FormLabel>
              <Input
                id="first_name"
                {...register("first_name")}
                // defaultValue={user?.nombre}
                placeholder="Nombres"
                required
                size="sm"
                rounded="md"
              />
            </FormControl>

            <FormControl my={5} mr="5%">
              <FormLabel
                htmlFor="apellido"
                fontSize={"small"}
                fontWeight={"semibold"}
              >
                Apellido Paterno
              </FormLabel>
              <Input
                id="apellido"
                {...register("apellido")}
                // defaultValue={user?.apellidoP}
                placeholder="Apellido Paterno"
                size="sm"
                rounded="md"
                required
              />
            </FormControl>

            <FormControl my={5} mr="5%">
              <FormLabel
                htmlFor="materno"
                fontSize={"small"}
                fontWeight={"semibold"}
              >
                Apellido Materno
              </FormLabel>
              <Input
                id="materno"
                {...register("materno")}
                // defaultValue={user?.apellidoM}
                placeholder="Apellido Materno"
                required
                size="sm"
                rounded="md"
              />
            </FormControl>

            <FormControl my={5}>
              <FormLabel
                htmlFor="telefono"
                fontSize={"small"}
                fontWeight={"semibold"}
              >
                Telefono
              </FormLabel>
              <Input
                id="telefono"
                {...register("telefono")}
                type={"tel"}
                placeholder="5555555555"
                required
                size="sm"
                rounded="md"
                maxLength={"5"}
              />
            </FormControl>
            <Button colorScheme={"blue"} mt={2} type="submit">
              Enviar
            </Button>
          </form>
        </Stack>
      </>
    );
  } else {
    return (
      <>
        <Stack
          alignContent="center"
          justifyContent="center"
          spacing={4}
          maxW="100%"
        >
          <form onSubmit={handleSubmit(onSubmit)}>
            <Heading
              color={"blue.700"}
              w="100%"
              textAlign={"center"}
              fontWeight="normal"
              mb="2%"
            >
              Compártenos una referencia personal
            </Heading>
            <FormControl my={5} mr="5%">
              <FormLabel
                htmlFor="first_name"
                fontSize={"small"}
                fontWeight={"semibold"}
              >
                Nombres
              </FormLabel>
              <Input
                id="first_name"
                {...register("first_name")}
                // defaultValue={user?.nombre}
                placeholder="Nombres"
                required
                size="sm"
                rounded="md"
              />
            </FormControl>

            <FormControl my={5} mr="5%">
              <FormLabel
                htmlFor="apellido"
                fontSize={"small"}
                fontWeight={"semibold"}
              >
                Apellido Paterno
              </FormLabel>
              <Input
                id="apellido"
                {...register("apellido")}
                // defaultValue={user?.apellidoP}
                placeholder="Apellido Paterno"
                size="sm"
                rounded="md"
                required
              />
            </FormControl>

            <FormControl my={5} mr="5%">
              <FormLabel
                htmlFor="materno"
                fontSize={"small"}
                fontWeight={"semibold"}
              >
                Apellido Materno
              </FormLabel>
              <Input
                id="materno"
                {...register("materno")}
                // defaultValue={user?.apellidoM}
                placeholder="Apellido Materno"
                required
                size="sm"
                rounded="md"
              />
            </FormControl>

            <FormControl my={5}>
              <FormLabel
                htmlFor="telefono"
                fontSize={"small"}
                fontWeight={"semibold"}
              >
                Telefono
              </FormLabel>
              <Input
                id="telefono"
                {...register("telefono")}
                type={"tel"}
                placeholder="5555555555"
                required
                size="sm"
                rounded="md"
                maxLength={"5"}
              />
            </FormControl>
            <Button colorScheme={"blue"} mt={2} type="submit">
              Enviar
            </Button>
          </form>
        </Stack>
      </>
    );
  }
}

export default NuovoReference;
