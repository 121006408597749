import React, { useState } from "react";
import {
  Button,
  Center,
  FormControl,
  FormLabel,
  Input,
  Heading,
  useToast,
} from "@chakra-ui/react";
import IneHelperModal from "components/signup/IneHelperModal";
import { useDisclosure } from "@chakra-ui/react";
import { useForm } from "react-hook-form";
import { LoaderBasic } from "components/LoaderBasic";

export const CicAndId = ({ validateIne, updateClient, globalEffectUpdate }) => {
  const [loading, setLoading] = useState(false);
  const [dataForm, setDataForm] = useState({
    cic: "",
    idCiudadano: "",
  });
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { register, handleSubmit } = useForm();
  const toast = useToast();

  const onSubmit = async (dataForm) => {
    setLoading(true);
    const { data, status } = await validateIne(dataForm);
    setLoading(false);

    if (status === 200) {
      toast({
        title: "INE validada",
        description: "Tu INE ha sido validada correctamente",
        status: "success",
        duration: 5000,
        isClosable: true,
      });
      updateClient(data);
      globalEffectUpdate();
    } else if (data.error_code === 2) {
      toast({
        title: "INE no válida, CIC o Identificador del ciudadano incorrecto",
        description: "Por favor verifica los datos ingresados",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    } else if (data.error_code === 6) {
      toast({
        title: "No se pudo generar el CURP",
        description: "Por favor verifica los datos ingresados",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    } else if (data.error_code === 3) {
      toast({
        title: "CURP ya registrado",
        description: "Por favor inicia sesión en la cuenta correspondiente",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    }
  };

  // Manejo de cambios en los inputs para actualizar el estado de dataForm
  const handleInputChange = (e) => {
    const { id, value } = e.target;
    setDataForm((prevData) => ({
      ...prevData,
      [id]: value,
    }));
  };

  if (loading) return <LoaderBasic />;

  return (
    <>
      <IneHelperModal modeloINE={2} onClose={onClose} isOpen={isOpen} />
      <form onSubmit={handleSubmit(onSubmit)}>
        <Heading
          color={"blue.700"}
          w="100%"
          textAlign={"center"}
          fontWeight="normal"
          mb="2%"
        >
          Por favor llena los campos solicitados
        </Heading>
        <FormControl mr="5%">
          <FormLabel htmlFor="cic" fontSize={"small"} fontWeight={"semibold"}>
            CIC
          </FormLabel>
          <Input
            id="cic"
            {...register("cic")}
            value={dataForm.cic}
            onChange={handleInputChange}
            size="sm"
            rounded="md"
            minLength="9"
            maxLength="9"
          />
        </FormControl>
        <FormControl>
          <FormLabel
            htmlFor="idCiudadano"
            fontSize={"small"}
            fontWeight={"semibold"}
          >
            Identificador del Ciudadano
          </FormLabel>
          <Input
            id="idCiudadano"
            {...register("idCiudadano")}
            value={dataForm.idCiudadano}
            onChange={handleInputChange}
            size="sm"
            rounded="md"
            minLength="9"
            maxLength="9"
          />
        </FormControl>
        <Center>
          <Button
            onClick={onOpen}
            variant="link"
            color={"blue.500"}
            mt={5}
            mr={20}
            size={"sm"}
          >
            ¿Dónde veo el CIC e Identificador del ciudadano?
          </Button>
        </Center>
        <Button
          mt={2}
          mr={2}
          type="submit"
          bg="blue.500"
          color="white"
          variant={"solid"}
          isDisabled={
            dataForm.cic.length !== 9 || dataForm.idCiudadano.length !== 9
          }
        >
          Enviar
        </Button>
      </form>
    </>
  );
};
