import React, { useState } from "react";
import { Box, useToast } from "@chakra-ui/react";
import ValidateCodeForm from "components/user/ValidateCodeForm"; // Asegúrate de la ruta correcta
import redux from "reducers/utils/Redux";
import { LoaderBasic } from "components/LoaderBasic";

const ValidateCodePage = ({
  validateCodeReference,
  globalEffectUpdate,
  sendCodeReference,
}) => {
  const toast = useToast();
  const [loading, setLoading] = useState(false); // Estado de carga

  const handleValidate = async (code) => {
    setLoading(true); // Iniciar el estado de carga

    const { status, data } = await validateCodeReference(code); // Hacer la llamada a la API para validar el código

    // Verificar si la API devolvió un estado de error
    if (!data.valid) {
      status === 200
        ? toast({
            title: "Error de Validación",
            description:
              "El código ingresado es incorrecto. Intenta nuevamente.",
            status: "error",
            duration: 5000,
            isClosable: true,
          })
        : toast({
            title: "Error de Validación",
            description: "El código ingresado ya ha sido utilizado.",
            status: "error",
            duration: 5000,
            isClosable: true,
          });

      setLoading(false); // Detener el estado de carga
      return;
    }

    toast({
      title: "Código Validado",
      description: "¡El código ha sido validado exitosamente!",
      status: "success",
      duration: 5000,
      isClosable: true,
    });

    // Actualización del estado global u otras acciones
    globalEffectUpdate(data);

    setLoading(false); // Detener el estado de carga
  };

  const onSendSMS = async () => {
    const { status } = await sendCodeReference(); // Hacer la llamada a la API para enviar el código

    // Verificar si la API devolvió un estado de error
    if (status !== 200) {
      toast({
        title: "Error en el Sistema",
        description:
          "No se pudo enviar el código. Por favor, intenta de nuevo más tarde.",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
      return false;
    }
    toast({
      title: "Código Enviado",
      description: "El código ha sido enviado a tu SMS.",
      status: "info",
      duration: 5000,
      isClosable: true,
    });
    return true;
  };

  if (loading) {
    return <LoaderBasic />;
  }

  return (
    <Box p="6">
      <ValidateCodeForm onValidate={handleValidate} onSendSMS={onSendSMS} />
    </Box>
  );
};

export default redux(ValidateCodePage);
