import { connect } from "react-redux";
import {
  Container,
  SimpleGrid,
  Image,
  Flex,
  Text,
  Stack,
  StackDivider,
  Icon,
  useColorModeValue,
  Center,
} from "@chakra-ui/react";
import { MdSettingsCell } from "react-icons/md";
import { BsFillCreditCard2FrontFill } from "react-icons/bs";
import { GiMexico } from "react-icons/gi";
import { AiFillIdcard } from "react-icons/ai";
import imagen from "assets/img/requisitos.webp";
import React from "react";

const Feature = ({ text, icon }) => {
  return (
    <Stack direction={"row"} align={"center"}>
      <Flex w={10} h={10} align={"center"} justify={"center"} rounded={"full"}>
        {icon}
      </Flex>
      <Text fontWeight={600}>{text}</Text>
    </Stack>
  );
};

function Requisitos() {
  return (
    <Container maxW={"6xl"} pt={12}>
      <SimpleGrid columns={{ base: 1, md: 2 }} spacing={10}>
        <Flex>
          <Image
            rounded={"md"}
            alt={"feature image"}
            src={imagen}
            objectFit={"cover"}
          />
        </Flex>
        <Stack mb={4} spacing={4} align={"center"}>
          <Center
            color={"#196fba"}
            align={"center"}
            fontSize={{ base: "xl", lg: "4xl" }}
          >
            Requisitos para contratar tu crédito
          </Center>
          <Stack
            spacing={3}
            divider={
              <StackDivider
                borderColor={useColorModeValue("gray.100", "gray.700")}
              />
            }
          >
            <Feature
              icon={<Icon as={AiFillIdcard} color={"blue.500"} w={10} h={10} />}
              text={"Contar con tu INE vigente"}
            />
            <Feature
              icon={
                <Icon
                  as={BsFillCreditCard2FrontFill}
                  color={"blue.500"}
                  w={9}
                  h={9}
                />
              }
              text={"Tener una cuenta bancaria a tu nombre"}
            />
            <Feature
              icon={<Icon as={GiMexico} color={"blue.500"} w={12} h={12} />}
              text={"Ser de nacionalidad mexicana"}
            />
          </Stack>
        </Stack>
      </SimpleGrid>
    </Container>
  );
}

const mapStateToProp = () => ({});

export default connect(mapStateToProp, {})(Requisitos);
