// reducers/deviceReducer.js

import { GET_DEVICE_INFO } from "redux/actions/infoPersonal/infoPersonal";

const initialState = null;

const deviceReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_DEVICE_INFO:
      return action.payload;
    default:
      return state;
  }
};

export default deviceReducer;
