import { GridItem, Grid, Image, Box } from "@chakra-ui/react";
import { connect } from "react-redux";
import ssl from "assets/img/ssl.webp";
import buro from "assets/img/buro.webp";
import circulo from "assets/img/circulo.webp";
import React from "react";

function Logos() {
  return (
    <Box alignItems={"center"}>
      <Grid
        templateColumns={{
          base: "repeat(1, 1fr)",
          md: "repeat(3, 1fr)",
        }}
        align={"center"}
        gap={{ base: "8", md: "10" }}
      >
        <GridItem align={"center"}>
          <Image alt="ssl" src={ssl} maxH="50" />
        </GridItem>
        <GridItem align={"center"}>
          <Image alt="buro" src={buro} maxH="50" />
        </GridItem>
        <GridItem align={"center"}>
          <Image alt="circulo" src={circulo} maxH="50" />
        </GridItem>
      </Grid>
    </Box>
  );
}
const mapStateToProp = () => ({});

export default connect(mapStateToProp, {})(Logos);
