import { Heading, Stack, useToast } from "@chakra-ui/react";
import { useState } from "react";
import WithoutReset from "components/user/NuovoWithoutReset";
import WithReset from "components/user/NuovoWithReset";
import NuovoReference from "components/user/NuovoReference";
import { DEFAULT_TOAST_TIME } from "configuration";
import redux from "reducers/utils/Redux";
import React from "react";
import { LoaderBasic } from "components/LoaderBasic";

function Nuovo({
  // client,
  // verifyEnrollment,
  // registroNuovo,
  // updateClient,
  referencesData,
  globalEffectUpdate,
}) {
  const [marcaTel, setMarcaTel] = useState();
  const [modelo, setModelo] = useState(null);
  const [show, setShow] = useState(false);
  const [next] = useState(true);
  const [reset] = useState(false);
  const [qr, setQr] = useState(null);
  const [loading, setLoading] = useState(false);
  const toast = useToast();

  async function onSubmit(dataForm) {
    setLoading(true);
    dataForm.marcaTel = marcaTel;
    dataForm.modelo = modelo;
    const { data, status } = await referencesData(dataForm);
    if (status === 200) {
      toast({
        title: "Referencia creada",
        description: "Se ha creado la referencia",
        status: "success",
        duration: DEFAULT_TOAST_TIME,
        isClosable: true,
      });
      setQr(data.qr);
      setLoading(false);
      globalEffectUpdate();
    }
    if (data.error_code === 3) {
      toast({
        title: "Error con la información ingresada",
        description: "Ingresa los campos sin caracteres especiales",
        status: "error",
        duration: DEFAULT_TOAST_TIME,
        isClosable: true,
      });
    }
    if (status === 500) {
      toast({
        position: "top",
        title: "Estamos experimentando intermitencia",
        description: "Inténtalo de nuevo mas tarde",
        status: "error",
        duration: DEFAULT_TOAST_TIME,
        isClosable: true,
      });
    }
    setLoading(false);
  }

  if (loading) {
    return <LoaderBasic />;
  }

  return (
    <>
      {next === true ? (
        <Stack>
          <NuovoReference
            onSubmit={onSubmit}
            setMarcaTel={setMarcaTel}
            setShow={setShow}
            setModelo={setModelo}
            marcaTel={marcaTel}
          ></NuovoReference>
          {marcaTel === 1 && show && <WithoutReset />}
          {marcaTel === 2 && show && (
            <Stack>
              {(modelo !== null) & (modelo !== 26) ? (
                <WithoutReset />
              ) : (
                <WithReset qr={qr} />
              )}
            </Stack>
          )}
          {marcaTel === 3 && show && <WithReset qr={qr} />}
        </Stack>
      ) : reset === true ? (
        <WithReset qr={qr} />
      ) : (
        <WithoutReset />
      )}
    </>
  );
}

export default redux(Nuovo);
