import { Button } from "@chakra-ui/react";
import EnrollmentModal from "components/user/EnrollmentModal";
import { useState } from "react";
import React  from 'react';

export const ButtonChangeEnrolment = ({ globalEffectUpdate }) => {
  const [isOpen, setIsOpen] = useState(false);
  const onClose = () => setIsOpen(false);
  return (
    <>
      <EnrollmentModal
        isOpen={isOpen}
        onClose={onClose}
        globalEffectUpdate={globalEffectUpdate}
      />
      <Button
        colorScheme="blue"
        size="md"
        rounded="md"
        w="100%"
        mt="2%"
        mb="2%"
        onClick={() => setIsOpen(true)}
      >
        Cambiar tipo de enrolamiento
      </Button>
    </>
  );
};
