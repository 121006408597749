import {
  Button,
  Container,
  Flex,
  Grid,
  Heading,
  Image,
} from "@chakra-ui/react";
import { Navigate, useNavigate } from "react-router-dom";
import imagen from "assets/img/bund.webp";
import { useEffect, useState } from "react";
import { CLIENT_HOME_ROUTE } from "configuration";
import React from "react";

function ConfirmEmailPage({
  emailConfirm,
  uid,
  token,
  checkAuthenticated,
  loadUser,
}) {
  const navigate = useNavigate();
  const navigateTo = () => {
    navigate(CLIENT_HOME_ROUTE);
  };

  const [user, setUser] = useState(null);

  useEffect(() => {
    emailConfirm(uid, token);
    const verifyAuthentication = async () => {
      if (await checkAuthenticated()) {
        const { data } = await loadUser();
        checkAuthenticated();
        setUser(data);
      }
    };
    verifyAuthentication();
  }, [loadUser, checkAuthenticated, emailConfirm, uid, token]);

  if (user) {
    return <Navigate to={CLIENT_HOME_ROUTE} />;
  }
  return (
    <Grid
      mb={10}
      templateColumns={{
        base: "repeat(1, 1fr)",
        md: "repeat(2, 1fr)",
      }}
      align={"center"}
      gap={{ base: "8", md: "5" }}
    >
      <Container maxW={"90vw"}>
        <Heading
          color={"blue.700"}
          w="100%"
          textAlign={"center"}
          fontWeight="normal"
          my="5%"
        >
          Correo electrónico confirmado
        </Heading>

        <Flex justify={"center"}>
          <Flex flex={1} align={"center"}>
            <Image
              alt={"Login Image"}
              objectFit={"cover"}
              src={imagen}
              h={{ base: "15vh", sm: "60vh", md: "70vh", lg: "80vh" }}
            />
          </Flex>
        </Flex>

        <Flex
          mt={"2%"}
          justifyContent={"center"}
          color={"blue.700"}
          w="100%"
          my="5%"
        >
          <Button onClick={navigateTo} colorScheme={"blue"}>
            Da click para continuar
          </Button>
        </Flex>
      </Container>
    </Grid>
  );
}

export default ConfirmEmailPage;
