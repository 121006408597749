import React, { useState } from "react";
import {
  Input,
  Button,
  Box,
  FormControl,
  FormLabel,
  Grid,
  Stack,
  Heading,
} from "@chakra-ui/react";

const ContactForm = ({ handleSubmit }) => {
  const [formData, setFormData] = useState({
    firstName: "",
    secondName: "",
    lastName: "",
    secondLastName: "",
    phone: "",
    email: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleKeyDown = (e) => {
    // Evitar la entrada de cualquier carácter que no sea un número
    if (!/^[0-9]*$/.test(e.key) && e.key !== "Backspace") {
      e.preventDefault();
    }
  };

  const submitForm = (e) => {
    e.preventDefault();
    handleSubmit(formData);
  };

  return (
    <Box p="6" borderWidth="1px" borderRadius="lg" boxShadow="lg" bg="white">
      <Heading color="blue.700" mb="4" fontWeight="normal" textAlign="center">
        Registra tu Contacto de Referencia
      </Heading>
      <Box as="form" onSubmit={submitForm}>
        <Grid templateColumns={{ base: "1fr", md: "1fr 1fr" }} gap={4}>
          <FormControl id="firstName" isRequired>
            <FormLabel>Nombre</FormLabel>
            <Input
              type="text"
              name="firstName"
              value={formData.firstName}
              onChange={handleChange}
              placeholder="Ingresa tu nombre"
            />
          </FormControl>

          <FormControl id="secondName">
            <FormLabel>Segundo Nombre</FormLabel>
            <Input
              type="text"
              name="secondName"
              value={formData.secondName}
              onChange={handleChange}
              placeholder="Ingresa tu segundo nombre en caso de tenerlo"
            />
          </FormControl>

          <FormControl id="lastName" isRequired>
            <FormLabel>Primer Apellido</FormLabel>
            <Input
              type="text"
              name="lastName"
              value={formData.lastName}
              onChange={handleChange}
              placeholder="Ingresa tu primer apellido"
            />
          </FormControl>

          <FormControl id="secondLastName" isRequired>
            <FormLabel>Segundo Apellido</FormLabel>
            <Input
              type="text"
              name="secondLastName"
              value={formData.secondLastName}
              onChange={handleChange}
              placeholder="Ingresa tu segundo apellido"
            />
          </FormControl>

          <FormControl id="phone" isRequired>
            <FormLabel>Teléfono</FormLabel>
            <Input
              type="tel"
              name="phone"
              value={formData.phone}
              onChange={handleChange}
              onKeyDown={handleKeyDown} // Restringe a números
              placeholder="Ingresa el número de teléfono del contacto"
              pattern="^\d{10}$"
              title="El número de teléfono debe ser de 10 dígitos"
            />
          </FormControl>

          <FormControl id="email" isRequired>
            <FormLabel>Correo Electrónico</FormLabel>
            <Input
              type="email"
              name="email"
              value={formData.email}
              onChange={handleChange}
              placeholder="Ingresa el correo de tu contacto"
              pattern="^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$"
              title="Ingresa un correo electrónico válido"
            />
          </FormControl>
        </Grid>

        <Stack spacing={4} mt={6}>
          <Button type="submit" colorScheme="blue" size="lg" width="full">
            Registrar Referencia
          </Button>
        </Stack>
      </Box>
    </Box>
  );
};

export default ContactForm;
