import { connect } from "react-redux";
import { Flex, Heading, Text, AbsoluteCenter, Box } from "@chakra-ui/react";
import "styles/loader.css";
import { ScrollToTop } from "./ScrollToTop";
import React from "react";

function Loading() {
  return (
    <Box mt="80" bgColor="white">
      <ScrollToTop />
      <AbsoluteCenter>
        <Flex direction="column" align="center" mx="10" my="10">
          <Heading as="h2" size="xl">
            Espere un momento
          </Heading>
          <div className="ploader"></div>
          <Text alignText="center" color={"gray.500"}>
            Su información está siendo procesada, por favor espere.
            <Text fontSize="sm">Este proceso puede tomar varios minutos.</Text>
          </Text>
        </Flex>
      </AbsoluteCenter>
    </Box>
  );
}
const mapStateToProp = (state) => ({});

export default connect(mapStateToProp, {})(Loading);
