import { useState, useEffect } from "react";
import redux from "reducers/utils/Redux";
import WithoutReset from "components/user/NuovoWithoutReset";
import WithReset from "components/user/NuovoWithReset";
import { Box, Toast, useToast } from "@chakra-ui/react";
import { LoaderBasic } from "components/LoaderBasic";
import React from "react";
import { DEFAULT_TOAST_TIME } from "configuration";
import { ScrollToTop } from "components/ScrollToTop";

function PendingEnrollmentPage({
  getDeviceInformation,
  verifyEnrollment,
  globalEffectUpdate,
  sendMessage,
}) {
  const toast = useToast();
  const [device, setDevice] = useState(null);

  const [loading, setLoading] = useState(true);
  const [buttonIsDisabled, setButtonIsDisabled] = useState(true);

  const sendMessageButton = async () => {
    setButtonIsDisabled(true);
    const { status, data } = await sendMessage();
    if (status === 200) {
      if (!data.status) {
        toast({
          title: "Ocurrió un error",
          description:
            "No se pudo enviar el SMS, intente de nuevo o contacte a soporte",
          status: "error",
          duration: DEFAULT_TOAST_TIME,
          isClosable: true,
          orientation: "top",
        });
        setButtonIsDisabled(false);
      }
      toast({
        title: "SMS enviado",
        description: "Se ha enviado un SMS con el código de verificación",
        status: "success",
        duration: DEFAULT_TOAST_TIME,
        isClosable: true,
        orientation: "top",
      });
    } else {
      toast({
        title: "Ocurrió un error",
        description:
          "No se pudo enviar el SMS, intente de nuevo o contacte a soporte",
        status: "error",
        duration: DEFAULT_TOAST_TIME,
        isClosable: true,
        orientation: "top",
      });
      setButtonIsDisabled(false);
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      const { data, status } = await getDeviceInformation();
      setLoading(false);
      if (status !== 200) {
        globalEffectUpdate();
        if (data?.error_code === 1) {
          globalEffectUpdate();
        }
      } else if (data.status) {
        globalEffectUpdate();
      } else {
        setDevice(data);
      }
    };

    fetchData();
  }, [setDevice, getDeviceInformation, globalEffectUpdate]);

  return (
    <Box
      borderWidth="1px"
      rounded="lg"
      shadow="1px 1px 3px rgba(0,0,0,0.3)"
      maxWidth={800}
      p={6}
      m="10px auto"
    >
      <ScrollToTop />
      {loading ? <LoaderBasic /> : null}
      {device?.device_type === 1 ? (
        <WithoutReset
          canChangeEnrollmentType={device?.can_change_enrollement_type}
          verifyEnrollment={verifyEnrollment}
          globalEffectUpdate={globalEffectUpdate}
          sendMessage={sendMessageButton}
          buttonIsDisabled={buttonIsDisabled}
          setButtonIsDisabled={setButtonIsDisabled}
        />
      ) : (
        <WithReset
          qr={device?.qr_url}
          canChangeEnrollmentType={device?.can_change_enrollement_type}
          verifyEnrollment={verifyEnrollment}
          globalEffectUpdate={globalEffectUpdate}
          sendMessage={sendMessageButton}
          buttonIsDisabled={buttonIsDisabled}
          setButtonIsDisabled={setButtonIsDisabled}
        />
      )}
    </Box>
  );
}

export default redux(PendingEnrollmentPage);
