import Auth from "actions/helpers/Auth";
import Create from "actions/helpers/Create";
import IneValidator from "actions/helpers/IneValidator";
import Nuovo from "actions/helpers/Nuovo";
import Credit from "actions/helpers/Credit";

const auth = new Auth();
const create = new Create();
const ineValidator = new IneValidator();
const nuovo = new Nuovo();
const credit = new Credit();

const actions = [
  {
    className: Auth,
    object: auth,
  },
  {
    className: Create,
    object: create,
  },
  {
    className: IneValidator,
    object: ineValidator,
  },
  {
    className: Nuovo,
    object: nuovo,
  },
  {
    className: Credit,
    object: credit,
  },
];

export default actions;
