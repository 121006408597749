import { connect } from "react-redux";
import {
  Box,
  SimpleGrid,
  Icon,
  Text,
  Stack,
  Flex,
  Center,
} from "@chakra-ui/react";
import {
  FcMultipleDevices,
  FcMoneyTransfer,
  FcRules,
  FcApproval,
} from "react-icons/fc";
import React from "react";

const Feature = ({ title, text, icon }) => {
  return (
    <Stack align={"center"}>
      <Flex
        w={16}
        h={16}
        align={"center"}
        justify={"center"}
        color={"white"}
        rounded={"full"}
        bg={"gray.100"}
        mb={1}
      >
        {icon}
      </Flex>
      <Text fontWeight={600}>{title}</Text>
      <Text align={"center"} color={"gray.600"}>
        {text}
      </Text>
    </Stack>
  );
};

function Pasos() {
  return (
    <Stack>
      <Center mt={"12"} color="#1a6ebb" fontSize={{ base: "xl", lg: "5xl" }}>
        ¡Es muy fácil obtener un crédito!
      </Center>
      <Box p={10}>
        <SimpleGrid columns={{ base: 1, md: 4 }} spacing={10}>
          <Feature
            icon={<Icon as={FcMultipleDevices} w={10} h={10} />}
            title={"1.Selecciona el monto"}
            text={"Elige el monto del crédito que necesitas"}
          />
          <Feature
            icon={<Icon as={FcRules} w={10} h={10} />}
            title={"2.Llena el formulario"}
            text={"Nombre, dirección, correo electrónico y teléfono celular"}
          />
          <Feature
            icon={<Icon as={FcApproval} w={10} h={10} />}
            title={"3.Obtén tu préstamo"}
            text={
              "De ser aprobado, recibirás tu dinero en tan solo unos minutos"
            }
          />
          <Feature
            icon={<Icon as={FcMoneyTransfer} w={10} h={10} />}
            title={
              /** 
            title={'4.Domiciliamos tus pagos'}**/
              "4.Paga tu crédito semanalmente"
            }
            text={
              /** 'Recuerda tener el saldo de tu pago semanal disponible en tu cuenta bancaria'**/
              "Realiza tu pago por transferencia electrónica o en una sucursal bancaria"
            }
          />
        </SimpleGrid>
      </Box>
    </Stack>
  );
}

const mapStateToProp = () => ({});

export default connect(mapStateToProp, {})(Pasos);
