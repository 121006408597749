import { Box, Heading, Text, Button, Image } from "@chakra-ui/react";
import logo from "assets/img/bund.webp";
import React  from 'react';

export default function Error404() {
  return (
    <Box textAlign="center" alignContent="center" my={10}>
      <Image src={logo} alt="404" w="50" mx="auto" />
      <Heading
        display="inline-block"
        as="h2"
        size="2xl"
        bgGradient="linear(to-r, blue.400, blue.600)"
        backgroundClip="text"
      >
        404
      </Heading>
      <Text fontSize="18px" mt={3} mb={2}>
        Lo sentimos, ha ocurrido un error.
      </Text>
      <Text color={"gray.500"} mb={6}>
        El recurso que busca no se encuentra disponible.
      </Text>

      <Button
        colorScheme="blue"
        bgGradient="linear(to-r, blue.400, blue500,blue.600)"
        color="white"
        variant="solid"
        onClick={() => window.location.replace("/login")}
      >
        Regresar al inicio
      </Button>
    </Box>
  );
}
