import React from "react";
import Error404 from "pages/errors/Error404";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { Provider } from "react-redux";
import ReactGA from "react-ga4";
import store from "store";
import SignUpPage from "pages/signup";
import QrPage from "pages/user/QrPage";
import UserHomePage from "pages/user";
import LoginPage from "pages/login";
import MainPage from "pages/main";

ReactGA.initialize("G-ZX6TX7N461");

function App() {
  ReactGA.send({
    hitType: "pageview",
    page: window.location.pathname,
    title: document.title,
  });
  return (
    <Provider store={store}>
      <Router>
        <Routes>
          {/* Error Display*/}
          <Route path="/*" element={<Error404 />} />
          <Route path="/index/*" element={<MainPage />} />
          <Route path="/" element={<MainPage />} />

          <Route path="/qr/:token" element={<QrPage />} />
          <Route path="/signup" element={<SignUpPage />} />
          {/* Informacion de crédito */}
          <Route path="/user/*" element={<UserHomePage />} />
          <Route path="/login/*" element={<LoginPage />} />
        </Routes>
      </Router>
    </Provider>
  );
}

export default App;
