export const CREATE_CLIENTE_SUCCESS = 'CREATE_CLIENTE_SUCCESS'
export const CREATE_CLIENTE_FAIL = 'CREATE_CLIENTE_FAIL'
export const UPDATE_CLIENTE_SUCCESS = 'UPDATE_CLIENTE_SUCCESS'
export const UPDATE_CLIENTE_FAIL = 'UPDATE_CLIENTE_FAIL'
export const GET_USERS_LIST_SUCCESS='GET_USERS_LIST_SUCCESS'
export const GET_USERS_LIST_FAIL = 'GET_USERS_LIST_FAIL'
export const GET_CLIENTS_LIST_SUCCESS = 'GET_CLIENTS_LIST_SUCCESS'
export const GET_CLIENTS_LIST_FAIL = 'GET_CLIENTS_LIST_FAIL'
export const GET_CLIENTE_SUCCESS = 'GET_CLIENTE_SUCCESS'
export const GET_CLIENTE_FAIL = 'GET_CLIENTE_FAIL'
export const LOAD_CLIENT_SUCCESS = 'LOAD_CLIENT_SUCCESS'
export const LOAD_CLIENT_FAIL = 'LOAD_CLIENT_FAIL'

