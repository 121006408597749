import QRenrolledComponent from "components/nuovo/QrEnrouled";
import Navbar from "components/navigation/Navbar";
import Layout from "components/layouts/Layout";
import redux from "reducers/utils/Redux";
import { useNavigate, useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import React  from 'react';

function QrPage({ qrEnrolled }) {
  const navigate = useNavigate();
  const [QR, setQR] = useState(null);

  const params = useParams();
  const token = params.token;

  useEffect(() => {
    const fetchQR = async () => {
      try {
        const res = await qrEnrolled(token);
        const qr = res.data.qr;
        setQR(qr);
      } catch (error) {
        console.error("Error al obtener el código QR", error);
      }
    };

    fetchQR();
  }, [token, qrEnrolled]);

  
  const navigateTo = () => {
    navigate("/miCredito");
  };

  return (
    <Layout>
      <Navbar />
      <QRenrolledComponent qr={QR} navigateTo={navigateTo} />
    </Layout>
  );
}

export default redux(QrPage);
