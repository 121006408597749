import { useToast } from "@chakra-ui/react";
import NuovoReference from "./NuovoReference";
import React, { useState } from "react";
import { LoaderBasic } from "components/LoaderBasic";

export const NuovoRenew = ({
  globalEffectUpdate,
  referenceDataRenew,
  client,
}) => {
  const toast = useToast();
  const [loading, setLoading] = useState(false);
  const { step } = client;
  const onSubmit = async (dataForm) => {
    setLoading(true);
    const { data, status } = await referenceDataRenew(dataForm);
    if (status) {
      globalEffectUpdate();
    } else if (data.error_code === 3) {
      const field = data.message.split(" ")[1];
      toast({
        title: `El campo ${field} no es válido`,
        description: "Ingrese un valor válido en el campo correspondiente",
        status: "info",
        duration: 15000,
        isClosable: true,
      });
    }
    setLoading(false);
  };

  if (loading) {
    return <LoaderBasic />;
  }

  return <NuovoReference onSubmit={onSubmit} step={step} />;
};
