import Navbar from "components/navigation/Navbar";
import Signup from "components/signup/Signup";
import Layout from "components/layouts/Layout";
import { useEffect } from "react";
import redux from "reducers/utils/Redux";
import { useNavigate } from "react-router-dom";
import { CLIENT_HOME_ROUTE } from "configuration";
import React  from 'react';

import { analytcEvent } from "analytics";

function SignUpPage({
  checkAuthenticated,
  loadUser,
  login,
  sendVerificationEmailCode,
  verifyVerificationEmailCode,
  saveUserInfo,
  logout,
}) {
  const navigate = useNavigate();
  useEffect(() => {
    const verifyAuthentication = async () => {
      const { status, data } = await checkAuthenticated();
      if (status === 200) {
        if (!data.valid) {
          logout();
          return;
        }
        navigate(CLIENT_HOME_ROUTE);
      } else {
        logout();
      }
    };
    verifyAuthentication();
  }, [loadUser, checkAuthenticated, navigate, logout]);
  analytcEvent("auth", "signup");
  return (
    <Layout>
      <Navbar />

      <Signup
        login={login}
        sendVerificationEmailCode={sendVerificationEmailCode}
        verifyVerificationEmailCode={verifyVerificationEmailCode}
        saveUserInfo={saveUserInfo}
        navigate={navigate}
      />
    </Layout>
  );
}

export default redux(SignUpPage);
