import { useState } from "react";
import {
  Button,
  Input,
  Heading,
  Image,
  FormControl,
  useToast,
  Stack,
} from "@chakra-ui/react";
import { useForm } from "react-hook-form";
import INE_FRONTAL from "assets/img/INE_FRONTAL.webp";
import INE_REVERSO from "assets/img/INE_REVERSO.webp";
import { LoaderBasic } from "components/LoaderBasic";
import React from "react";
import { DEFAULT_TOAST_TIME } from "configuration";

const ImageUploadComponent = ({
  verifyOcr,
  updateClient,
  globalEffectUpdate,
}) => {
  const [images, setImages] = useState([]);
  const { register, handleSubmit } = useForm();
  const [loading, setLoading] = useState(false);
  const [selectedFileIndexes, setSelectedFileIndexes] = useState([
    false,
    false,
  ]);
  const [selectedFileNames, setSelectedFileNames] = useState([
    "Selecciona Archivo",
    "Selecciona Archivo",
  ]);
  const [isButtonEnabled, setIsButtonEnabled] = useState(false);
  const toast = useToast();

  const onSubmit = async () => {
    setLoading(true);
    const { data, status } = await verifyOcr(images);
    setLoading(false);
    if (status === 200) {
      await updateClient(data?.user);
      toast({
        position: "top",
        title: "Aceptado",
        description: "Información recibida",
        status: "success",
        duration: DEFAULT_TOAST_TIME,
        isClosable: true,
      });
      globalEffectUpdate();
    }
    if (data?.error_code === 1) {
      toast({
        position: "top",
        title: "INE rechazada",
        description: "Vuélvalo a intentar con un documento vigente",
        status: "error",
        duration: DEFAULT_TOAST_TIME,
        isClosable: true,
      });
    } else if (data?.error_code === 2) {
      toast({
        position: "top",
        title: "INE rechazada",
        description: "CIC e identificador ciudadano invalido",
        status: "error",
        duration: DEFAULT_TOAST_TIME,
        isClosable: true,
      });
    } else if (data?.error_code === 3) {
      toast({
        position: "top",
        title: "Usuario ya existente",
        description:
          "Por favor inicia sesión con la cuenta correspondiente al correo",
        status: "error",
        duration: DEFAULT_TOAST_TIME,
        isClosable: true,
      });
      globalEffectUpdate();
    } else if (data?.error_code === 4) {
      toast({
        position: "top",
        title: "Imagen no legible",
        description: "Por favor vuelve a tomar la foto de tu INE",
        status: "info",
        duration: DEFAULT_TOAST_TIME,
        isClosable: true,
      });
    } else if (data?.error_code === 5) {
      toast({
        position: "top",
        title: "INE no vigente",
        description: "Por favor inténtalo con una INE vigente",
        status: "info",
        duration: DEFAULT_TOAST_TIME,
        isClosable: true,
      });
    } else if (data?.error_code === 6) {
      toast({
        position: "top",
        title: "RFC no generado",
        description: "No se pudo generar el RFC por datos incorrectos",
        status: "error",
        duration: DEFAULT_TOAST_TIME,
        isClosable: true,
      });
    } else if (data?.error_code === 7) {
      toast({
        position: "top",
        title: "El INE no corresponde al titular",
        description: "Por favor inténtalo con el INE del titular de la cuenta",
        status: "info",
        duration: DEFAULT_TOAST_TIME,
        isClosable: true,
      });
    } else if (data?.error_code === 9) {
      toast({
        position: "top",
        title: "No se pudo verificar tu información",
        description: "Estás siendo redirigido al registro manual",
        status: "info",
        duration: DEFAULT_TOAST_TIME,
        isClosable: true,
      });
      globalEffectUpdate();
    }
    setLoading(false);
  };

  const handleImageChange = (e, index) => {
    const newImages = [...images];
    newImages[index] = e.target.files[0];
    setImages(newImages);

    const newSelectedFileIndexes = [...selectedFileIndexes];
    newSelectedFileIndexes[index] = true;
    setSelectedFileIndexes(newSelectedFileIndexes);

    const newSelectedFileNames = [...selectedFileNames];
    newSelectedFileNames[index] = e.target.files[0].name;
    setSelectedFileNames(newSelectedFileNames);

    if (newSelectedFileIndexes[0] && newSelectedFileIndexes[1]) {
      setIsButtonEnabled(true);
    }
  };

  return (
    <>
      {(loading && <LoaderBasic />) || (
        <>
          {" "}
          <Heading
            color={"blue.700"}
            w="100%"
            textAlign={"center"}
            fontWeight="normal"
            my="5%"
          >
            Escanea tu INE de ambos lados
          </Heading>
          <Stack textAlign={"center"}>
            <form
              onSubmit={handleSubmit(onSubmit)}
              style={{
                width: "80%",
                padding: "0",
                margin: "0% 0% 0% 10%",
              }}
            >
              <label htmlFor="image1">Parte frontal</label>
              <Image
                alt={"Login Image"}
                objectFit={"cover"}
                src={images[0] ? URL.createObjectURL(images[0]) : INE_FRONTAL}
                maxWidth="100%"
              />
              <br />
              <FormControl>
                <Button
                  as="label"
                  htmlFor="image1"
                  w="100%"
                  cursor="pointer"
                  borderRadius="md"
                  bg={selectedFileIndexes[0] ? "green.500" : "red.500"}
                  color="white"
                  _hover={{
                    bg: selectedFileIndexes[0] ? "green.600" : "red.600",
                  }}
                  _active={{
                    bg: selectedFileIndexes[0] ? "green.700" : "red.700",
                  }}
                  transition="background-color 0.2s"
                >
                  {selectedFileIndexes[0]
                    ? "Archivo Seleccionado"
                    : selectedFileNames[0]}
                </Button>

                <Input
                  type="file"
                  id="image1"
                  {...register("image1")}
                  onChange={(e) => handleImageChange(e, 0)}
                  accept="image/*"
                  style={{ display: "none" }}
                />
              </FormControl>

              <br />

              <label htmlFor="image2">Parte trasera</label>
              <Image
                alt={"Login Image"}
                objectFit={"cover"}
                width={"100%"}
                src={images[1] ? URL.createObjectURL(images[1]) : INE_REVERSO}
                maxWidth="100%"
              />
              <br />
              <FormControl>
                <Button
                  as="label"
                  htmlFor="image2"
                  w="100%"
                  cursor="pointer"
                  borderRadius="md"
                  bg={selectedFileIndexes[1] ? "green.500" : "red.500"}
                  color="white"
                  _hover={{
                    bg: selectedFileIndexes[1] ? "green.600" : "red.600",
                  }}
                  _active={{
                    bg: selectedFileIndexes[1] ? "green.700" : "red.700",
                  }}
                  transition="background-color 0.2s"
                >
                  {selectedFileIndexes[1]
                    ? "Archivo Seleccionado"
                    : selectedFileNames[1]}
                </Button>

                <Input
                  type="file"
                  id="image2"
                  {...register("image2")}
                  onChange={(e) => handleImageChange(e, 1)}
                  accept="image/*"
                  style={{ display: "none" }}
                />
              </FormControl>

              <Button
                type="submit"
                mt={4}
                bg="blue.500"
                color="white"
                isDisabled={!isButtonEnabled}
              >
                Subir Imágenes y Verificar
              </Button>
            </form>
          </Stack>{" "}
        </>
      )}
    </>
  );
};

export default ImageUploadComponent;
