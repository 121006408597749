import { useState } from "react";
import {
  Button,
  Heading,
  FormControl,
  FormLabel,
  Input,
  InputGroup,
  InputRightElement,
  FormHelperText,
  Grid,
} from "@chakra-ui/react";
import { useForm } from "react-hook-form";
import {
  DEFAULT_TOAST_TIME,
  DEFAULT_EMAIL_PLACEHOLDER,
  DEFAULT_PASSWORD_PLACEHOLDER,
} from "configuration";
import React from "react";

const InfoPersonal = ({ toast, setDataForm, setCaptchaOpen }) => {
  const [show, setShow] = useState(false);
  const { register, handleSubmit, setValue } = useForm();

  const showPassword = () => setShow(!show);

  const onSubmit = async (data) => {
    if (data.password !== data.re_password) {
      toast({
        position: "top",
        title: "Error de contraseña",
        description: "Las contraseñas deben ser iguales",
        status: "error",
        duration: DEFAULT_TOAST_TIME,
        isClosable: true,
      });
      return;
    }
    let newData = {
      ...data,
    };
    setDataForm(newData);
    setCaptchaOpen(true);
  };

  // const debouncedOnSubmit = debounce(onSubmit, 1000);
  // Función para capitalizar cada palabra en una cadena
  const capitalizarCadaPalabra = (frase) => {
    return frase
      .split(" ")
      .map(
        (palabra) =>
          palabra.charAt(0).toUpperCase() + palabra.slice(1).toLowerCase()
      )
      .join(" ");
  };

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Heading
          color={"blue.700"}
          w="100%"
          textAlign={"center"}
          fontWeight="normal"
          mb="2%"
        >
          ¡Comienza tu registro!
        </Heading>
        <Grid
          mt={"2%"}
          templateColumns={{
            base: "repeat(1, 1fr)",
            md: "repeat(2, 1fr)",
          }}
          gap={6}
        >
          <FormControl>
            <FormLabel
              htmlFor="first_name"
              fontSize={"small"}
              fontWeight={"semibold"}
            >
              Nombre
            </FormLabel>
            <Input
              id="first_name"
              {...register("first_name")}
              placeholder="Nombre como aparece en tu INE"
              required
              size="sm"
              rounded="md"
              onChange={(e) => {
                const cleanedValue = e.target.value.replace(
                  /[^A-Za-záéíóúÁÉÍÓÚüÜñÑ ]/g,
                  ""
                );
                setValue("first_name", capitalizarCadaPalabra(cleanedValue));
              }}
            />
          </FormControl>

          <FormControl>
            <FormLabel
              htmlFor="second_name"
              fontSize={"small"}
              fontWeight={"semibold"}
            >
              Segundo Nombre
            </FormLabel>
            <Input
              id="second_name"
              {...register("second_name")}
              placeholder="En caso de tenerlo"
              size="sm"
              rounded="md"
              onChange={(e) => {
                const cleanedValue = e.target.value.replace(
                  /[^A-Za-záéíóúÁÉÍÓÚüÜñÑ ]/g,
                  ""
                );
                setValue("second_name", capitalizarCadaPalabra(cleanedValue));
              }}
            />
          </FormControl>

          <FormControl>
            <FormLabel
              htmlFor="apellido"
              fontSize={"small"}
              fontWeight={"semibold"}
            >
              Apellido Paterno
            </FormLabel>
            <Input
              id="apellido"
              {...register("apellido")}
              placeholder="Apellido Paterno"
              size="sm"
              rounded="md"
              onChange={(e) => {
                const cleanedValue = e.target.value.replace(
                  /[^A-Za-záéíóúÁÉÍÓÚüÜñÑ ]/g,
                  ""
                );
                setValue("apellido", cleanedValue);
              }}
              required
            />
          </FormControl>

          <FormControl>
            <FormLabel
              htmlFor="materno"
              fontSize={"small"}
              fontWeight={"semibold"}
            >
              Apellido Materno
            </FormLabel>
            <Input
              id="materno"
              {...register("materno")}
              placeholder="Apellido Materno"
              required
              size="sm"
              rounded="md"
              onChange={(e) => {
                const cleanedValue = e.target.value.replace(
                  /[^A-Za-záéíóúÁÉÍÓÚüÜñÑ ]/g,
                  ""
                );
                setValue("materno", cleanedValue);
              }}
            />
          </FormControl>

          <FormControl>
            <FormLabel
              htmlFor="email"
              fontSize={"small"}
              fontWeight={"semibold"}
            >
              Correo Electrónico
            </FormLabel>
            <Input
              id="email"
              {...register("email")}
              type="email"
              placeholder={DEFAULT_EMAIL_PLACEHOLDER}
              size="sm"
              rounded="md"
              required
            />
          </FormControl>
          <FormControl>
            <FormLabel
              htmlFor="telefono"
              fontSize={"small"}
              fontWeight={"semibold"}
            >
              Teléfono
            </FormLabel>
            <Input
              id="telefono"
              {...register("telefono")}
              type="tel"
              placeholder="5555555555"
              onChange={(e) => {
                const cleanedValue = e.target.value.replace(/[^0-9]/g, "");
                setValue("telefono", cleanedValue);
              }}
              required
              pattern="[0-9]+"
              size="sm"
              rounded="md"
              maxLength={"10"}
            />
          </FormControl>

          <FormControl>
            <FormLabel
              htmlFor="password"
              fontSize={"small"}
              fontWeight={"semibold"}
            >
              Contraseña
            </FormLabel>
            <InputGroup size="sm">
              <Input
                id="password"
                {...register("password")}
                pr="4.5rem"
                type={show ? "text" : "password"}
                placeholder={DEFAULT_PASSWORD_PLACEHOLDER}
                size="sm"
                rounded="md"
                required
                pattern="^(?=.*[a-z])(?=.*[A-Z])(?=.*\d).{8,}$"
                onChange={(e) => {
                  const cleanedValue = e.target.value.replace(/\s/g, "");
                  setValue("password", cleanedValue);
                }}
              />
              <InputRightElement width="5rem">
                <Button h="1.75rem" size="sm" onClick={showPassword}>
                  {show ? "Ocultar" : "Mostrar"}
                </Button>
              </InputRightElement>
            </InputGroup>
            <FormHelperText fontSize={"small"} fontWeight="bold">
              La contraseña debe contener al menos 8 caracteres, una minúscula,
              una mayúscula y un número.
            </FormHelperText>
          </FormControl>

          <FormControl>
            <FormLabel
              htmlFor="re_password"
              fontSize={"small"}
              fontWeight={"semibold"}
            >
              Vuelve a escribir la contraseña
            </FormLabel>
            <InputGroup size="md">
              <Input
                id="re_password"
                {...register("re_password")}
                pr="4.5rem"
                type={show ? "text" : "password"}
                placeholder={DEFAULT_PASSWORD_PLACEHOLDER}
                size="sm"
                rounded="md"
                onChange={(e) => {
                  const cleanedValue = e.target.value.replace(/\s/g, "");
                  setValue("re_password", cleanedValue);
                }}
                required
                pattern=".{8,}$"
              />
              <InputRightElement width="4.5rem"></InputRightElement>
            </InputGroup>
          </FormControl>
        </Grid>
        <Button mt={2} type="submit" colorScheme={"blue"} variant={"solid"}>
          Continuar
        </Button>
      </form>
    </>
  );
};

export default InfoPersonal;
