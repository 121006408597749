import React, { useState } from "react";
import {
  Box,
  Button,
  Heading,
  Center,
  FormControl,
  FormLabel,
  HStack,
  PinInput,
  PinInputField,
  Text,
  useToast,
} from "@chakra-ui/react";
import CountdownLoader from "components/CountdownLoader";

const ValidateCodeForm = ({ onValidate, onSendSMS }) => {
  const [btnIsDisabled, setBtnIsDisabled] = useState(true);
  const [buttonVisible, setButtonVisible] = useState(true);
  const [code, setCode] = useState(""); // Guardar el código completo como string

  const handleCountdownEnd = () => {
    setButtonVisible(true);
  };

  const handleClick = async () => {
    setButtonVisible(false); // Ocultar el botón de enviar SMS
    const res = await onSendSMS();
    if (!res) setButtonVisible(true); // Si la llamada a la API fue exitosa, iniciar el temporizador
  };

  const handleComplete = (value) => {
    setCode(value);
    setBtnIsDisabled(false); // Habilitar botón cuando el código esté completo
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    // Llamar a la función de validación proporcionada
    onValidate(code);

    // Reiniciar el formulario
    setBtnIsDisabled(true);
    setCode(""); // Vaciar el código
  };

  return (
    <Box
      p="6"
      borderWidth="1px"
      borderRadius="lg"
      boxShadow="lg"
      bg="white"
      maxWidth="400px"
      mx="auto"
      mt="10"
    >
      <Center>
        <Heading
          color="blue.700"
          w="90%"
          textAlign="center"
          fontWeight="normal"
          mb="2%"
        >
          Completa la verificación del teléfono de tu contacto alternativo
        </Heading>
      </Center>
      <FormControl mt="1%">
        <Center>
          <FormLabel maxW="60%" textAlign="center">
            Pídele el código SMS que le enviamos a tu contacto alternativo para
            finalizar tu solicitud.
          </FormLabel>
        </Center>
        <HStack my={3} justifyContent="center">
          <PinInput
            id="smsPIN"
            autoFocus
            onComplete={handleComplete}
            value={code}
            onChange={setCode} // Actualizar el estado cuando cambie el código
          >
            <PinInputField />
            <PinInputField />
            <PinInputField />
            <PinInputField />
            <PinInputField />
            <PinInputField />
          </PinInput>
        </HStack>
      </FormControl>

      <Box mt="5" mx="5">
        <Center>
          <Box d="flex" flexDirection="column" alignItems="center" width="100%">
            <Center>
              {buttonVisible ? (
                <Button
                  mt={2}
                  colorScheme="blue"
                  variant="solid"
                  id="sendSMS"
                  onClick={handleClick}
                  width="full" // Hacer que el botón ocupe todo el ancho
                  size="lg" // Tamaño más grande para mayor impacto
                >
                  Enviar SMS
                </Button>
              ) : (
                <CountdownLoader onCountdownEnd={handleCountdownEnd} />
              )}
            </Center>
            {btnIsDisabled && (
              <Center>
                <Text maxW="50%" align="center" fontSize="sm" color="tomato">
                  El botón de validar se habilitará una vez que ingreses el
                  código de verificación.
                </Text>
              </Center>
            )}
            <Box mt={3} width="full">
              {" "}
              {/* Espaciado entre los botones */}
              {!btnIsDisabled && (
                <Button
                  colorScheme="blue"
                  variant="solid"
                  id="validateSMS"
                  type="submit"
                  onClick={handleSubmit}
                  width="full" // Hacer que el botón ocupe todo el ancho
                  size="lg" // Tamaño más grande para mayor impacto
                >
                  Validar Código
                </Button>
              )}
            </Box>
          </Box>
        </Center>
      </Box>
    </Box>
  );
};

export default ValidateCodeForm;
