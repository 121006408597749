import FormasDePago from "components/inicio/FormasDePago";
import React  from 'react';


function FormasPago() {
  return (
    <>
      <FormasDePago />
    </>
  );
}

export default FormasPago;
