import { FormControl, FormLabel, Input } from "@chakra-ui/react";
import React, { useState, useEffect } from "react";

export const FormFieldValidation = ({
  fieldData,
  isDisabled = true,
  setValue,
}) => {
  const { label, value, id, placeholder } = fieldData;

  // Estado local para manejar el valor del input
  const [inputValue, setInputValue] = useState(value || "");

  // Actualiza el estado cuando el valor cambia externamente
  useEffect(() => {
    setInputValue(value);
  }, [value]);

  // Función onChange para manejar cambios en el campo de entrada
  const handleChange = (e) => {
    const newValue = e.target.value;
    setInputValue(newValue); // Actualiza el valor del input
    setValue(newValue); // Notifica al componente padre del nuevo valor
  };

  return (
    <FormControl>
      <FormLabel htmlFor={id} fontSize={"small"} fontWeight={"semibold"}>
        {label}
      </FormLabel>
      <Input
        id={id}
        placeholder={placeholder}
        size="sm"
        rounded="md"
        isDisabled={isDisabled}
        value={inputValue} // El valor se actualiza con el estado local
        onChange={handleChange} // Usamos la función handleChange aquí
      />
    </FormControl>
  );
};
