import React from "react";
import ReactDOM from "react-dom/client";
import "./styles/index.css";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";
import App from "./App";
import { ChakraProvider } from "@chakra-ui/react";
import "styles/loader.css";
import * as Sentry from "@sentry/react";

Sentry.init({
  dsn: "https://161494acdc3b0a5d5908556f2d00e774@o4507556606509056.ingest.us.sentry.io/4507556607885312",
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration({
      networkDetailAllowUrls: ["https://api.bund.mx/api/clients/"],
      networkRequestHeaders: ["X-Request-Id"],
      networkResponseHeaders: ["X-Request-Id"],
    }),
  ],
  // Performance Monitoring
  tracesSampleRate: 1.0, //  Capture 100% of the transactions
  // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: [
    "localhost",
    /^https:\/\/bund\.mx\//,
    /^https:\/\/bund\.mx\/api/,
  ],
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <ChakraProvider>
      <App />
    </ChakraProvider>
  </React.StrictMode>
);
