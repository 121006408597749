import { useState, useEffect } from "react";
import {
  Box,
  CircularProgress,
  CircularProgressLabel,
  Text,
} from "@chakra-ui/react";
import React from "react";

const CountdownLoader = ({
  onCountdownEnd,
  text = "En caso de no recibir el código, podrás solicitar un reenvío en unos instantes",
  timer = 60,
}) => {
  const [secondsLeft, setSecondsLeft] = useState(timer);

  useEffect(() => {
    if (secondsLeft > 0) {
      const countdownInterval = setInterval(() => {
        setSecondsLeft((prevSeconds) => prevSeconds - 1);
      }, 1000);

      return () => {
        clearInterval(countdownInterval);
      };
    }
  }, [secondsLeft]);

  useEffect(() => {
    if (secondsLeft <= 0) {
      setSecondsLeft(0); // Aseguramos que el valor no sea negativo
      onCountdownEnd();
    }
  }, [secondsLeft, onCountdownEnd]);

  const formatTime = (time) => {
    const minutes = Math.floor(time / 60);
    const seconds = time % 60;
    return `${String(minutes).padStart(2, "0")}:${String(seconds).padStart(
      2,
      "0"
    )}`;
  };

  return (
    <Box textAlign="center" my={5} mx={10} maxW="30%">
      <CircularProgress value={(secondsLeft / timer) * 100} color="blue.300">
        <CircularProgressLabel>{formatTime(secondsLeft)}</CircularProgressLabel>
      </CircularProgress>
      <Text align={"center"} fontSize="xs" color={"orange.300"}>
        {text}
      </Text>
    </Box>
  );
};

export default CountdownLoader;
