import {
  FormControl,
  FormLabel,
  Input,
  FormHelperText,
  Button,
  Center,
  Checkbox,
  Link,
  useToast,
} from "@chakra-ui/react";
import { useForm } from "react-hook-form";
import { useState } from "react";
import { DEFAULT_TOAST_TIME, StepDictionary } from "configuration";
import CountdownLoader from "components/CountdownLoader";
import React from "react";
import { LoaderBasic } from "components/LoaderBasic";

export const BuroAuthorization = ({
  client,
  updateClient,
  sendSMS,
  validateNip,
  globalEffectUpdate,
  sendSMSRenew,
  validateNipRenew,
}) => {
  const [isButtonEnabled, setIsButtonEnabled] = useState(false);
  const [isButtonEnabled2, setIsButtonEnabled2] = useState(false);
  const [isSendButtonEnabled, setIsSendButtonEnabled] = useState(true);
  const [sendButtonPressed, setSendButtonPressed] = useState(0);
  const [loading, setLoading] = useState(false);

  const { step } = client;
  const steps = StepDictionary;

  const toast = useToast();

  const handleCheckboxChange = () => {
    setIsButtonEnabled(!isButtonEnabled);
  };

  const handleCheckboxChang2 = () => {
    setIsButtonEnabled2(!isButtonEnabled2);
  };
  const handleCountdownEnd = () => {
    setIsSendButtonEnabled(true);
  };

  const { register, handleSubmit } = useForm();

  const handleChange = (event) => {
    const nip = event.target.value;
    if (nip) {
      setIsButtonEnabled(true);
    } else {
      setIsButtonEnabled(false);
    }
  };

  const handleClick = async () => {
    setSendButtonPressed(sendButtonPressed + 1);
    setIsSendButtonEnabled(false);
    if (step === steps.VALIDATE_CODE_TO_RENEW) {
      const res = await sendSMSRenew();
      if (res) {
        toast({
          title: "Se ha enviado un SMS con tu NIP",
          status: "success",
          duration: DEFAULT_TOAST_TIME,
          isClosable: true,
        });
      } else {
        toast({
          title: "No se pudo enviar el SMS por intermitencia",
          description: "Intenta de nuevo en unos minutos",
          status: "error",
          duration: DEFAULT_TOAST_TIME,
          isClosable: true,
        });
      }
    } else {
      const res = await sendSMS();
      if (res) {
        toast({
          title: "Se ha enviado un SMS con tu NIP",
          status: "success",
          duration: DEFAULT_TOAST_TIME,
          isClosable: true,
        });
      } else {
        toast({
          title: "No se pudo enviar el SMS por intermitencia",
          description: "Intenta de nuevo en unos minutos",
          status: "error",
          duration: DEFAULT_TOAST_TIME,
          isClosable: true,
        });
      }
    }
  };

  const onSubmit = async (dataForm) => {
    setLoading(true);
    if (step === steps.VALIDATE_CODE_TO_RENEW) {
      const { data } = await validateNipRenew(dataForm.nip);
      setLoading(false);
      if (data.valid) {
        await updateClient(data.client);
        globalEffectUpdate();
      } else {
        data.Code === 1
          ? setIsSendButtonEnabled(true)
          : setIsSendButtonEnabled(false);
        toast({
          title: "Es necesario reenviar el SMS",
          description: "Ingresa el nuevo NIP",
          status: "error",
          duration: DEFAULT_TOAST_TIME,
          isClosable: true,
        });
      }
    } else {
      const { data } = await validateNip(dataForm.nip);
      setLoading(false);
      if (data.valid) {
        await updateClient(data.client);
        globalEffectUpdate();
      } else {
        data.Code === 1
          ? setIsSendButtonEnabled(true)
          : setIsSendButtonEnabled(false);
        toast({
          title: "Es necesario reenviar el SMS",
          description: "Ingresa el nuevo NIP",
          status: "error",
          duration: DEFAULT_TOAST_TIME,
          isClosable: true,
        });
      }
    }
  };

  if (loading) {
    return <LoaderBasic />;
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div>
        <FormControl>
          <Checkbox
            mt={2}
            size="sm"
            colorScheme="blue"
            onChange={handleCheckboxChang2}
          >
            He leído el{" "}
            <Link href="/avisoPrivacidad" target="_blank" color="blue.700">
              Aviso de Privacidad
            </Link>
          </Checkbox>
          <br />
          <Checkbox
            mt={2}
            size="sm"
            colorScheme="blue"
            onChange={handleCheckboxChange}
          >
            Acepto{" "}
            <Link href="/terminosYcondiciones" target="_blank" color="blue.700">
              Términos y Condiciones
            </Link>{" "}
            y cláusula de medios electrónicos tales como NIP
          </Checkbox>
          <FormHelperText align={"justify"} fontSize={"small"}>
            <Center mb={1} fontWeight={"bold"}>
              Autorización para solicitar Reportes de Credito
            </Center>
            Autorizo expresamente a Promotora Bund SAPI de CV, para que lleve a
            cabo Investigaciones sobre mi comportamiento Crediticio en las
            Sociedades de Información Crediticia (SIC) que estime conveniente.
            Conozco la naturaleza y alcance de la Información que se solicitará,
            del uso que se le dará y que se podrán realizar consultas periódicas
            de mi historial crediticio. Consiento que esta autorización tenga
            una vigencia de 3 años contados a partir de hoy, y en su caso
            mientras mantengamos relación jurídica. Acepto que este documento
            quede bajo propiedad de Promotora Bund SAPI de CV para efectos de
            control y cumplimiento del articulo 28 de la LRSIC.
          </FormHelperText>
        </FormControl>
        <Center></Center>
        <FormControl mt={"1%"}>
          <Center>
            <FormLabel htmlFor="nip" fontSize={"small"} fontWeight={"semibold"}>
              Captura el NIP que se te enviara por SMS para finalizar tu
              solicitud
            </FormLabel>
          </Center>
          <Center>
            {isSendButtonEnabled ? (
              <Button
                isDisabled={!isButtonEnabled || !isButtonEnabled2}
                mt={2}
                mb={2}
                colorScheme={"blue"}
                variant={"solid"}
                onClick={handleClick}
                id="sendSms"
              >
                {sendButtonPressed === 0 ? "Enviar SMS" : "Reenviar SMS"}
              </Button>
            ) : (
              <CountdownLoader onCountdownEnd={handleCountdownEnd} />
            )}
          </Center>
          <Center>
            <Input
              type="number"
              onChange={handleChange}
              id="nip"
              required
              size="sm"
              rounded="md"
              minLength={4}
              maxLength={4}
              maxW="130"
              disabled={sendButtonPressed === 0 ? true : false}
              {...register("nip")}
            />
          </Center>
        </FormControl>
        <Center>
          <Button
            isDisabled={sendButtonPressed === 0 ? true : false}
            mt={2}
            colorScheme={"blue"}
            variant={"solid"}
            id="validateNip"
            type="submit"
          >
            Validar NIP
          </Button>
        </Center>
      </div>
    </form>
  );
};
