import {
  Button,
  FormControl,
  Flex,
  Heading,
  Input,
  Stack,
  FormLabel,
  FormHelperText,
  useToast,
} from "@chakra-ui/react";
import { DEFAULT_TOAST_TIME } from "configuration";
import { useForm } from "react-hook-form";
import { useParams, useNavigate } from "react-router-dom";
import React from "react";

function ConfirmPasswordPage({ resetPasswordConfirm }) {
  const { register, handleSubmit } = useForm();
  const { uid, token } = useParams();
  const toast = useToast();
  const navigate = useNavigate();

  const onSubmit = async (dataForm) => {
    const { new_password, re_new_password } = dataForm;
    if (new_password !== re_new_password) {
      toast({
        position: "top",
        title: "Las contraseñas no coinciden",
        description: "Las contraseñas deben coincidir",
        status: "error",
        duration: DEFAULT_TOAST_TIME,
        isClosable: true,
      });
      return;
    }
    const body = {
      recover_id: uid,
      recover_token: token,
      password: new_password,
    };
    const { data, status } = await resetPasswordConfirm(body);
    if (status === 200) {
      navigate("/login");
      toast({
        position: "top",
        title: "Contraseña cambiada",
        description: "Tu contraseña ha sido cambiada exitosamente",
        status: "success",
        duration: DEFAULT_TOAST_TIME,
        isClosable: true,
      });
    } else if (status === 400 && data.error_code === 3) {
      toast({
        position: "top",
        title: "Contraseña con formato incorrecto",
        description: "Ingrese una contraseña válida",
        status: "error",
        duration: DEFAULT_TOAST_TIME,
        isClosable: true,
      });
    } else if (status === 400 && data.error_code === 2) {
      toast({
        position: "top",
        title: "Tiempo de expiración excedido",
        description: "Genere una nueva solicitud de cambio de contraseña",
        status: "error",
        duration: DEFAULT_TOAST_TIME,
        isClosable: true,
      });
    }
  };

  return (
    <Flex minH={"90vh"} align={"center"} justify={"center"} bg={"gray.50"}>
      <Stack
        spacing={4}
        w={"full"}
        maxW={"md"}
        bg={"white"}
        rounded={"xl"}
        boxShadow={"lg"}
        p={6}
        my={12}
      >
        <Heading lineHeight={1.1} fontSize={{ base: "2xl", md: "3xl" }}>
          Crea una nueva contraseña
        </Heading>
        <form onSubmit={handleSubmit(onSubmit)}>
          <FormControl id="new_password">
            <FormLabel>Nueva contraseña</FormLabel>
            <Input
              {...register("new_password")}
              type="password"
              pattern="(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}"
              required
            />
            <FormHelperText fontSize={"small"}>
              La contraseña debe contener al menos 8 carácteres, una minuscula,
              una mayuscula y un número.
            </FormHelperText>
          </FormControl>
          <FormControl id="re_new_password">
            <FormLabel>Escribe de nuevo la contraseña</FormLabel>
            <Input
              {...register("re_new_password")}
              type="password"
              pattern="(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}"
              required
            />
            <FormHelperText fontSize={"small"}>
              Las contraseñas deben coincidir
            </FormHelperText>
          </FormControl>
          <Stack spacing={6}>
            <Button
              type="submit"
              mt={2}
              bg={"blue.400"}
              color={"white"}
              _hover={{
                bg: "blue.500",
              }}
            >
              Cambiar contraseña
            </Button>
          </Stack>
        </form>
      </Stack>
    </Flex>
  );
}

export default ConfirmPasswordPage;
