import ImageUploadComponent from "components/nuovo/OCR";
import Direccion from "components/signup/Direccion";
import Betado from "components/signup/Betado";
import Ine from "components/signup/Ine";
import { Box } from "@chakra-ui/react";
import redux from "reducers/utils/Redux";
import { BuroAuthorization } from "components/signup/BuroAuthorization";
import { EmailVerification } from "components/signup/EmailVerification";
import { StepDictionary } from "configuration";
import { RenewEmail } from "components/user/RenewEmail";
import { RenewPhone } from "components/user/RenewPhone";
import { NuovoRenew } from "components/user/NuovoRenew";
import { ScrollToTop } from "components/ScrollToTop";
import { BankAcountConfirmation } from "components/user/BankAcountConfirmation";
import { PhoneConfirmation } from "components/user/PhoneConfirmation";
import React from "react";

import { analytcEvent } from "analytics";
import RfcAndCurpPage from "./RfcAndCurpPage";
import { CicAndId } from "components/user/CicAndId";

function DatosPersonales({
  user,
  verifyOcr,
  updateClient,
  validateIne,
  validatePostalCode,
  updateDirection,
  sendSMS,
  validatePhoneNumber,
  validateNip,
  sendVerificationEmailCode,
  verifyVerificationEmailCode,
  loadUser,
  globalEffectUpdate,
  confirmEmail,
  confirmPhone,
  sendSMSRenew,
  validateNipRenew,
  ineManualRenewal,
  nuovoConfirm,
  referenceDataRenew,
  confirmBankData,
  getBankData,
}) {
  const steps = StepDictionary;
  const step = user.step;
  let componentToRender = null;

  if (step === steps.LOCKED) {
    analytcEvent("personalData", "locked");
    componentToRender = <Betado />;
  } else if (step === steps.CONFIRM_EMAIL) {
    analytcEvent("personalData", "confirm_email");
    componentToRender = (
      <EmailVerification
        user={user}
        updateClient={updateClient}
        sendVerificationEmailCode={sendVerificationEmailCode}
        verifyVerificationEmailCode={verifyVerificationEmailCode}
        loadUser={loadUser}
        globalEffectUpdate={globalEffectUpdate}
      />
    );
  } else if (step === steps.OCR_INE) {
    analytcEvent("personalData", "ocr");
    componentToRender = (
      <ImageUploadComponent
        verifyOcr={verifyOcr}
        updateClient={updateClient}
        globalEffectUpdate={globalEffectUpdate}
      />
    );
  } else if (step === steps.INE_MANUAL_RENEWAL) {
    analytcEvent("personalData", "ine_manual");
    componentToRender = (
      <Ine
        ineManualRenewal={ineManualRenewal}
        validateIne={validateIne}
        client={user}
        updateClient={updateClient}
        globalEffectUpdate={globalEffectUpdate}
      />
    );
  } else if (step === steps.INE_MANUAL) {
    analytcEvent("personalData", "ine_manual");
    componentToRender = (
      <CicAndId
        user={user}
        validateIne={validateIne}
        updateClient={updateClient}
        globalEffectUpdate={globalEffectUpdate}
      />
    );
  } else if (
    step === steps.SAVE_ADDRESS ||
    step === steps.SAVE_ADDRESS_RENEWAL
  ) {
    analytcEvent("personalData", "addres");
    componentToRender = (
      <Direccion
        client={user}
        validatePostalCode={validatePostalCode}
        updateDirection={updateDirection}
        updateClient={updateClient}
        globalEffectUpdate={globalEffectUpdate}
      />
    );
  } else if (
    step === steps.VALIDATE_PHONE ||
    step === steps.VALIDATE_CODE_TO_RENEW
  ) {
    analytcEvent("personalData", "validate_phone");
    componentToRender = (
      <BuroAuthorization
        client={user}
        updateClient={updateClient}
        validatePhoneNumber={validatePhoneNumber}
        sendSMS={sendSMS}
        validateNip={validateNip}
        globalEffectUpdate={globalEffectUpdate}
        sendSMSRenew={sendSMSRenew}
        validateNipRenew={validateNipRenew}
      />
    );
  } else if (step === steps.VERIFY_RENEWAL_EMAIL_INFORMATION) {
    analytcEvent("personalData", "renew_email");
    componentToRender = (
      <RenewEmail
        user={user}
        confirmEmail={confirmEmail}
        globalEffectUpdate={globalEffectUpdate}
      />
    );
  } else if (step === steps.NUOVO_RENEWAL) {
    analytcEvent("personalData", "nuovo_renewal_confirmation");
    componentToRender = (
      <PhoneConfirmation
        client={user}
        nuovoConfirm={nuovoConfirm}
        globalEffectUpdate={globalEffectUpdate}
      />
    );
  } else if (step === steps.VERIFY_RENEWAL_PHONE_INFORMATION) {
    analytcEvent("personalData", "renew_phone");
    componentToRender = (
      <RenewPhone
        user={user}
        confirmPhone={confirmPhone}
        globalEffectUpdate={globalEffectUpdate}
      />
    );
  } else if (step === steps.REFERENCE_NUOVO) {
    analytcEvent("personalData", "nuovo_renewal");
    componentToRender = (
      <NuovoRenew
        client={user}
        referenceDataRenew={referenceDataRenew}
        globalEffectUpdate={globalEffectUpdate}
      />
    );
  } else if (step === steps.CONFIRM_BANK_DATA) {
    analytcEvent("personalData", "bank_data_confirm");
    componentToRender = (
      <BankAcountConfirmation
        client={user}
        globalEffectUpdate={globalEffectUpdate}
        confirmBankData={confirmBankData}
        getBankData={getBankData}
      />
    );
  } else if (step === steps.UPLOAD_RFC_CURP) {
    componentToRender = (
      <RfcAndCurpPage user={user} globalEffectUpdate={globalEffectUpdate} />
    );
  }
  return (
    <>
      <ScrollToTop />
      <Box
        borderWidth="1px"
        rounded="lg"
        shadow="1px 1px 3px rgba(0,0,0,0.3)"
        maxWidth={800}
        minWidth={350}
        p={6}
        m="10px auto"
      >
        {componentToRender}
      </Box>
    </>
  );
}

export default redux(DatosPersonales);
