import { Button, Container, Flex, Grid, Heading } from "@chakra-ui/react";
import { QRCodeSVG } from "qrcode.react";
import React from "react";

function QRenrolledComponent({ qr, navigateTo }) {
  return (
    <Grid
      mb={10}
      templateColumns={{
        base: "repeat(1, 1fr)",
        md: "repeat(2, 1fr)",
      }}
      align={"center"}
      gap={{ base: "8", md: "5" }}
    >
      <Container maxW={"90vw"}>
        <Heading
          color={"blue.700"}
          w="100%"
          textAlign={"center"}
          fontWeight="normal"
          my="5%"
        >
          QR de enrolamiento
        </Heading>

        <Flex justify={"center"}>
          <Flex justify="center" align="center" h="35vh">
            <QRCodeSVG value={qr} width={250} height={250} />
          </Flex>
        </Flex>

        <Flex
          mt={"2%"}
          justifyContent={"center"}
          color={"blue.700"}
          w="100%"
          my="5%"
        >
          <Button onClick={navigateTo} colorScheme={"blue"}>
            Da click para continuar
          </Button>
        </Flex>
      </Container>
    </Grid>
  );
}

export default QRenrolledComponent;
