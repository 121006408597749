// src/PDFViewer.js
import { Worker, Viewer, SpecialZoomLevel } from "@react-pdf-viewer/core";
import "@react-pdf-viewer/core/lib/styles/index.css";
import { pdfjs } from "react-pdf";
import { Box } from "@chakra-ui/react";
import React  from 'react';

const PDFViewer = ({ pdfUrl }) => {
  // Ruta relativa al archivo PDF

  return (
    <Box
      width={{ base: "320px", md: "750px" }}
      height={{ base: "500", md: "600px" }}
      border="1px solid #e8e8e8"
      borderRadius="5px"
      overflow="auto"
      p={2}
    >
      <Worker
        workerUrl={`https://unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.js`}
      >
        <Viewer
          fileUrl={pdfUrl}
          defaultScale={SpecialZoomLevel.PageFit}
          externalLinkTarget={"_blank"}
        />
      </Worker>
    </Box>
  );
};

export default PDFViewer;
