import Footer from "components/navigation/Footer";
import Navbar from "components/navigation/Navbar";
import Layout from "components/layouts/Layout";
import { Route, Routes } from "react-router";
import FormasPago from "pages/main/FormasPago";
import CatPage from "pages/main/CatPage";
import PreguntasPages from "pages/main/PreguntasPages";
import TerminosCondiciones from "pages/main/TerminosCondiciones";
import CookiesPage from "pages/main/CookiesPage";
import AvisoPrivacidad from "pages/main/AvisoPrivacidad";
import Home from "pages/Home";
import React from "react";
import TutorialesPage from "pages/main/TutorialesPage";

function IndexPage() {
  return (
    <>
      <Layout>
        <Navbar />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/cat" element={<CatPage />} />
          <Route
            path="/terminosYcondiciones"
            element={<TerminosCondiciones />}
          />
          <Route path="/tutoriales" element={<TutorialesPage />} />
          <Route path="/cookies" element={<CookiesPage />} />
          <Route path="/avisoPrivacidad" element={<AvisoPrivacidad />} />
          <Route path="/preguntasFrecuentes" element={<PreguntasPages />} />
          <Route path="/formasDePago" element={<FormasPago />} />
        </Routes>
        <Footer />
      </Layout>
    </>
  );
}

export default IndexPage;
