import Actions from "./Actions";

class IneValidator extends Actions {
  state;

  constructor() {
    super();
    this.state = {};
  }

  verifyOcr(images) {
    const body = new FormData();
    body.append("img1", images[0]);
    body.append("img2", images[1]);
    return this.post("/clients/identification", body, VERIFICAR_OCR);
  }

  validateIne(data) {
    const body = {
      cic: data.cic,
      ocr: data.ocr,
      identificadorCiudadano: data.idCiudadano,
    };
    return this.post(
      "/clients/identification/manual",
      body,
      "VALIDATE_INE_SUCCESS"
    );
  }

  validatePostalCode(cp) {
    const body = { cp: cp };
    return this.post("/clients/validate/cp/", body, COMPOMEX_SUCCESS);
  }

  ineManualRenewal(data) {
    const body = {
      cic: data.cic,
      identificadorCiudadano: data.idCiudadano,
    };
    return this.post(
      "/clients/identification/manual/renewal",
      body,
      "INE_RENEWAL_SUCCESS"
    );
  }

  confirmOCRInfo(valid) {
    const body = {
      is_valid: valid,
    };
    return this.post("/clients/confirm/data", body, "OCR_CONFIRMED");
  }

  rfcAndCurp(data) {
    const body = {
      rfc: data.rfc,
      curp: data.curp,
      nombre: data.nombre,
      segundo_nombre: data.segundo_nombre,
      apellidoP: data.apellidoP,
      apellidoM: data.apellidoM,
      ocupacion: data.ocupacion,
    };
    return this.post(
      "/clients/identification/rfc_curp",
      body,
      "RFC_CURP_SUCCESS"
    );
  }
}

export const VALID_OCR_DATA_FAIL = "VALID_OCR_DATA_FAIL";
export const VERIFICAR_OCR = "VERIFICAR_OCR";
export const ERROR_VERIFICAR_OCR = "ERROR_VERIFICAR_OCR";
export const VALIDATE_INE_SUCCESS = "VALIDATE_INE_SUCCESS";
export const VALIDATE_INE_FAIL = "VALIDATE_INE_FAIL";
export const SET_INE_LOADING = "SET_INE_LOADING";

export const COMPOMEX_SUCCESS = "COMPOMEX_SUCCESS";
export const COMPOMEX_FAIL = "COMPOMEX_FAIL";
export const SET_CP_LOADING = "SET_CP_LOADING";
export const REMOVE_CP_LOADING = "REMOVE_CP_LOADING";

export default IneValidator;
