import Actions from "./helpers/Actions";

class Client extends Actions {
  state;

  constructor() {
    super();
    this.state = {};
  }

  updateClient() {
    return async (dispatch) => {
      dispatch({
        type: LOAD_CLIENT_SUCCESS,
        payload: {},
      });
    };
  }

  updateDirection(body) {
    return this.post(`/clients/direction/`, body, UPDATE_CLIENT_SUCCESS);
  }

  getDeviceInformation() {
    return this.get(`/clients/device`, LOAD_CLIENT_DEVICE_SUCCESS);
  }

  loadClient() {
    return this.get(`/clients/`, LOAD_CLIENT_SUCCESS);
  }
  confirmEmail(body) {
    return this.post(`/clients/email/approved`, body, VALID_EMAIL_SUCCESS);
  }
  confirmPhone(body) {
    return this.post(`/clients/phone/approved`, body, VALID_PHONE_SUCCESS);
  }
}

export default Client;
export const CREATE_CLIENT_SUCCESS = "CREATE_CLIENT_SUCCESS";
export const CREATE_CLIENT_FAIL = "CREATE_CLIENT_FAIL";
export const UPDATE_CLIENT_SUCCESS = "UPDATE_CLIENT_SUCCESS";
export const UPDATE_CLIENT_FAIL = "UPDATE_CLIENT_FAIL";
export const GET_USERS_LIST_SUCCESS = "GET_USERS_LIST_SUCCESS";
export const GET_USERS_LIST_FAIL = "GET_USERS_LIST_FAIL";
export const GET_CLIENTS_LIST_SUCCESS = "GET_CLIENTS_LIST_SUCCESS";
export const GET_CLIENTS_LIST_FAIL = "GET_CLIENTS_LIST_FAIL";
export const GET_CLIENTE_SUCCESS = "GET_CLIENTE_SUCCESS";
export const GET_CLIENTE_FAIL = "GET_CLIENTE_FAIL";
export const LOAD_CLIENT_SUCCESS = "LOAD_CLIENT_SUCCESS";
export const LOAD_CLIENT_FAIL = "LOAD_CLIENT_FAIL";

export const LOAD_CLIENT_DEVICE_SUCCESS = "LOAD_CLIENT_DEVICE_SUCCESS";
export const LOAD_CLIENT_DEVICE_FAIL = "LOAD_CLIENT_DEVICE_FAIL";

export const VALID_EMAIL_SUCCESS = "VALID_EMAIL_SUCCESS";
export const VALID_EMAIL_FAIL = "VALID_EMAIL_FAIL";

export const VALID_PHONE_SUCCESS = "VALID_PHONE_SUCCESS";
export const VALID_PHONE_FAIL = "VALID_PHONE_FAIL";
