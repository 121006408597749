import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  Text,
  Button,
  HStack,
  useToast,
} from "@chakra-ui/react";
import { DEFAULT_TOAST_TIME } from "configuration";

import redux from "reducers/utils/Redux";
import React  from 'react';

function EnrollmentModal({
  isOpen,
  onClose,
  changeEnrollment,
  globalEffectUpdate,
}) {
  const toast = useToast();
  const handleConfirm = async () => {
    const { data, status } = await changeEnrollment();
    if (data && status !== 200) {
      toast({
        title: "No se pudo cambiar el tipo de enrolamiento",
        description: "Contacta a soporte para resolver el problema",
        status: "error",
        duration: DEFAULT_TOAST_TIME,
        isClosable: true,
      });
      return;
    } else if (data && data.error_code === 1) {
      toast({
        title: "No se puede cambiar mas de una vez el tipo de enrolamiento",
        description:
          "Si tu teléfono no soporta este tipo de enrolamiento contacta a soporte",
        status: "success",
        duration: 2 * DEFAULT_TOAST_TIME,
        isClosable: true,
      });
      return;
    }
    globalEffectUpdate();
  };
  return (
    <Modal size={"xl"} isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>
          Confirmación de cambio de tipo de enrolamiento
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Text fontSize="lg" color="blue.600">
            ¿Estás seguro de que deseas cambiar el tipo de enrolamiento?
          </Text>
          <Text fontSize="md">
            Considere que solo podrá cambiar el tipo de enrolamiento una vez y
            que no podrá volver a cambiarlo.
          </Text>
          <HStack mt={4} justify="flex-end">
            <Button colorScheme="blue" onClick={handleConfirm} mt={4}>
              Confirmar
            </Button>

            <Button colorScheme="red" onClick={onClose} ml={4} mt={4}>
              Cancelar
            </Button>
          </HStack>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
}

export default redux(EnrollmentModal);
