import Login from "pages/login/LoginPage";
import { Route, Routes } from "react-router";
import RecuperarPassPage from "pages/login/RecuperarPassPage";
import ConfirmPasswordPage from "pages/login/ConfirmPasswordPage";
import ConfirmEmailPage from "pages/login/ConfirmEmailPage";
import Navbar from "components/navigation/Navbar";
import Layout from "components/layouts/Layout";
import redux from "reducers/utils/Redux";
import { useToast } from "@chakra-ui/react";
import { Navigate, useNavigate } from "react-router-dom";
import { useState, useEffect } from "react";
import React from "react";
import { CLIENT_HOME_ROUTE, DEFAULT_TOAST_TIME } from "configuration";

function LoginPage({
  loadUser,
  checkAuthenticated,
  resetPasswordConfirm,
  resetPassword,
}) {
  const toast = useToast();
  const [user, setUser] = useState(null);
  const navigate = useNavigate();

  const onResetPassword = async (dataForm) => {
    const { status, data } = await resetPassword(dataForm.email);
    if (status === 401 && data.error_code === 1) {
      toast({
        position: "top",
        title: "Usuario no encontrado",
        description: "No se encontró un usuario con el correo proporcionado",
        status: "error",
        duration: DEFAULT_TOAST_TIME,
        isClosable: true,
      });
    } else if (status === 422) {
      toast({
        position: "top",
        title: "Correo con formato incorrecto",
        description: "Ingrese un correo válido",
        status: "error",
        duration: DEFAULT_TOAST_TIME,
        isClosable: true,
      });
    } else {
      toast({
        position: "top",
        title: "Revisa tu correo",
        description:
          "Te hemos enviado un correo para que puedas recuperar tu contraseña",
        status: "success",
        duration: DEFAULT_TOAST_TIME,
        isClosable: true,
      });
      navigate("/login");
    }
  };

  useEffect(() => {
    const verifyAuthentication = async () => {
      const { status } = await checkAuthenticated();
      if (status === 200) {
        const { data } = await loadUser();
        checkAuthenticated();
        setUser(data);
      } else {
        setUser(null);
      }
    };
    verifyAuthentication();
  }, [loadUser, checkAuthenticated]);

  if (user !== null) {
    return <Navigate to={CLIENT_HOME_ROUTE} />;
  }

  return (
    <>
      <Layout>
        <Navbar />
        <Routes>
          <Route path={``} element={<Login />}></Route>
          <Route path={`/confirm/:uid/:token`} element={<ConfirmEmailPage />} />
          <Route
            path={`/reset`}
            element={<RecuperarPassPage onResetPassword={onResetPassword} />}
          />
          <Route
            path={`/reset/confirm/:uid/:token`}
            element={
              <ConfirmPasswordPage
                resetPasswordConfirm={resetPasswordConfirm}
              />
            }
          />
        </Routes>
      </Layout>
    </>
  );
}
export default redux(LoginPage);
