function retryWithIntervals(
  validation = () => {},
  intervals = [1, 2, 3],
  onSuccess = () => {},
  onError = () => {}
) {
  let validationResult = validation();
  if (validationResult.then) {
    validationResult.then((result) => {
      if (result) {
        onSuccess();
        return;
      }
      if (intervals.length === 0) {
        onError();
        return;
      }
      setTimeout(() => {
        retryWithIntervals(validation, intervals.slice(1), onSuccess, onError);
      }, intervals[0]);
    });
    return;
  }
  if (validationResult) {
    onSuccess();
    return;
  }
  if (intervals.length === 0) {
    onError();
    return;
  }
  setTimeout(() => {
    retryWithIntervals(validation, intervals.slice(1), onSuccess, onError);
  }, intervals[0]);
}
export default retryWithIntervals;
