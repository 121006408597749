import { Flex, Stack, useToast, Heading, Box } from "@chakra-ui/react";
import redux from "reducers/utils/Redux";
import { DEFAULT_TOAST_TIME } from "configuration";
import { RequestAmountForm } from "components/user/RequestAmountForm";
import { BankAcountConfirmation } from "components/user/BankAcountConfirmation";
import React, { useState, useEffect, useCallback } from "react";
import { LoaderBasic } from "components/LoaderBasic";
import BankDataPage from "./BankDataPage";

function RequestAmount({
  user,
  requestAmount,
  globalEffectUpdate,
  getBankData,
}) {
  const today = new Date();
  const [buttonLoader, setButtonLoader] = useState(false);
  const [loading, setLoading] = useState(false);
  const [bankData, setBankData] = useState({
    number: "",
    bank: "",
    validated: false,
    can_update: false,
    last_update: "",
  });
  const [isBankDataConfirmed, setIsBankDataConfirmed] = useState(false);
  const [showBankAccountData, setShowBankAccountData] = useState(false);

  const toast = useToast();

  const isRenewing = user.loans.length > 0;

  const onSubmit = async (dataForm) => {
    setButtonLoader(true);
    const body = {
      cliente: user,
      fecha: today,
      plazo: 12,
      monto: dataForm.monto,
      dia_pago: today.getDay(),
    };
    let { data, status } = await requestAmount(body);
    if (status === 200) {
      toast({
        title: "Monto solicitado",
        position: "top",
        description: "Tu solicitud ha sido enviada",
        status: "success",
        duration: DEFAULT_TOAST_TIME,
        isClosable: true,
      });
      setButtonLoader(false);
      globalEffectUpdate();
    } else if (data.error_code === 3) {
      toast({
        title: "Cantidad no válida",
        position: "top",
        description: "Ingrese un monto entre 2000 y 3000",
        status: "error",
        duration: DEFAULT_TOAST_TIME,
        isClosable: true,
      });
      setButtonLoader(false);
    } else if (status === 422) {
      toast({
        title: "Monto con formato incorrecto",
        position: "top",
        description: "Ingrese un monto válido",
        status: "error",
        duration: DEFAULT_TOAST_TIME,
        isClosable: true,
      });
      setButtonLoader(false);
    }
  };

  const loadBankData = useCallback(async () => {
    setLoading(true);
    const { status, data } = await getBankData();
    if (status !== 200) {
      toast({
        title: "Error al cargar la información",
        position: "top",
        description: "Inténtalo de nuevo más tarde",
        status: "error",
        duration: DEFAULT_TOAST_TIME,
        isClosable: true,
      });
      setLoading(false);
      setBankData({
        can_update: false,
      });
      return;
    }
    setBankData(data);
    setLoading(false);
  }, [getBankData, toast]);

  useEffect(() => {
    if (isRenewing) {
      loadBankData();
    } else {
      setBankData({
        can_update: false,
      });
      setLoading(false);
    }
  }, [getBankData, loadBankData]);

  // Mostrar loader mientras se cargan los datos bancarios
  if (loading) {
    return <LoaderBasic />;
  }

  // Si el usuario está en renovación, mostrar el componente BankAcountConfirmation
  if (bankData?.can_update && !showBankAccountData && !isBankDataConfirmed) {
    return (
      <Flex p={8} flex={1} align={"center"} justify={"center"}>
        <Box
          borderWidth={1}
          borderRadius="lg"
          p={8}
          maxW={"md"}
          textAlign="center"
          boxShadow="lg"
          bg="gray.50"
        >
          <BankAcountConfirmation
            bankData={bankData}
            onClickYes={() => {
              setShowBankAccountData(false);
              setIsBankDataConfirmed(true);
            }}
            onClickNo={() => {
              setShowBankAccountData(true);
              setIsBankDataConfirmed(true);
            }}
          />
        </Box>
      </Flex>
    );
  }

  if (
    isRenewing &&
    bankData.can_update &&
    showBankAccountData &&
    isBankDataConfirmed
  ) {
    return <BankDataPage globalEffectUpdate={globalEffectUpdate} user={user} />;
  }

  return (
    <Flex p={8} flex={1} align={"center"} justify={"center"}>
      <Stack spacing={4} w={"full"} maxW={"md"}>
        <Heading
          color={"blue.700"}
          w="100%"
          textAlign={"center"}
          fontWeight="normal"
          mb="2%"
        >
          Confirma el monto para tu crédito
        </Heading>
        <RequestAmountForm onSubmit={onSubmit} buttonLoader={buttonLoader} />
      </Stack>
    </Flex>
  );
}

export default redux(RequestAmount);
