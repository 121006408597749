import {
  Container,
  Center,
  Text,
  UnorderedList,
  ListItem,
  Link,
} from "@chakra-ui/react";
import React  from 'react';

export default function AvisoPrivacidad() {
  return (
    <>
      <Center m={5} fontSize={"4xl"} color={"blue.700"} fontWeight="normal">
        AVISO DE PRIVACIDAD
      </Center>
      <Container mb={10} minW={"95vw"}>
        <Text align={"justify"}>
          PROMOTORA BUND, SOCIEDAD ANÓNIMA PROMOTORA DE INVERSIÓN DE CAPITAL
          VARIABLE (en adelante <Text as="b">“BUND”</Text>) valora su confianza
          y protege su privacidad, por lo que de conformidad con los principios
          de licitud, consentimiento, información, lealtad, calidad, finalidad,
          proporcionalidad, y responsabilidad establecidos por la Ley Federal de
          Protección de Datos Personales en Posesión de Particulares (en
          adelante la “Ley”), su Reglamento y los Lineamientos del Aviso de
          Privacidad, ponemos a su disposición el presente Aviso de Privacidad.
          <br /> <br /> En caso de que considere que su derecho a la protección
          de datos personales ha sido vulnerado de alguna manera, le asiste el
          derecho de acudir ante el Instituto Nacional de Transparencia, Acceso
          a la Información y Protección de Datos Personales para solicitar la
          protección de datos personales. Para más información por favor visite
          el sitio web:{" "}
          <Link color="blue" href="http://home.inai.org.mx" isExternal>
            http://home.inai.org.mx
          </Link>
          . <br /> <br />
          <Text as="b">
            ¿QUIÉN ES EL RESPONSABLE DE MIS DATOS PERSONALES?
          </Text>{" "}
          <br />
          <br />
          Para efectos del presente Aviso de Privacidad,{" "}
          <Text as="b">“BUND”</Text> con domicilio en Cerrada Palma de Mayorca
          3A, Bosques de las Palmas, Huixquilucan, Estado de México, CP 52787 es
          el responsable del tratamiento de sus datos personales, entiéndase por
          tratamiento: la obtención, divulgación, almacenamiento, uso, acceso,
          manejo, aprovechamiento, transferencia o disposición de datos
          personales. <br />
          <br />
          <Text as="b">¿CÓMO RECABA BUND MIS DATOS PERSONALES? </Text> <br />
          <br />
          <Text>
            <Text as="b">BUND</Text> solo trata los datos personales que ha
            través del sitio web:{" "}
            <Link href="https://bund.com.mx" isExternal color="blue">
              bund.com.mx
            </Link>
            , a través de mensajes de WhatsApp del teléfono: 55 2341 2346 o a
            través del correo electrónico:
            <Text color="blue">contacto@bund.com.mx.</Text>
          </Text>
          <br />
          <br />
          <Text as="b">BUND</Text> en ningún caso utiliza medios engañosos o
          fraudulentos para recabar los datos personales, por lo que siempre se
          recaban datos de manera lícita y bajo su previo consentimiento, salvo
          las excepciones permitidas por la Ley.
          <br />
          <br /> <Text as="b">¿QUÉ DATOS PERSONALES TRATA BUND?</Text>
          <br />
          <br /> <Text as="b">BUND</Text> sólo recaba datos que son necesarios y
          adecuados para el cumplimiento de sus obligaciones con el titular que
          son:
          <br />
          <br />
          <UnorderedList pl={10}>
            {" "}
            <ListItem> Nombre</ListItem> <ListItem>Domicilio</ListItem>{" "}
            <ListItem> Fecha de Nacimiento </ListItem>{" "}
            <ListItem>Nacionalidad </ListItem>{" "}
            <ListItem> Teléfono móvil </ListItem>{" "}
            <ListItem>Correo electrónico </ListItem> <ListItem> CURP </ListItem>{" "}
            <ListItem> RFC </ListItem>{" "}
            <ListItem>
              {" "}
              Número de seguridad social (NSS) e historial laboral{" "}
            </ListItem>{" "}
            <ListItem>
              Información financiera (número de tarjeta/número de cuenta){" "}
            </ListItem>
            <ListItem>Número de cédula profesional.</ListItem>
            <ListItem> Datos de facturación.</ListItem>
            <ListItem>
              Identificación oficial (pasaporte, credencial para votar,
              licencia)
            </ListItem>
          </UnorderedList>
          <br />
          Toda vez que <Text as="b">BUND</Text> se apega estrictamente a las
          disposiciones de la legislación mexicana para el tratamiento de datos
          personales, aun y cuando su información financiera y patrimonial es
          necesaria para poder cumplir con las obligaciones contraídas con
          usted, como el titular de sus datos personales, en atención a su
          confianza, solicitamos su consentimiento expreso para el tratamiento:
          <br />
          <br />
          <Text as="b">
            <Text as="u">Otorgo mi consentimiento expreso.</Text>
          </Text>{" "}
          <br />
          Usted reconoce que otorga su consentimiento expreso a través de medios
          electrónicos de acuerdo a con el artículo 8 de la Ley. <br />
          <br /> <Text as="b">
            ¿BUND TRATA MIS DATOS PERSONALES SENSIBLES?
          </Text>{" "}
          <br />
          <br />
          <Text as="b">BUND</Text> no trata datos personales considerados
          sensibles, en caso de que fuera necesario el tratamiento de datos
          personales sensibles <Text as="b">BUND</Text> obtendrá previo, al
          tratamiento, su consentimiento.
          <br />
          <br />
          <Text as="b">¿QUÉ SON LAS COOKIES?</Text>
          <br />
          <br />
          Las cookies son archivos de texto que son descargadas automáticamente
          y almacenados en el disco duro del equipo de cómputo del usuario al
          navegar en una página de internet específica que, permiten recordar al
          servidor de internet algunos datos sobre el usuario, ente ellos, sus
          preferencias para la visualización de las páginas en ese servidor,
          nombre y contraseña.
          <br />
          <br />
          <Text as="b">BUND</Text> a través del uso de su sitio web{" "}
          <Link href="https://bund.com.mx" isExternal color="blue">
            bund.com.mx
          </Link>{" "}
          utiliza cookies para recabar su dirección IP, la duración del tiempo
          de interacción en el sitio web y el tipo de navegador utilizado.
          Dichos datos serán tratados únicamente con la finalidad de análisis
          del tráfico en redes, análisis de datos para el mejoramiento del
          sistema y servicio web, para fines de autenticación y prevención
          frente al robo de identidad o vulneración de la seguridad de los
          datos, así como para autenticar o identificar usuarios o terceros que
          utilicen nuestro sitio web. No se realizará transferencia alguna de
          estos datos.
          <br />
          <br />
          En caso de que no esté de acuerdo con el uso de esta tecnología por
          favor deshabilite las cookies desde su navegador o absténgase de usar
          el sitio web referido.
          <br />
          <br />{" "}
          <Text as="b">
            ¿CON QUÉ FINALIDADES BUND TRATA MIS DATOS PERSONALES?{" "}
          </Text>
          <br />
          <br />
          <Text as="b">BUND</Text> trata los datos personales con las siguientes
          finalidades necesarias:
          <br />
          <br />
          <UnorderedList pl={10}>
            <ListItem>
              Crear una relación jurídica entre <Text as="b">BUND</Text> y el
              titular, y en consecuencia, prestar los servicios contratados por
              este.{" "}
            </ListItem>
            <ListItem>
              Crear una base de datos ordenada con la información proporcionada
            </ListItem>{" "}
            <ListItem> Realizar análisis de datos,</ListItem>
            <ListItem>
              {" "}
              Realizar encuestas sobre la calidad de los servicios prestados,{" "}
            </ListItem>
            <ListItem>
              {" "}
              Atender solicitudes y comentarios del titular,{" "}
            </ListItem>
            <ListItem>
              Cumplir con obligaciones administrativas, comerciales y fiscales{" "}
            </ListItem>
            <ListItem>
              Informar sobre actividades y nuevos productos y servicios, así
              como sus mejoras;{" "}
            </ListItem>
            <ListItem>
              Atender requerimientos legales de autoridades competentes.{" "}
            </ListItem>
            <ListItem>
              Realizar consultas de bases de datos de acceso público con
              terceros prestadores de servicios a fin de validar su identidad y
              la viabilidad del crédito a ser otorgado.
            </ListItem>
            <ListItem>
              [Realizar consultas de su historial crediticio en el buró de
              crédito].
            </ListItem>
          </UnorderedList>
          <br />
          Asimismo, <Text as="b">BUND</Text> podrá tratar sus datos personales,
          en atención a las siguientes finalidades no necesarias: i) Hacer de su
          conocimiento, la gama de nuevos productos y servicios, así como
          beneficios, descuentos, promociones, estudios de mercado,
          promociones.; y ii) Publicidad derivada de los productos y servicios
          que ofrece <Text as="b">BUND</Text>.
          <br />
          <br />
          <Text as="b">BUND</Text> tratará sus datos personales únicamente por
          el tiempo que sea estrictamente necesario con el objetivo de cumplir
          con las finalidades descritas en el presente aviso de privacidad y de
          conformidad con lo que establezcan las disposiciones legales
          aplicables.
          <br />
          <br /> Los datos personales que obtenga <Text as="b">BUND</Text> serán
          manejados en documentos físicos o electrónicos, contando con medidas
          de seguridad técnicas, administrativas y físicas para su protección,
          tratándolos con responsabilidad y de manera confidencial, quedando
          prohibida su divulgación salvo por lo aquí señalado y obedeciendo la
          Ley, así como para cumplir con las necesidades administrativas en su
          beneficio.
          <br />
          <br />
          Para oponerse al tratamiento de sus datos personales con relación a
          alguna de las finalidades no necesarias descritas anteriormente por
          favor consulte las secciones ¿Cómo puedo limitar el tratamiento de mis
          datos personales? y ¿Cómo puedo ejercer los derechos ARCO? del
          presente aviso de privacidad. En caso de que no se oponga, será un
          signo inequívoco de su conformidad para el tratamiento de sus datos
          personales conforme a todas las finalidades descritas.
          <br />
          <br />
          <Text as="b">¿BUND TRANSFIERE MIS DATOS PERSONALES?</Text>
          <br />
          <br />
          <Text as="b">BUND</Text> transferirá sus datos personales sin antes
          obtener su consentimiento expreso. <Text as="b">BUND</Text> únicamente
          podrá realizar la transferencia de datos personales a:
          <br />
          <br />
          <UnorderedList pl={10}>
            <ListItem>
              Las empresas afiliadas y subsidiarias de <Text as="b">BUND</Text>{" "}
              a nivel nacional o internacional.{" "}
            </ListItem>
            <ListItem>
              {" "}
              Terceros proveedores de servicios de investigación, análisis de
              datos, envío de información enfocada a las necesidades del titular
              de datos personales, así como en la realización de otros servicios
              necesarios o requeridos por el titular.
            </ListItem>
            <ListItem>
              {" "}
              Socios comerciales con los que <Text as="b">BUND</Text> o
              cualquiera de las empresas de <Text as="b">BUND</Text>, celebre
              cualquier tipo de contratos, incluyendo convenios o contratos
              enfocados al desarrollo de nuevos productos y/o servicios
              enfocados al mejoramiento y de acuerdo con las necesidades del
              titular.
            </ListItem>
          </UnorderedList>
          <br />
          Asimismo, <Text as="b">BUND</Text> podrá transferir sus datos
          personales sin su consentimiento, en aquellos casos señalados en el
          artículo 37 de la Ley.
          <br />
          <br />
          <Text as="b">BUND</Text> se asegurará a través de la firma de
          contratos, convenios y cláusulas contractuales, que dichos terceros
          mantengan medidas de seguridad, administrativas, técnicas y físicas
          adecuadas para resguardar sus datos personales, así como que dichos
          terceros únicamente utilicen sus datos personales para las finalidades
          para los cuales fueron recabados de conformidad con el presente Aviso
          de Privacidad.
          <br />
          <br />
          Mediante la firma del Contrato de Adhesión de Crédito simple, usted
          otorga su consentimiento expreso y por escrito para que sus datos sean
          transferidos a los terceros anteriormente señalados.
          <br />
          <br />
          <Text as="b">¿CÓMO PROTEGE MIS DATOS PERSONALES BUND?</Text>
          <br />
          <br />
          <Text as="b">BUND</Text> protege sus datos personales manteniéndolos
          en estricta confidencialidad de conformidad con las medidas de
          seguridad, administrativas, técnicas y físicas que{" "}
          <Text as="b">BUND</Text> ha implementado en su domicilio de manera
          física y electrónica.
          <br />
          <br />
          Asimismo, <Text as="b">BUND</Text> ha implementado políticas y
          procedimientos de seguridad con su personal en los que se cuenta con
          estrictos estándares de confidencialidad.
          <br />
          <br />
          <Text as="b">¿QUÉ SON LOS DERECHOS ARCO? </Text>
          <br />
          <br />
          Los derechos ARCO son los derechos que tiene todo titular de datos
          personales para Acceder, Rectificar, Cancelar u Oponerse al
          tratamiento de sus datos personales.
          <br />
          <br />
          <Text as="b">Acceso:</Text> Revisar a detalle todos sus datos
          personales.
          <br />
          <br />
          <Text as="b">Rectificación:</Text> Modificar datos personales, si
          están incorrectos o incompletos, o si se presentó algún cambio.
          <br />
          <br />
          <Text as="b">Cancelación:</Text> Eliminar los datos personales, por no
          estar de acuerdo en su tratamiento.
          <br />
          <br />
          <Text as="b">Oposición:</Text> Oponerse a la divulgación o
          transferencia de datos personales.
          <br />
          <br />
          Asimismo, usted podrá limitar el uso o divulgación de sus datos
          personales, siguiendo el procedimiento que se señala en la siguiente
          sección.
          <br />
          <br />
          <Text as="b">¿CÓMO PUEDO EJERCER MIS DERECHOS ARCO?</Text>
          <br />
          <br />
          Usted podrá ejercer sus derechos ARCO o solicitar la limitación del
          uso o divulgación de sus datos personales, de la siguiente forma:
          <br />
          <br />
          El titular de datos personales deberá presentar una solicitud por
          escrito que contenga la siguiente información:
          <UnorderedList pl={10}>
            <ListItem>
              Nombre completo del titular de los datos personales, domicilio y
              cuenta de correo electrónico.{" "}
            </ListItem>
            <ListItem>
              Los documentos que acrediten la identidad y en su caso, la
              representación legal del titular de los datos personales.{" "}
            </ListItem>
            <ListItem>
              La descripción clara y precisa respecto del derecho que ejerce ya
              sea limitar o revocar su consentimiento.
            </ListItem>
            <ListItem>
              La descripción clara y precisa de los datos personales respecto de
              los que se busca limitar o revocar el consentimiento.
            </ListItem>
            <ListItem>
              Cualquier otro elemento o documento que facilite la localización
              de los datos personales.
            </ListItem>
            <ListItem>
              Entregada en el domicilio de BUND vía correo tradicional o por
              correo electrónico a:{" "}
              <Text color="blue">contacto@bund.com.mx.</Text>
            </ListItem>
          </UnorderedList>
          <br />
          <br />
          <Text as="b">CAMBIOS AL AVISO DE PRIVACIDAD</Text>
          <br />
          <br />
          Cualquier cambio al presente aviso de privacidad, será informado a
          través de los siguientes medios: Avisos en el domicilio físico de
          <Text as="b">BUND</Text>; Mediante correo electrónico proporcionado
          por el titular de datos personales; Avisos en el portal de Internet de{" "}
          <Text as="b">BUND</Text>.
          <br />
          <br />
          <Text as="b">CONSENTIMIENTO</Text>
          Mediante la firma del Contrato de Adhesión de Crédito simple, usted
          otorga su consentimiento expreso y por escrito para que sus datos
          personales y datos personales financieros sean tratados de conformidad
          con este Aviso de Privacidad.
          <br />
          <br />
          El no otorgar su consentimiento, será un impedimento para formalizar
          una relación jurídica con BUND.
          <br />
          <br />
          <Text as="b">FECHA DE ÚLTIMA ACTUALIZACIÓN:</Text> 31 de octubre de
          2023.
        </Text>
      </Container>
    </>
  );
}
