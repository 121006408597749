import { useForm } from "react-hook-form";
import {
  Button,
  Flex,
  FormControl,
  FormLabel,
  Heading,
  Input,
  Link,
  Stack,
  Image,
  InputRightElement,
  InputGroup,
  Center,
  useToast,
} from "@chakra-ui/react";
import loginImg from "assets/img/login.webp";
import { useNavigate } from "react-router-dom";
import {
  CLIENT_HOME_ROUTE,
  DEFAULT_EMAIL_PLACEHOLDER,
  DEFAULT_PASSWORD_PLACEHOLDER,
  DEFAULT_TOAST_TIME,
} from "configuration";
import React from "react";

import { useState } from "react";
import redux from "reducers/utils/Redux";
import { analytcEvent } from "analytics";

function LoginPage({ login }) {
  const { register, handleSubmit } = useForm();
  const [show, setShow] = useState(false);
  const [loading, setLoading] = useState(false);

  const navigate = useNavigate();
  const toast = useToast();

  const onClick = () => {
    setShow(!show);
  };

  const onSubmit = async (data) => {
    setLoading(true);
    analytcEvent("auth", "login");
    const { email, password } = data;
    const res = await login(email, password);

    if (res.status === 200) {
      toast({
        position: "top",
        title: "Inicio de sesión exitoso",
        description: "Bienvenido",
        status: "success",
        duration: DEFAULT_TOAST_TIME,
        isClosable: true,
      });
      navigate(CLIENT_HOME_ROUTE, { replace: true });
    } else if (res.data.error_code === 1 || res.data.error_code === 2) {
      toast({
        position: "top",
        title: "El nombre de usuario o la contraseña no son correctos",
        description:
          "En caso de que ya estés registrado, intenta recuperar contraseña",
        status: "error",
        duration: DEFAULT_TOAST_TIME,
        isClosable: true,
      });
    } else {
      toast({
        position: "top",
        title: "Estamos experimentando intermitencia",
        description: "Inténtalo de nuevo mas tarde",
        status: "error",
        duration: DEFAULT_TOAST_TIME,
        isClosable: true,
      });
    }
    setLoading(false);
  };

  return (
    <>
      <Stack minH={"100vh"} direction={{ base: "column", md: "row" }}>
        <Flex p={8} flex={1} align={"center"} justify={"center"}>
          <Stack spacing={4} w={"full"} maxW={"md"}>
            <Heading fontSize={"2xl"}>Inicia sesión en tu cuenta</Heading>
            <form onSubmit={handleSubmit(onSubmit)}>
              <FormControl id="email">
                <FormLabel>Correo electrónico</FormLabel>
                <Input
                  {...register("email")}
                  placeholder={DEFAULT_EMAIL_PLACEHOLDER}
                  required
                  type="email"
                />
              </FormControl>
              <FormControl id="password">
                <FormLabel>Contraseña</FormLabel>
                <InputGroup size="md">
                  <Input
                    {...register("password")}
                    placeholder={DEFAULT_PASSWORD_PLACEHOLDER}
                    required
                    type={show ? "text" : "password"}
                  />
                  <InputRightElement width="5rem">
                    <Button h="1.75rem" size="sm" onClick={onClick}>
                      {show ? "Ocultar" : "Mostrar"}
                    </Button>
                  </InputRightElement>
                </InputGroup>
              </FormControl>
              <Stack spacing={6}>
                <Link href="/login/reset" color={"blue.500"}>
                  ¿Olvidaste tu contraseña?
                </Link>

                <Button
                  type="submit"
                  colorScheme={"blue"}
                  variant={"solid"}
                  isDisabled={loading}
                >
                  Iniciar Sesión
                </Button>

                {loading || (
                  <Center>
                    <Link href="/signup" color={"blue.500"}>
                      Crear Cuenta
                    </Link>
                  </Center>
                )}
              </Stack>
            </form>
          </Stack>
        </Flex>
        <Flex flex={1}>
          <Image alt={"Login Image"} objectFit={"cover"} src={loginImg} />
        </Flex>
      </Stack>
    </>
  );
}

export default redux(LoginPage);
