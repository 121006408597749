import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  Stack,
  Image,
  Flex,
} from "@chakra-ui/react";
import ineEFGH from "assets/img/ineEFGH.webp";
import ineD from "assets/img/ineD.webp";
import React  from 'react';

function IneHelperModal({ modeloINE, isOpen, onClose }) {
  return (
    <Modal size={"xl"} isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>CIC e identificador del ciudadano</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          {modeloINE === 2 && (
            <Stack align={"center"}>
              <Flex>
                <Image alt="ine" src={ineEFGH} maxH="250px" />
              </Flex>
            </Stack>
          )}
          {modeloINE === 1 && (
            <Stack align={"center"}>
              <Flex>
                <Image alt="ine" src={ineD} maxH="200px" />
              </Flex>
            </Stack>
          )}
        </ModalBody>
      </ModalContent>
    </Modal>
  );
}

export default IneHelperModal;
