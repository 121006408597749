import {
  Container,
  Accordion,
  AccordionItem,
  AccordionButton,
  Box,
  AccordionIcon,
  AccordionPanel,
  Center,
} from "@chakra-ui/react";
import { connect } from "react-redux";
import React from "react";

function PreguntasFrecuentes() {
  const faqs = {
    "pregunta_1": {
      "title": "¿Qué son los préstamos personales Bund?",
      "description": "Es el servicio con el cual puedes pedir un préstamo de hasta $3,000 M.N. pesos en tu primera solicitud, es fácil, rápido y seguro. El proceso es 100% en línea sin necesidad de aval ni largos trámites. De esta manera, eliminamos los altos márgenes de los intermediarios financieros tradicionales, lo que nos permite ofrecer tasas más atractivas."
    },
    "pregunta_2": {
      "title": "¿Cuánto puedo solicitar con Bund y a qué plazo?",
      "description": "En el primer préstamo, puedes solicitar hasta $3,000 M.N. Una vez seleccionado el monto, puedes iniciar tu solicitud de préstamo de dinero. Podrías obtener un límite de crédito más alto pagando en tiempo y forma y según otros factores relacionados con tu historial crediticio. El plazo estándar que manejamos es a 12 semanas con pago semanal."
    },
    "pregunta_4": {
      "title": "¿Cuáles son los requisitos de contratación de un préstamo personal Bund?",
      "description": "Contar con credencial INE vigente; ser persona física de nacionalidad mexicana; tener RFC con homoclave registrado en el SAT; cuenta bancaria en banco mexicano; y domicilio en territorio nacional"
    },
    "pregunta_5": {
      "title": "Una vez que aplico, ¿cuánto tiempo se tarda Bund para tomar una decisión?",
      "description": "Una vez que nos envías tu información y documentación, tardamos minutos en darte una respuesta preliminar. Una vez que se firma el contrato, en un lapso estimado de dos horas* recibirás el depósito en tu cuenta. *Considera que estos tiempos pueden variar por contratiempos en la conexión con el servicio."
    },
    "pregunta_6": {
      "title": "¿Cómo puedo hacer mi pago?",
      "description": "Puedes consultar la sección de Formas de Pago."
    },
    "pregunta_7": {
      "title": "¿Dónde obtengo mi referencia de pago?",
      "description": "Una vez que inicies sesión, en tu perfil podrás obtener tu referencia de pago y saldo a pagar. Asimismo, esa referencia se encuentra en el contrato y en los anexos del propio contrato. Semana a semana se te hará un recordatorio de pago y ahí también se enunciará la referencia."
    },
    "pregunta_8": {
      "title": "¿Qué hago si me equivoqué de referencia?",
      "description": "Recuerda que para que tu pago se aplique correctamente, es necesario que la referencia se incluya de forma correcta. En caso de que hayas cometido un error, verifica que el pago no se haya devuelto; en caso de no ser así por favor envíanos tu comprobante al correo contacto@bund.com.mx o por WhatsApp al 5523412346"
    },
    "pregunta_9": {
      "title": "¿Qué hago si no se ve reflejado mi pago en mi perfil de Bund?",
      "description": "Recuerda que tu pago puede tardar hasta 24 horas en verse reflejado, ¡pero no te preocupes! Será registrado con la fecha en que lo hiciste. Si pasa este tiempo y aún no ves tu pago domiciliado o manual reflejado, por favor envíanos tu comprobante al correo contacto@bund.com.mx Te sugerimos pagar antes de las 5:00PM, ya que esto podría ayudar a que se refleje antes."
    },
    "pregunta_11": {
      "title": "¿Qué pasa si no pago a tiempo?",
      "description": "En caso de impago, nosotros reportamos la situación a buró, por lo que te sugerimos pagar a tiempo siempre!"
    },
    "pregunta_12": {
      "title": "¿Piden dinero por adelantado?",
      "description": "No, de ninguna manera. Nosotros nunca te pediremos dinero por adelantado, el trámite de tu crédito es totalmente gratuito, no te dejes engañar. Asimismo, por tu seguridad te solicitamos que utilices únicamente nuestros medios de contacto oficiales y que jamás compartas en redes sociales datos de contacto como tu email o número telefónico."
    },
    "pregunta_13": {
      "title": "¿Puedo obtener un préstamo si tengo mal historial crediticio?",
      "description": "No. En Bund sólo autorizamos solicitudes de personas con buen historial. Podemos otorgarle créditos únicamente a personas que puedan demostrar que han tenido un comportamiento financiero aceptable y confiable."
    },
    "pregunta_14": {
      "title": "¿Puedo hacer liquidaciones o pagos anticipados sin penalización?",
      "description": "Así es, puedes adelantar pagos o liquidar de manera anticipada sin penalización alguna."
    },
    "pregunta_15": {
      "title": "¿Son una empresa confiable?",
      "description": "Somos una empresa legalmente constituida, con muchos usuarios registrados y millones de pesos en préstamos personales otorgados, lo que nos posiciona como una excelente plataforma de préstamos."
    }
  };

  return (
    <Container py={5} minW={"95vw"}>
      <Center color={"blue.700"} fontSize={"3xl"} fontWeight={"bold"}>
        Preguntas Frecuentes
      </Center>
      <Accordion p={5} defaultIndex={[0]} allowMultiple>
        {Object.keys(faqs).map((key, index) => {
          const faq = faqs[key];
          return (
            <AccordionItem key={index}>
              <h2>
                <AccordionButton>
                  <Box color={"blue.600"} as="span" flex="1" textAlign="left">
                    {faq.title}
                  </Box>
                  <AccordionIcon />
                </AccordionButton>
              </h2>
              <AccordionPanel pb={4}>{faq.description}</AccordionPanel>
            </AccordionItem>
          );
        })}
      </Accordion>
    </Container>
  );
}

const mapStateToProp = () => ({});

export default connect(mapStateToProp, {})(PreguntasFrecuentes);
