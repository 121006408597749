import Rechazado from "components/signup/Rechazado";
import React  from 'react';

function RejectedPage() {
  return (
    <>
      <Rechazado />
    </>
  );
}

export default RejectedPage;
