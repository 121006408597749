import { Box, useToast } from "@chakra-ui/react";
import InfoPersonal from "./InfoPersonal";
import { Captcha } from "components/Captcha";
import { DEFAULT_TOAST_TIME, CLIENT_HOME_ROUTE } from "configuration";
import { useState } from "react";
import React from "react";

function Signup({ login, saveUserInfo, navigate }) {
  const toast = useToast();
  const [dataForm, setDataForm] = useState(null);
  const [captchaOpen, setCaptchaOpen] = useState(false);
  const [loading, setLoading] = useState(false);

  const onClose = async (captcha) => {
    setLoading(true);
    const { data, status } = await saveUserInfo({
      ...dataForm,
      token: captcha,
    });
    if (status === 201) {
      toast({
        title: "Usuario creado",
        description: "Usuario creado correctamente",
        status: "success",
        duration: DEFAULT_TOAST_TIME,
        isClosable: true,
      });
      await login(dataForm.email, dataForm.password);
      navigate(CLIENT_HOME_ROUTE);
      return;
    }
    setCaptchaOpen(false);
    setLoading(false);
    if (data.error_code === 1) {
      toast({
        title: "El correo o el teléfono ya existe",
        description:
          "Inicie sesión en la cuenta existente o use otro correo o teléfono",
        status: "error",
        duration: DEFAULT_TOAST_TIME,
        isClosable: true,
      });
      return;
    }
    if (data.error_code === 2) {
      toast({
        title: "Error",
        description: "El captcha no es válido",
        status: "error",
        duration: DEFAULT_TOAST_TIME,
        isClosable: true,
      });
      return;
    }
    if (data.error_code === 3) {
      const field = data.message.split(" ")[1];
      toast({
        title: `El campo ${field} no es válido`,
        description: "Ingrese un valor válido en el campo correspondiente",
        status: "error",
        duration: DEFAULT_TOAST_TIME,
        isClosable: true,
      });
      return;
    }
    if (status === 422) {
      toast({
        title: "Email con formato incorrecto",
        description: "Ingrese un correo válido",
        status: "error",
        duration: DEFAULT_TOAST_TIME,
        isClosable: true,
      });
      return;
    }
  };

  const handlerClose = (captcha) => {
    onClose(captcha);
  };

  return (
    <>
      <Box
        borderWidth="1px"
        rounded="lg"
        shadow="1px 1px 3px rgba(0,0,0,0.3)"
        maxWidth={800}
        p={6}
        m="10px auto"
      >
        <InfoPersonal
          toast={toast}
          setDataForm={setDataForm}
          setCaptchaOpen={setCaptchaOpen}
        />
      </Box>
      <Captcha onClose={handlerClose} isOpen={captchaOpen} loading={loading} />
    </>
  );
}

export default Signup;
