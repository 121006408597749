import Aviso from "components/inicio/Aviso";
import Contacto from "components/inicio/Contacto";
import Cotizador from "components/inicio/Cotizador";
import Pasos from "components/inicio/Pasos";
import Requisitos from "components/inicio/Requisitos";
import Testimonios from "components/inicio/Testimonios";
import React from "react";

function Home() {
  return (
    <>
      <Cotizador />
      <Pasos />
      <Requisitos />
      {/* <Aviso /> */}
      <Testimonios />
      <Contacto />
    </>
  );
}

export default Home;
