import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  Stack,
  Flex,
  Text,
  Button,
} from "@chakra-ui/react";
import { useState } from "react";
import React from "react";

function RenewCreditModal({ isOpen, onClose, onCloseWhithoutRenew }) {
  const [buttonClicked, setButtonClicked] = useState("");
  const [disabled, setDisabled] = useState(false);
  const close = () => {
    if (buttonClicked === "rechazar") {
      onCloseWhithoutRenew();
    }
    if (buttonClicked === "aceptar") {
      onClose();
    }
    setDisabled(false);
  };

  return (
    <Modal size={"xl"} isOpen={isOpen} onClose={close}>
      <ModalOverlay />
      <ModalContent>
        <ModalBody>
          <Stack align={"center"} justifyContent="center">
            <ModalHeader textAlign="center">
              Has finalizado de pagar tu crédito
            </ModalHeader>
            <Text textAlign="center">
              Tu próximo crédito está preaprobado por hasta 3,000
            </Text>
            <Text>¿Quieres renovar ahora mismo?</Text>

            <Flex
              gap="5"
              justifyContent="space-between"
              alignItems="center"
              mt="2"
              mb="5"
            >
              <Button
                colorScheme="blue"
                disabled={disabled}
                onClick={() => {
                  setDisabled(true);
                  setButtonClicked("aceptar");
                  close();
                }}
                size="md"
              >
                Aceptar
              </Button>
              <Button
                colorScheme="red"
                disabled={disabled}
                onClick={() => {
                  setDisabled(true);
                  setButtonClicked("rechazar");
                  close();
                }}
                size="md"
              >
                Rechazar
              </Button>
            </Flex>
          </Stack>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
}

export default RenewCreditModal;
