export const LOGIN_SUCCESS = 'LOGIN_SUCCESS'
export const LOGIN_FAIL = 'LOGIN_FAIL'
export const RESET_PASSWORD_SUCCESS = 'RESET_PASSWORD_SUCCESS'
export const RESET_PASSWORD_FAIL = 'RESET_PASSWORD_FAI'
export const RESET_PASSWORD_CONFIRM_SUCCESS = 'RESET_PASSWORD_CONFIRM_SUCCESS'
export const RESET_PASSWORD_CONFIRM_FAIL = 'RESET_PASSWORD_CONFIRM_FAIL'
export const SET_AUTH_LOADING = 'SET_AUTH_LOADING'
export const REMOVE_AUTH_LOADING = 'REMOVE_AUTH_LOADING'
export const LOGOUT = 'LOGOUT'
export const AUTHENTICATED_FAIL = 'AUTHENTICATED_FAIL'
export const AUTHENTICATED_SUCCESS = 'AUTHENTICATED_SUCCESS'
export const REFRESH_SUCCESS = 'REFRESH_SUCCESS'
export const REFRESH_FAIL = 'REFRESH_FAIL'
export const USER_LOADED_SUCCES = 'USER_LOADED_SUCCES'
export const USER_LOADED_FAIL = 'USER_LOADED_FAIL'