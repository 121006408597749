import { Button, Grid } from "@chakra-ui/react";
import React  from 'react';

export const ButtonValidations = ({ onClickYes, onClickNo }) => {
  return (
    <>
      <Grid
        mt={"2%"}
        mx={"5%"}
        templateColumns={{
          base: "repeat(2, 1fr)",
        }}
        gap={6}
      >
        {/* Button */}
        <Button
          colorScheme="blue"
          size="md"
          rounded="md"
          w="100%"
          mt="2%"
          mb="2%"
          onClick={onClickYes}
        >
          Sí
        </Button>

        <Button
          colorScheme="red"
          size="md"
          rounded="md"
          w="100%"
          mt="2%"
          mb="2%"
          onClick={onClickNo}
        >
          No
        </Button>
      </Grid>
    </>
  );
};
