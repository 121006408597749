import {
  Heading,
  FormControl,
  FormLabel,
  Input,
  Button,
  Grid,
  useToast,
  Select,
} from "@chakra-ui/react";
import { useForm, useWatch } from "react-hook-form";
import { useState, useEffect } from "react";
import React from "react";

// Mapeo de entidades federativas basado en el CURP
const entidadMap = {
  AS: "Aguascalientes",
  BC: "Baja California",
  BS: "Baja California Sur",
  CC: "Campeche",
  CL: "Coahuila",
  CM: "Colima",
  CS: "Chiapas",
  CH: "Chihuahua",
  DF: "Ciudad de México",
  DG: "Durango",
  GT: "Guanajuato",
  GR: "Guerrero",
  HG: "Hidalgo",
  JC: "Jalisco",
  MC: "Estado de México",
  MN: "Michoacán",
  MS: "Morelos",
  NT: "Nayarit",
  NL: "Nuevo León",
  OC: "Oaxaca",
  PL: "Puebla",
  QT: "Querétaro",
  QR: "Quintana Roo",
  SP: "San Luis Potosí",
  SL: "Sinaloa",
  SR: "Sonora",
  TC: "Tabasco",
  TS: "Tamaulipas",
  TL: "Tlaxcala",
  VZ: "Veracruz",
  YN: "Yucatán",
  ZS: "Zacatecas",
};

// Función para parsear el CURP
const parseCurp = (curp) => {
  const birthDate = curp.slice(4, 10); // AAMMDD
  const year = birthDate.slice(0, 2);
  const month = birthDate.slice(2, 4);
  const day = birthDate.slice(4, 6);

  // Suponemos que si el año es menor que 30 es del 2000+, de lo contrario es del 1900+
  const fullYear = parseInt(year) >= 30 ? `19${year}` : `20${year}`;

  const formattedBirthDate = `${fullYear}-${month}-${day}`;

  const sexo = curp.charAt(10); // H para hombre, M para mujer

  const entidadFederativa = curp.slice(11, 13); // Entidad federativa

  return {
    birthDate: formattedBirthDate,
    sexo: sexo === "H" ? "Hombre" : "Mujer",
    entidad: entidadMap[entidadFederativa] || "",
  };
};

// Función para validar el RFC
const isValidRfc = (rfc) => {
  const rfcRegex = /^[A-Z]{4}[0-9]{6}[A-Z0-9]{3}$/; // RFC con homoclave, simplificado
  return rfcRegex.test(rfc);
};

export const RfcAndCurp = ({ user, onSubmit }) => {
  // Cargar datos del localStorage si existen
  const loadInitialState = () => {
    const storedFormData = localStorage.getItem("formData");
    return storedFormData
      ? JSON.parse(storedFormData)
      : {
          name: user?.name || "",
          secondName: user?.second_name || "",
          firstSurname: user?.first_surname || "",
          secondSurname: user?.second_surname || "",
          curp: "",
          rfc: "",
          ocupacion: user?.ocupacion || "",
          birthDate: "",
          sexo: "",
          entidad: "",
        };
  };

  const [formData, setFormData] = useState(loadInitialState());

  const { register, handleSubmit, control } = useForm();
  const toast = useToast();

  // Guardar datos en localStorage cuando formData cambie
  useEffect(() => {
    localStorage.setItem("formData", JSON.stringify(formData));
  }, [formData]);

  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      event.preventDefault();
    }
  };

  const handleChange = (e) => {
    const { id, value } = e.target;

    const curpRegex = /^[A-Z]{4}[0-9]{6}[A-Z]{6}[A-Z0-9]{2}$/;

    if (id === "curp" && value.length === 18) {
      const { birthDate, sexo, entidad } = parseCurp(value);

      setFormData((prevData) => ({
        ...prevData,
        curp: value.toUpperCase(),
        birthDate,
        sexo,
        entidad,
        rfc: prevData.rfc, // Mantenemos el RFC actual hasta que el usuario lo modifique
      }));
      if (!curpRegex.test(value)) {
        toast({
          title: "CURP inválida",
          status: "error",
          duration: 5000,
          isClosable: true,
        });
        return;
      }
    } else {
      setFormData((prevData) => ({
        ...prevData,
        [id]: value.toUpperCase(),
      }));
    }
  };

  const handleRfcChange = (e) => {
    const { value } = e.target;
    const formattedValue = value.toUpperCase();

    setFormData((prevData) => ({
      ...prevData,
      rfc: formattedValue,
    }));

    if (!isValidRfc(formattedValue) && formattedValue.length === 13) {
      toast({
        title: "RFC inválido",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    }
  };

  const handleOcupacionChange = (e) => {
    const { value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      ocupacion: value,
    }));
  };

  const submitForm = () => {
    onSubmit(formData);
  };

  return (
    <form onSubmit={handleSubmit(submitForm)}>
      <Heading color="blue.700" textAlign="center" fontWeight="normal" mb="2%">
        Por favor llena los campos solicitados
      </Heading>
      <Grid
        mt="2%"
        templateColumns={{ base: "repeat(1, 1fr)", md: "repeat(2, 1fr)" }}
        gap={6}
      >
        <FormControl>
          <FormLabel htmlFor="name" fontSize="small" fontWeight="semibold">
            Nombre
          </FormLabel>
          <Input
            id="name"
            value={formData.name}
            placeholder="Nombre"
            size="sm"
            onChange={handleChange}
            onKeyPress={handleKeyPress}
          />
        </FormControl>

        <FormControl>
          <FormLabel
            htmlFor="secondName"
            fontSize="small"
            fontWeight="semibold"
          >
            Segundo Nombre
          </FormLabel>
          <Input
            id="secondName"
            value={formData.secondName}
            placeholder="Segundo Nombre"
            size="sm"
            onChange={handleChange}
            onKeyPress={handleKeyPress}
          />
        </FormControl>

        <FormControl>
          <FormLabel
            htmlFor="firstSurname"
            fontSize="small"
            fontWeight="semibold"
          >
            Primer Apellido
          </FormLabel>
          <Input
            id="firstSurname"
            value={formData.firstSurname}
            placeholder="Primer Apellido"
            size="sm"
            onChange={handleChange}
            onKeyPress={handleKeyPress}
          />
        </FormControl>

        <FormControl>
          <FormLabel
            htmlFor="secondSurname"
            fontSize="small"
            fontWeight="semibold"
          >
            Segundo Apellido
          </FormLabel>
          <Input
            id="secondSurname"
            value={formData.secondSurname}
            placeholder="Segundo Apellido"
            size="sm"
            onChange={handleChange}
            onKeyPress={handleKeyPress}
          />
        </FormControl>

        <FormControl>
          <FormLabel htmlFor="curp" fontSize="small" fontWeight="semibold">
            CURP
          </FormLabel>
          <Input
            id="curp"
            value={formData.curp}
            placeholder="CURP"
            size="sm"
            required
            onChange={handleChange}
            onKeyPress={handleKeyPress}
            maxLength={18}
          />
        </FormControl>

        <FormControl>
          <FormLabel htmlFor="rfc" fontSize="small" fontWeight="semibold">
            RFC con Homoclave
          </FormLabel>
          <Input
            id="rfc"
            value={formData.rfc}
            placeholder="RFC"
            size="sm"
            onChange={handleRfcChange}
            onKeyPress={handleKeyPress}
            maxLength={13}
          />
        </FormControl>

        <FormControl>
          <FormLabel htmlFor="birthDate" fontSize="small" fontWeight="semibold">
            Fecha de Nacimiento
          </FormLabel>
          <Input
            id="birthDate"
            value={formData.birthDate}
            placeholder="Fecha de nacimiento"
            size="sm"
            isDisabled
            onChange={handleChange}
          />
        </FormControl>

        <FormControl>
          <FormLabel htmlFor="sexo" fontSize="small" fontWeight="semibold">
            Sexo
          </FormLabel>
          <Input
            id="sexo"
            value={formData.sexo}
            placeholder="Sexo"
            size="sm"
            isDisabled
            onChange={handleChange}
          />
        </FormControl>

        <FormControl>
          <FormLabel htmlFor="entidad" fontSize="small" fontWeight="semibold">
            Entidad Federativa de Nacimiento
          </FormLabel>
          <Input
            id="entidad"
            value={formData.entidad}
            placeholder="Entidad"
            size="sm"
            isDisabled
            onChange={handleChange}
          />
        </FormControl>

        <FormControl>
          <FormLabel htmlFor="ocupacion" fontSize="small" fontWeight="semibold">
            Ocupación
          </FormLabel>
          <Select
            id="ocupacion"
            value={formData.ocupacion}
            placeholder="Selecciona tu ocupación"
            size="sm"
            onChange={handleOcupacionChange}
          >
            <option value={"Ama de casa"}>Ama de casa</option>
            <option value={"Desempleado"}>Desempleado</option> {/* Rechazó*/}
            <option value={"Dueño de negocio"}>Dueño de negocio</option>
            <option value={"Empleado privado"}>Empleado privado</option>
            <option value={"Estudiante"}>Estudiante</option>
            <option value={"Jubilado"}>Jubilado</option>
            <option value={"Servidor público"}>Servidor público</option>
          </Select>
        </FormControl>
      </Grid>

      <Button
        mt={2}
        type="submit"
        bg="blue.500"
        color="white"
        isDisabled={formData.curp.length !== 18 || formData.rfc.length !== 13}
      >
        Enviar
      </Button>
    </form>
  );
};
