import { Heading, Grid, useToast } from "@chakra-ui/react";
import { ButtonValidations } from "components/user/ButtonValidations";
import { FormFieldValidation } from "components/user/FormFieldValidation";
import { DEFAULT_TOAST_TIME } from "configuration";
import React, { useState, useCallback, useEffect } from "react";
import { LoaderBasic } from "components/LoaderBasic";
import retryWithIntervals from "helpers/retry";
import { DEFAULT_INTERVAL } from "configuration";

const LoadOcrConfirmation = ({
  loadClient,
  confirmOCRInfo,
  globalEffectUpdate,
}) => {
  const [client, setClient] = useState(null);
  let onTimeout = useCallback(() => {
    confirmOCRInfo(false).then((res) => {
      globalEffectUpdate();
    });
  }, []);
  let onRetry = useCallback(async () => {
    let { data } = await loadClient();
    if (data.rfc && data.rfc !== "") {
      setClient(data);
      return true;
    }
    return false;
  }, [setClient, loadClient, client]);

  useEffect(() => {
    retryWithIntervals(
      onRetry,
      [
        DEFAULT_INTERVAL,
        5 * DEFAULT_INTERVAL,
        10 * DEFAULT_INTERVAL,
        15 * DEFAULT_INTERVAL,
        20 * DEFAULT_INTERVAL,
        25 * DEFAULT_INTERVAL,
        30 * DEFAULT_INTERVAL,
        35 * DEFAULT_INTERVAL,
      ],
      globalEffectUpdate,
      onTimeout
    );
  }, [onRetry, onTimeout, globalEffectUpdate]);
  if (!client) {
    return <LoaderBasic />;
  }
  return (
    <OCRConfirmation
      user={client}
      confirmOCRInfo={confirmOCRInfo}
      globalEffectUpdate={globalEffectUpdate}
    />
  );
};

export const OCRConfirmation = ({
  user,
  confirmOCRInfo,
  globalEffectUpdate,
}) => {
  const [loading, setLoading] = useState(false);
  const { rfc, curp } = user.identification;
  const toast = useToast();
  const validateOCRInfo = async (valid) => {
    setLoading(true);
    const res = await confirmOCRInfo(valid);
    if (res.status === 200) {
      globalEffectUpdate();
    } else {
      toast({
        position: "top",
        title: "Estamos experimentando intermitencia",
        description: "Inténtalo de nuevo mas tarde",
        status: "error",
        duration: DEFAULT_TOAST_TIME,
        isClosable: true,
      });
    }
    setLoading(false);
  };
  const [field1Data] = useState({
    label: "Tu RFC es:",
    value: rfc,
    id: "rfc",
    placeholder: "RFC",
  });
  const [field2Data] = useState({
    label: "Tu CURP es:",
    value: curp,
    id: "curp",
    placeholder: "CURP",
  });

  if (loading) {
    return <LoaderBasic />;
  }
  return (
    <>
      <Heading
        color={"blue.700"}
        w="100%"
        textAlign={"center"}
        fontWeight="normal"
        mb="2%"
      >
        Confirma que tus datos sean correctos
      </Heading>
      <Grid
        mt={"2%"}
        mx={"5%"}
        templateColumns={{
          base: "repeat(1, 1fr)",
          md: "repeat(2, 1fr)",
        }}
        gap={6}
      >
        <FormFieldValidation fieldData={field1Data} />
        <FormFieldValidation fieldData={field2Data} />
      </Grid>
      <Heading
        color={"red"}
        w="100%"
        textAlign={"center"}
        fontWeight="normal"
        size={"sm"}
        mt="2%"
      >
        Estos datos son de suma importancia para la aprobación de tu crédito.
      </Heading>
      <Heading
        color={"blue.600"}
        w="100%"
        textAlign={"center"}
        fontWeight="normal"
        size={"md"}
        mt="2%"
      >
        ¿Son correctos tus datos?
      </Heading>
      <ButtonValidations
        onClickYes={() => validateOCRInfo(true)}
        onClickNo={() => validateOCRInfo(false)}
      />
    </>
  );
};

export default LoadOcrConfirmation;
