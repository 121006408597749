import {
  Heading,
  FormControl,
  FormLabel,
  Input,
  Button,
  Select,
  useDisclosure,
  Center,
  Spinner,
  Grid,
  useToast,
} from "@chakra-ui/react";
import { useForm, useWatch } from "react-hook-form";
import IneHelperModal from "components/signup/IneHelperModal";
import { useEffect, useState } from "react";
import { DEFAULT_TOAST_TIME, StepDictionary } from "configuration";
import React from "react";
import { LoaderBasic } from "components/LoaderBasic";

function defaultBirthDate() {
  let date = new Date();
  date.setFullYear(date.getFullYear() - 18);
  return date.toDateString();
}

const Ine = ({
  ineManualRenewal,
  validateIne,
  client,
  updateClient,
  globalEffectUpdate,
}) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [modeloINE, setModeloINE] = useState(1);
  const { register, handleSubmit, control } = useForm();
  const [loading, setLoading] = useState();
  const toast = useToast();
  const [fechaNacimiento, setFechaNacimiento] = useState(defaultBirthDate());
  const [isButtonEnabled, setIsButtonEnabled] = useState(false);

  const { identification, step } = client;

  const [areVisible] = useState(
    step !== StepDictionary.INE_MANUAL_RENEWAL ? true : false
  );

  useEffect(() => {
    if (identification?.cic || identification?.electoral_id) {
      setIsButtonEnabled(true);
    }
    if (client.birth_date) {
      setFechaNacimiento(client.birth_date);
    }
    if (step === StepDictionary.INE_MANUAL_RENEWAL) {
      setModeloINE(2);
    }
  }, [client, setFechaNacimiento, identification, step]);

  const handleKeyPress = (event) => {
    const charCode = event.which || event.keyCode;
    const char = String.fromCharCode(charCode);
    const regex = /^[a-zA-ZñÑ]+$/;

    if (!regex.test(char)) {
      event.preventDefault();
    }
  };

  const handleChange = (event) => {
    const cic = event.target.value;
    const idCiudadano = event.target.value;
    if (cic || idCiudadano) {
      setIsButtonEnabled(true);
    } else {
      setIsButtonEnabled(false);
    }
  };

  const modelo = useWatch({
    control,
    name: "modelo", // El nombre del campo que deseas observar
    defaultValue: "Selecciona una opción", // Valor por defecto si el campo está vacío
  });

  const onSubmit = async (dataForm) => {
    if (step === StepDictionary.INE_MANUAL_RENEWAL) {
      const { data, status } = await ineManualRenewal(dataForm);
      if (data) {
        if (status === 200) {
          toast({
            title: "INE validada",
            description: "Tu INE ha sido validada correctamente",
            status: "success",
            duration: DEFAULT_TOAST_TIME,
            isClosable: true,
          });
          updateClient(data);
          globalEffectUpdate();
        } else if (data.error_code === 2) {
          toast({
            title:
              "INE no válida, CIC o Identificador del ciudadano incorrecto",
            description: "Por favor verifica los datos ingresados",
            status: "error",
            duration: DEFAULT_TOAST_TIME,
            isClosable: true,
          });
        }
      }
      return 0;
    }
    setLoading(true);
    if (modeloINE === 1) {
      dataForm = {
        ...dataForm,
        idCiudadano: null,
      };
    } else {
      dataForm = {
        ...dataForm,
        ocr: null,
      };
    }
    const { data, status } = await validateIne(dataForm);
    setLoading(false);
    if (data) {
      if (status === 200) {
        toast({
          title: "INE validada",
          description: "Tu INE ha sido validada correctamente",
          status: "success",
          duration: DEFAULT_TOAST_TIME,
          isClosable: true,
        });
        updateClient(data);
        globalEffectUpdate();
      } else if (data.error_code === 2) {
        toast({
          title: "INE no válida, CIC o Identificador del ciudadano incorrecto",
          description: "Por favor verifica los datos ingresados",
          status: "error",
          duration: DEFAULT_TOAST_TIME,
          isClosable: true,
        });
      } else if (data.error_code === 6) {
        toast({
          title: "No se pudo generar el CURP",
          description: "Por favor verifica los datos ingresados",
          status: "error",
          duration: DEFAULT_TOAST_TIME,
          isClosable: true,
        });
      } else if (data.error_code === 3) {
        toast({
          title: "CURP ya registrado",
          description: "Por favor inicia sesión en la cuenta correspondiente",
          status: "error",
          duration: DEFAULT_TOAST_TIME,
          isClosable: true,
        });
      }
    }
  };

  const ineYear = (e) => {
    setModeloINE(e.target.selectedIndex);
  };

  if (loading) {
    return <LoaderBasic />;
  }

  return (
    <>
      <IneHelperModal
        modeloINE={modeloINE}
        isOpen={isOpen}
        onClose={onClose}
      ></IneHelperModal>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Heading
          color={"blue.700"}
          w="100%"
          textAlign={"center"}
          fontWeight="normal"
          mb="2%"
        >
          Por favor llena los campos solicitados
        </Heading>
        <Grid
          mt={"2%"}
          templateColumns={{
            base: "repeat(1, 1fr)",
            md: "repeat(2, 1fr)",
          }}
          gap={6}
        >
          {areVisible && (
            <FormControl mr="5%">
              <FormLabel
                htmlFor="first_name"
                fontSize={"small"}
                fontWeight={"semibold"}
              >
                Nombre
              </FormLabel>
              <Input
                id="first_name"
                {...register("first_name")}
                defaultValue={client?.name}
                placeholder="Nombre como aparece en tu INE"
                required
                size="sm"
                rounded="md"
                onKeyPress={handleKeyPress}
                style={{
                  borderColor:
                    !identification?.electoral_id && !identification?.cic
                      ? "red"
                      : "inherit",
                }}
              />
            </FormControl>
          )}

          {areVisible && (
            <FormControl>
              <FormLabel
                htmlFor="second_name"
                fontSize={"small"}
                fontWeight={"semibold"}
              >
                Segundo Nombre
              </FormLabel>
              <Input
                id="second_name"
                {...register("second_name")}
                defaultValue={client?.second_name}
                placeholder="En caso de tenerlo"
                size="sm"
                rounded="md"
                onKeyPress={handleKeyPress}
                style={{
                  borderColor:
                    !identification?.electoral_id && !identification?.cic
                      ? "red"
                      : "inherit",
                }}
              />
            </FormControl>
          )}

          {areVisible && (
            <FormControl mr="5%">
              <FormLabel
                htmlFor="apellido"
                fontSize={"small"}
                fontWeight={"semibold"}
              >
                Apellido Paterno
              </FormLabel>
              <Input
                id="apellido"
                {...register("apellido")}
                defaultValue={client?.first_surname}
                placeholder="Apellido Paterno"
                size="sm"
                rounded="md"
                required
                onKeyPress={handleKeyPress}
                style={{
                  borderColor:
                    !identification?.electoral_id && !identification?.cic
                      ? "red"
                      : "inherit",
                }}
              />
            </FormControl>
          )}

          {areVisible && (
            <FormControl>
              <FormLabel
                htmlFor="materno"
                fontSize={"small"}
                fontWeight={"semibold"}
              >
                Apellido Materno
              </FormLabel>
              <Input
                id="materno"
                {...register("materno")}
                defaultValue={client?.second_surname}
                placeholder="Apellido Materno"
                required
                size="sm"
                rounded="md"
                onKeyPress={handleKeyPress}
                style={{
                  borderColor:
                    !identification?.electoral_id && !identification?.cic
                      ? "red"
                      : "inherit",
                }}
              />
            </FormControl>
          )}

          {areVisible && (
            <FormControl mr="5%">
              <FormLabel
                htmlFor="fecha_nacimiento"
                fontSize={"small"}
                fontWeight={"semibold"}
              >
                Fecha de nacimiento
              </FormLabel>
              <Input
                id="fecha_nacimiento"
                {...register("fecha_nacimiento")}
                defaultValue={fechaNacimiento}
                type="date"
                onKeyPress={(event) => {
                  if (event.key === "/") {
                    event.preventDefault();
                  }
                }}
                required
                size="sm"
                rounded="md"
                style={{ borderColor: fechaNacimiento ? "inherit" : "red" }}
              />
            </FormControl>
          )}

          {areVisible && (
            <FormControl>
              <FormLabel
                htmlFor="ocupacion"
                fontSize={"small"}
                fontWeight={"semibold"}
              >
                Ocupación
              </FormLabel>
              <Select
                id="ocupacion"
                {...register("ocupacion")}
                defaultValue={client?.ocupacion}
                placeholder="Selecciona una opción"
                size="sm"
                rounded="md"
                required
                w={"100%"}
                // style={{ borderColor: cliente?.ocupacion ? 'inherit' : 'red' }}
              >
                <option value={"Ama de casa"}>Ama de casa</option>
                <option value={"Desempleado"}>Desempleado</option>{" "}
                {/* Rechazó*/}
                <option value={"Dueño de negocio"}>Dueño de negocio</option>
                <option value={"Empleado privado"}>Empleado privado</option>
                <option value={"Estudiante"}>Estudiante</option>
                <option value={"Jubilado"}>Jubilado</option>
                <option value={"Servidor público"}>Servidor público</option>
              </Select>
            </FormControl>
          )}

          {areVisible && (
            <FormControl mr="5%">
              <FormLabel
                htmlFor="rfc"
                fontSize={"small"}
                fontWeight={"semibold"}
              >
                Entidad federativa de nacimiento
              </FormLabel>
              <Select
                id="entidad"
                {...register("entidad")}
                defaultValue={client?.place_of_birth}
                placeholder="Selecciona una opción"
                size="sm"
                rounded="md"
                required
                style={{
                  borderColor: client?.place_of_birth ? "inherit" : "red",
                }}
              >
                <option value={"AS"}>Aguascalientes</option>
                <option value={"BC"}>Baja California</option>
                <option value={"BS"}>Baja California Sur</option>
                <option value={"CC"}>Campeche</option>
                <option value={"CL"}>Coahuila</option>
                <option value={"CM"}>Colima</option>
                <option value={"CS"}>Chiapas</option>
                <option value={"CH"}>Chihuahua</option>
                <option value={"DF"}>Ciudad de México</option>
                <option value={"DG"}>Durango</option>
                <option value={"GT"}>Guanajuato</option>
                <option value={"GR"}>Guerrero</option>
                <option value={"HG"}>Hidalgo</option>
                <option value={"JC"}>Jalisco</option>
                <option value={"MC"}>Estado de México</option>
                <option value={"MN"}>Michoacán</option>
                <option value={"MS"}>Morelos</option>
                <option value={"NT"}>Nayarit</option>
                <option value={"NL"}>Nuevo León</option>
                <option value={"OC"}>Oaxaca</option>
                <option value={"PL"}>Puebla</option>
                <option value={"QT"}>Querétaro</option>
                <option value={"QR"}>Quintana Roo</option>
                <option value={"SP"}>San Luis Potosí</option>
                <option value={"SL"}>Sinaloa</option>
                <option value={"SR"}>Sonora</option>
                <option value={"TC"}>Tabasco</option>
                <option value={"TS"}>Tamaulipas</option>
                <option value={"TL"}>Tlaxcala</option>
                <option value={"VZ"}>Veracruz</option>
                <option value={"YN"}>Yucatán</option>
                <option value={"ZS"}>Zacatecas</option>
              </Select>
            </FormControl>
          )}

          {areVisible && (
            <FormControl>
              <FormLabel
                htmlFor="sexo"
                fontSize={"small"}
                fontWeight={"semibold"}
              >
                Sexo de nacimiento
              </FormLabel>
              <Select
                id="sexo"
                {...register("sexo")}
                defaultValue={client?.genre === 1 ? "H" : "M"}
                placeholder="Selecciona una opción"
                size="sm"
                rounded="md"
                required
                style={{ borderColor: client?.genre ? "inherit" : "red" }}
              >
                <option value={"M"}>Mujer</option>
                <option value={"H"}>Hombre</option>
              </Select>
            </FormControl>
          )}

          {areVisible && (
            <FormControl>
              <FormLabel
                htmlFor="modelo"
                fontSize={"small"}
                fontWeight={"semibold"}
              >
                Año de emisión de tu INE
              </FormLabel>
              <Select
                id="modelo"
                {...register("modelo")}
                placeholder="Selecciona una opción"
                size="sm"
                rounded="md"
                required
                w={"100%"}
                onChange={ineYear}
                style={{
                  borderColor:
                    modelo !== "Selecciona una opción" ? "inherit" : "red",
                }}
              >
                <option>Antes del 2014</option>
                <option>2014 o después</option>
              </Select>
            </FormControl>
          )}
          <Center>
            <Button
              onClick={onOpen}
              variant="link"
              color={"blue.500"}
              mt={5}
              mr={20}
              size={"sm"}
            >
              ¿Dónde veo el CIC e Identificador del ciudadano?
            </Button>
          </Center>
          <FormControl mr="5%">
            <FormLabel htmlFor="cic" fontSize={"small"} fontWeight={"semibold"}>
              CIC
            </FormLabel>
            <Input
              id="cic"
              {...register("cic")}
              defaultValue={identification?.cic}
              // required
              size="sm"
              rounded="md"
              minLength="9"
              maxLength="9"
              onChange={handleChange}
              style={{ borderColor: identification?.cic ? "inherit" : "red" }}
            />
          </FormControl>
          <FormControl>
            <FormLabel
              htmlFor="idCiudadano"
              fontSize={"small"}
              fontWeight={"semibold"}
            >
              Identificador del ciudadano / OCR
            </FormLabel>
            <Input
              name={modeloINE === 1 ? "ocr" : "idCiudadano"}
              id={modeloINE === 1 ? "ocr" : "idCiudadano"}
              {...register(modeloINE === 1 ? "ocr" : "idCiudadano")}
              defaultValue={identification?.electoral_id}
              // required
              size="sm"
              rounded="md"
              onChange={handleChange}
              minLength={modeloINE === 1 ? "13" : "9"}
              maxLength={modeloINE === 1 ? "13" : "9"}
              style={{
                borderColor: identification?.electoral_id ? "inherit" : "red",
              }}
            />
          </FormControl>
        </Grid>

        <Button
          mt={2}
          mr={2}
          type="submit"
          bg="blue.500"
          color="white"
          variant={"solid"}
          isDisabled={!isButtonEnabled}
        >
          Enviar
        </Button>
        {loading && <Spinner />}
      </form>
    </>
  );
};

export default Ine;
