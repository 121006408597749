import Nuovo from "components/nuovo/Nuovo";
import { Box } from "@chakra-ui/react";
import redux from "reducers/utils/Redux";
import React  from 'react';

function EnrolledNuovo({
  user,
  verifyEnrollment,
  updateClient,
  addToNuovo,
  referecesData,
  registroNuovo,
  globalEffectUpdate,
}) {
  return (
    <>
      <Box
        borderWidth="1px"
        rounded="lg"
        shadow="1px 1px 3px rgba(0,0,0,0.3)"
        maxWidth={800}
        p={6}
        m="10px auto"
      >
        <Nuovo
          client={user}
          verifyEnrollment={verifyEnrollment}
          updateClient={updateClient}
          addToNuovo={addToNuovo}
          referecesData={referecesData}
          registroNuovo={registroNuovo}
          globalEffectUpdate={globalEffectUpdate}
        />
      </Box>
    </>
  );
}

export default redux(EnrolledNuovo);
