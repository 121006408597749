import Actions from "./Actions";

class Nuovo extends Actions {
  state;

  constructor() {
    super();
    this.state = {};
  }

  referencesData(data) {
    const body = {
      nombres: data.first_name,
      apellidoP: data.apellido,
      apellidoM: data.materno,
      telefono: data.telefono,
      marca: data.marcaTel,
      modelo: data.modelo,
    };
    return this.post("/clients/device/enrroll", body, "REFERENCES_DATA");
  }

  verifyEnrollment() {
    return this.get("/clients/verify/enrollement/status", "VERIFY_ENROLLMENT");
  }

  qrEnrolled(token) {
    const body = {
      token,
    };
    return this.post("/clients/qr_enrollement_data", body, "QR_ENROLLED");
  }

  referenceDataRenew(data) {
    const body = {
      nombres: data.first_name,
      apellidoP: data.apellido,
      apellidoM: data.materno,
      telefono: data.telefono,
    };
    return this.post(
      "/clients/reference/enrroll",
      body,
      "REFERENCE_DATA_RENEW"
    );
  }

  nuovoConfirm(data) {
    const body = {
      confirm: data.confirm,
    };
    return this.post("/clients/nuovo/confirm", body, "NUOVO_CONFIRM");
  }

  changeEnrollment() {
    return this.patch("/clients/change/enrollement", "CHANGE_ENROLLMENT");
  }
  sendMessage() {
    return this.post("/clients/send_enrollement_code", {}, "SEND_MESSAGE");
  }
}

export default Nuovo;
