import {
  CURP_SUCCESS,
  CURP_FAIL,
  RFC_SUCCESS,
  RFC_FAIL,
  INE_SUCCESS,
  INE_FAIL,
} from "../actions/nubarium/types";

const initialState = {
  resCURP: null,
  resIne: null,
  resRfc: null,
};

export default function nubarium(state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case CURP_SUCCESS:
      return {
        ...state,
        resCURP: payload,
      };
    case CURP_FAIL:
      return {
        ...state,
        resCURP: null,
      };
    case RFC_SUCCESS:
      return {
        ...state,
        resRfc: payload,
      };
    case RFC_FAIL:
      return {
        ...state,
        resRfc: null,
      };
    case INE_SUCCESS:
      return {
        ...state,
        resIne: payload,
      };
    case INE_FAIL:
      return {
        ...state,
        resIne: null,
      };
    default:
      return state;
  }
}
