import {
  Flex,
  Button,
  Container,
  Heading,
  Image,
  Grid,
  Text,
  Box,
  useToast,
} from "@chakra-ui/react";
import imagen from "assets/img/imagen.webp";
import React from "react";
import redux from "reducers/utils/Redux";
import { DEFAULT_TOAST_TIME } from "configuration";

function RequestOtherCredit({ creditRenewal, globalEffectUpdate }) {
  const toast = useToast();
  const handleClick = async () => {
    const { status } = await creditRenewal(true);
    if (status) {
      globalEffectUpdate();
    } else {
      toast({
        position: "top",
        title: "Estamos experimentando intermitencia",
        description: "Inténtalo de nuevo más tarde",
        status: "error",
        duration: DEFAULT_TOAST_TIME,
        isClosable: true,
      });
    }
  };

  return (
    <>
      <Grid
        mb={10}
        templateColumns={{
          base: "repeat(1, 1fr)",
          md: "repeat(2, 1fr)",
        }}
        align={"center"}
        gap={{ base: "10", md: "8" }}
        px={{ base: "4", md: "8", lg: "16" }} // Ajuste de padding
        py={{ base: "8", md: "12", lg: "16" }} // Ajuste de padding
      >
        <Container maxW={"full"} p={0}>
          <Heading
            color={"blue.700"}
            w="100%"
            textAlign={"center"}
            fontWeight="semibold"
            fontSize={{ base: "2xl", md: "3xl", lg: "4xl" }}
            mb={6}
          >
            No tienes un crédito vigente actualmente
          </Heading>

          <Flex justify={"center"}>
            <Text
              mt={4}
              fontSize={{ base: "14px", md: "16px", lg: "18px" }}
              fontFamily="Arial, sans-serif"
              textAlign="justify"
              maxWidth="90%"
              color="gray.600"
              lineHeight="1.8"
            >
              🌟 ¡Vuelve a experimentar la facilidad y conveniencia de nuestros
              préstamos!
              <br />
              💡 <b>¿Por qué volver a elegirnos?</b> <br />
              ✅ Tasa de interés baja <br />
              ✅ Sin cargos por intereses moratorios <br />
              ✅ Proceso sencillo y rápido <br />
              ✅ Experiencia satisfactoria <br />
              💬 ¡No pierdas esta oportunidad de volver a experimentar la
              comodidad y confianza que ofrecemos en Bund!
            </Text>
          </Flex>

          <Flex mt={8} justifyContent={"center"} color={"blue.700"} w="100%">
            <Button
              onClick={handleClick}
              colorScheme={"blue"}
              size="lg"
              borderRadius="full" // Botón más estilizado
              shadow="md" // Sombra para destacar el botón
              _hover={{ boxShadow: "xl", transform: "scale(1.05)" }} // Hover más interactivo
            >
              Renueva tu crédito
            </Button>
          </Flex>
        </Container>

        <Flex
          flex={1}
          align={"center"}
          justify="center"
          display={{ base: "none", md: "flex" }} // Ocultar en pantallas pequeñas
        >
          <Box
            boxShadow="lg"
            borderRadius="lg"
            overflow="hidden"
            maxH="80vh"
            p={4}
            bg="white"
          >
            <Image
              alt={"Login Image"}
              objectFit={"cover"}
              src={imagen}
              maxH={{ base: "50vh", sm: "60vh", md: "70vh", lg: "80vh" }}
              borderRadius="md"
            />
          </Box>
        </Flex>
      </Grid>
    </>
  );
}

export default redux(RequestOtherCredit);
