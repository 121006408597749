import React, { useState } from "react";
import { Box, Heading, useToast } from "@chakra-ui/react";
import ContactForm from "components/user/ContactForm"; // Asegúrate de la ruta correcta
import redux from "reducers/utils/Redux";
import { set } from "lodash";
import { LoaderBasic } from "components/LoaderBasic";

const ContactFormPage = ({ loanReference, globalEffectUpdate }) => {
  const toast = useToast();
  const [loading, setLoading] = useState(false);

  const handleSubmit = async (formData) => {
    setLoading(true);
    // Hacer la llamada a la API para registrar la referencia
    const formattedData = {
      first_name: formData.firstName,
      second_name: formData.secondName,
      apellidoP: formData.lastName,
      apellidoM: formData.secondLastName,
      telefono: formData.phone,
      email: formData.email,
    };
    const { data } = await loanReference(formattedData);

    // Verificar si la API devolvió un estado de error
    if (!data.status) {
      // Manejar errores según el código recibido en la respuesta
      if (data.errorCode === 1) {
        toast({
          title: "Error al registrar referencia",
          description: "El email o el teléfono es el mismo que el del cliente.",
          status: "error",
          duration: 5000,
          isClosable: true,
        });
      }

      if (data.errorCode === 3) {
        toast({
          title: "Error al registrar referencia",
          description:
            "Existen caracteres incorrectos en alguno de los campos.",
          status: "error",
          duration: 5000,
          isClosable: true,
        });
      }
      setLoading(false);
      return;
    }
    toast({
      title: "Referencia Registrada",
      description: `¡La referencia ${formattedData.first_name} ${
        formattedData.second_name || ""
      } ${formattedData.apellidoP} ${
        formattedData.apellidoM
      } ha sido registrada exitosamente!`,
      status: "success",
      duration: 5000,
      isClosable: true,
    });
    setLoading(false);
    globalEffectUpdate();
  };

  if (loading) {
    return <LoaderBasic />;
  }

  return (
    <Box p="6">
      <ContactForm handleSubmit={handleSubmit} />
    </Box>
  );
};

export default redux(ContactFormPage);
