import { connect } from "react-redux";
import {
  Box,
  Flex,
  Heading,
  Text,
  Stack,
  Container,
  Avatar,
  useColorModeValue,
  Center,
} from "@chakra-ui/react";
import cal1 from "assets/img/cal1.webp";
import cal2 from "assets/img/cal2.webp";
import React from "react";

const Testimonial = ({ children }) => {
  return <Box>{children}</Box>;
};

const TestimonialContent = ({ children }) => {
  return (
    <Stack
      minH={{ lg: 60 }}
      maxW={{ lg: 400 }}
      bg={useColorModeValue("white", "gray.800")}
      boxShadow={"lg"}
      p={8}
      rounded={"xl"}
      align={"center"}
      pos={"relative"}
      _after={{
        content: `""`,
        w: 0,
        h: 0,
        borderLeft: "solid transparent",
        borderLeftWidth: 16,
        borderRight: "solid transparent",
        borderRightWidth: 16,
        borderTop: "solid",
        borderTopWidth: 16,
        borderTopColor: useColorModeValue("white", "gray.800"),
        pos: "absolute",
        bottom: "-16px",
        left: "50%",
        transform: "translateX(-50%)",
      }}
    >
      {children}
    </Stack>
  );
};

const TestimonialHeading = ({ children }) => {
  return (
    <Heading
      align={"center"}
      color={"#1d6dbc"}
      mt={3}
      as={"h3"}
      fontSize={"xl"}
    >
      {children}
    </Heading>
  );
};

const TestimonialText = ({ children }) => {
  return (
    <Text
      textAlign={"center"}
      color={useColorModeValue("gray.600", "gray.400")}
      fontSize={"sm"}
    >
      {children}
    </Text>
  );
};

const TestimonialAvatar = ({ src, name }) => {
  return (
    <Flex align={"center"} mt={8} direction={"column"}>
      <Avatar src={src} alt={name} mb={2} />
      <Stack color={"#154578"} spacing={-1} align={"center"}>
        <Text fontWeight={600}>{name}</Text>
      </Stack>
    </Flex>
  );
};

function Testimonios() {
  return (
    <Box bg={useColorModeValue("gray.50", "gray.700")}>
      <Container maxW={"7xl"} py={12} as={Stack} spacing={12}>
        <Stack spacing={0} align={"center"}>
          <Center color="#154578" fontSize={{ base: "xl", lg: "5xl" }}>
            Así nos califican nuestros clientes
          </Center>
        </Stack>
        <Stack
          direction={{ base: "column", md: "row" }}
          spacing={{ base: 10, md: 4, lg: 10 }}
        >
          <Testimonial>
            <TestimonialContent>
              <TestimonialHeading>Me sacaron de un apuro</TestimonialHeading>
              <TestimonialText>
                Tenía un crédito contratado que cobraba altos intereses
                semanales, y con Bund pude contratar un crédito con mejores
                condiciones, ahora mis pagos son mas bajos. Gracias
              </TestimonialText>
            </TestimonialContent>
            <TestimonialAvatar src={cal1} name={"Antonio Carvajal"} />
          </Testimonial>
          <Testimonial>
            <TestimonialContent>
              <TestimonialHeading>Excelente servicio</TestimonialHeading>
              <TestimonialText>
                Necesitaba dinero rápido y Bund me ayudó a obtener un crédito en
                cuestión de minutos a través de un proceso muy ágil y seguro
              </TestimonialText>
            </TestimonialContent>
            <TestimonialAvatar src={cal2} name={"Johnathan Enriquez"} />
          </Testimonial>
          <Testimonial>
            <TestimonialContent>
              <TestimonialHeading>Obtuve mi primer crédito</TestimonialHeading>
              <TestimonialText>
                Nunca había tenido acceso a un crédito personal y Bund me dió
                esa oportunidad. Gracias por ayudarme a empezar a construir un
                historial crediticio
              </TestimonialText>
            </TestimonialContent>
            <TestimonialAvatar
              src={
                "https://images.unsplash.com/photo-1586297135537-94bc9ba060aa?ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=100&q=80"
              }
              name={"Amalia Azucena López"}
            />
          </Testimonial>
        </Stack>
      </Container>
    </Box>
  );
}

const mapStateToProp = (state) => ({});

export default connect(mapStateToProp, {})(Testimonios);
