import { connect } from "react-redux";
import { Button, Center, Flex, Heading, Image, Stack } from "@chakra-ui/react";
import {
  RangeSlider,
  RangeSliderTrack,
  RangeSliderFilledTrack,
  RangeSliderThumb,
} from "@chakra-ui/react";
import { useState, useMemo } from "react";
import imagen from "assets/img/imagen.webp";
import React from "react";

function Cotizador() {
  const [monto, setMonto] = useState("2500");
  const cantidad = useMemo(() => {
    const tasaInteres = (0.8966 / 52) * 12;
    const interesIVA = parseInt(monto) * tasaInteres * 0.16;
    return (
      (parseInt(monto) * tasaInteres + interesIVA + parseInt(monto)) /
      12
    ).toFixed(2);
  }, [monto]);

  return (
    <Stack
      bg={"gray.50"}
      maxH={{ lg: "90vh" }}
      direction={{ base: "column", md: "row" }}
    >
      <Flex p={8} flex={1} align={"center"} justify={"center"}>
        <Stack spacing={6} w={"full"} maxW={"lg"}>
          <Heading fontSize={{ base: "3xl", md: "4xl", lg: "5xl" }}>
            <Center color={"#154578"}>Calcula tu crédito</Center>
            <Center mt={"5"} fontSize={"lg"}>
              ¿Cuánto dinero necesitas?
            </Center>
            <Center m={"2"} mb={"-1.5"} color={"#24a4e8"}>
              ${monto}
            </Center>
          </Heading>
          <RangeSlider
            aria-label={"max"}
            defaultValue={[0, 2500]}
            min={2000}
            max={3000}
            step={100}
            onChange={(val) => setMonto(val[1])}
          >
            <RangeSliderTrack rounded="full" h="1" bg="gray.300">
              <RangeSliderFilledTrack bg="#1a6ebb" />
            </RangeSliderTrack>
            <RangeSliderThumb bg="gray.200" boxSize={7} index={1} />
          </RangeSlider>
          <Center fontSize={{ lg: "lg" }}>Pago semanal:</Center>
          <Center fontSize={"lg"} mt={-6} color={"#154578"}>
            ${cantidad}
          </Center>
          <Center fontSize={{ lg: "lg" }} mt={-6}>
            A un plazo de 12 semanas
          </Center>
          <Center fontSize={{ lg: "lg" }} mt={"0.5rem !important"}>
            ¡Esto es lo que pagas!
          </Center>
          <Center fontSize={{ lg: "lg" }} mt={-6}>
            No hay comisiones ni costos ocultos
          </Center>

          <Center>
            <Button
              as={"a"}
              href={"/signup"}
              w={{ lg: "fit-content" }}
              rounded={"full"}
              bg={"#1a6ebb"}
              color={"white"}
              _hover={{
                bg: "blue.500",
              }}
            >
              Inicia tu solicitud
            </Button>
          </Center>
        </Stack>
      </Flex>
      <Flex flex={1} align={"center"}>
        <Image
          alt={"Login Image"}
          objectFit={"cover"}
          src={imagen}
          h={{ sm: "90vh" }}
        />
      </Flex>
    </Stack>
  );
}

const mapStateToProp = () => ({});

export default connect(mapStateToProp, {})(Cotizador);
