import PreguntasFrec from "components/inicio/PreguntasFrec";
import React  from 'react';

function PreguntasPages() {
  return (
    <>
      <PreguntasFrec />
    </>
  );
}

export default PreguntasPages;
