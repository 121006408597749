import {
  CREATE_CLIENTE_SUCCESS,
  UPDATE_CLIENTE_SUCCESS,
  LOAD_CLIENT_SUCCESS,
  LOAD_CLIENT_FAIL,
  GET_USERS_LIST_SUCCESS,
  GET_USERS_LIST_FAIL,
  GET_CLIENTS_LIST_FAIL,
  GET_CLIENTS_LIST_SUCCESS,
} from "redux/actions/clientes/types";

const initialState = {
  cliente: null,
  list: null,
  clientes_list: null,
};

export default function clientes(state = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case LOAD_CLIENT_SUCCESS:
    case CREATE_CLIENTE_SUCCESS:
    case UPDATE_CLIENTE_SUCCESS:
      return {
        ...state,
        cliente: payload,
      };
    case LOAD_CLIENT_FAIL:
      return {
        ...state,
        cliente: null,
      };
    case GET_USERS_LIST_SUCCESS:
      return {
        ...state,
        list: payload,
      };
    case GET_USERS_LIST_FAIL:
      return {
        ...state,
        list: null,
      };
    case GET_CLIENTS_LIST_SUCCESS:
      return {
        ...state,
        clientes_list: payload,
      };
    case GET_CLIENTS_LIST_FAIL:
      return {
        ...state,
        clientes_list: null,
      };
    default:
      return state;
  }
}
