import { Box, Heading, Text, useToast } from "@chakra-ui/react";
import { ButtonValidations } from "./ButtonValidations";
import React, { useState } from "react";
import { DEFAULT_TOAST_TIME } from "configuration";
import { LoaderBasic } from "components/LoaderBasic";

export const PhoneConfirmation = ({
  client,
  globalEffectUpdate,
  nuovoConfirm,
}) => {
  const toast = useToast();
  const [loading, setLoading] = useState(false);

  const onClickYes = async () => {
    setLoading(true);
    const res = await nuovoConfirm({ confirm: false });
    if (res.status) {
      globalEffectUpdate();
    } else {
      toast({
        position: "top",
        title: "Estamos experimentando intermitencia",
        description: "Inténtalo de nuevo mas tarde",
        status: "error",
        duration: DEFAULT_TOAST_TIME,
        isClosable: true,
      });
    }
    setLoading(false);
  };

  const onClickNo = async () => {
    setLoading(true);
    const res = await nuovoConfirm({ confirm: true });
    if (res.status) {
      globalEffectUpdate();
    } else {
      toast({
        position: "top",
        title: "Estamos experimentando intermitencia",
        description: "Inténtalo de nuevo mas tarde",
        status: "error",
        duration: DEFAULT_TOAST_TIME,
        isClosable: true,
      });
    }
    setLoading(false);
  };

  if (loading) return <LoaderBasic />;

  return (
    <>
      <Box textAlign="center" m="1%">
        <Heading
          color={"blue.700"}
          w="100%"
          textAlign={"center"}
          fontWeight="normal"
          mt="2%"
          mb="2%"
        >
          ¿Ha cambiado de dispositivo móvil?
        </Heading>
        <Text color="blue.500" fontWeight="bold">
          Si no ha cambiado de dispositivo móvil, presione "Sí" para continuar
          con su solicitud.
        </Text>
        <Text color="blue.500" fontWeight="bold">
          Si ha cambiado de dispositivo móvil, presione "No" para continuar con
          el proceso de enrolamiento.
        </Text>
        <ButtonValidations
          onClickYes={() => {
            onClickYes();
          }}
          onClickNo={() => {
            onClickNo();
          }}
        />
      </Box>
    </>
  );
};
