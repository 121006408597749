import { connect } from "react-redux";
import {
  Box,
  Flex,
  chakra,
  Grid,
  GridItem,
  Container,
  Center,
  Icon,
} from "@chakra-ui/react";
import { FcOvertime, FcAddressBook } from "react-icons/fc";
import { IoLogoWhatsapp } from "react-icons/io";
import React from "react";

const Feature = ({ heading, text, icon }) => {
  return (
    <GridItem align={"center"}>
      <Flex
        w={16}
        h={16}
        align={"center"}
        justify={"center"}
        color={"white"}
        rounded={"full"}
        bg={"gray.100"}
        mb={1}
      >
        {icon}
      </Flex>
      <chakra.h3 fontSize="xl" fontWeight="600">
        {heading}
      </chakra.h3>
      <chakra.p>{text}</chakra.p>
    </GridItem>
  );
};

function Contacto() {
  return (
    <Box as={Container} maxW="7xl" my={8} py={8} px={10}>
      <Center mb={12} color="#1a6ebb" fontSize={{ base: "xl", lg: "4xl" }}>
        Si tienes alguna duda, contáctanos a través de estos medios:
      </Center>
      <Grid
        templateColumns={{
          base: "repeat(1, 1fr)",
          sm: "repeat(2, 1fr)",
          md: "repeat(3, 1fr)",
        }}
        gap={{ base: "8", sm: "12", md: "16" }}
      >
        <Feature
          heading={"Lunes a Viernes de"}
          text={"9AM a 6PM"}
          icon={<Icon as={FcOvertime} w={10} h={10} />}
        />
        <a
          rel="noreferrer"
          target={"_blank"}
          href="https://wa.me/5523412346?text=Hola,%20tengo%20una%20duda%20con%20respecto%20a..."
        >
          <Feature
            heading={"Por WhatsApp"}
            text={"Click to Chat"}
            icon={
              <Icon color={"green.300"} as={IoLogoWhatsapp} w={10} h={10} />
            }
          />
        </a>
        <a
          rel="noreferrer"
          target={"_blank"}
          href="mailto:contacto@bund.com.mx?subject=Consulta&body=Hola,%20tengo%20una%20duda%20con%20respecto%20a..."
        >
          <Feature
            heading={"Por correo"}
            text={"contacto@bund.com.mx"}
            icon={<Icon as={FcAddressBook} w={10} h={10} />}
          />
        </a>
      </Grid>
    </Box>
  );
}

const mapStateToProp = () => ({});

export default connect(mapStateToProp, {})(Contacto);
