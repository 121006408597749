import { Flex, Heading, Stack, useToast, Button } from "@chakra-ui/react";
import { FormFieldValidation } from "./FormFieldValidation";
import { useState } from "react";
import { ButtonValidations } from "./ButtonValidations";
import React from "react";
import { DEFAULT_TOAST_TIME } from "configuration";
import { LoaderBasic } from "components/LoaderBasic";

export const RenewPhone = ({ user, confirmPhone, globalEffectUpdate }) => {
  const [loading, setLoading] = useState(false);
  const [isDisabled, setIsDisabled] = useState(true);
  const [phone, setPhone] = useState(user.phone);
  const [fieldData, setFieldData] = useState({
    label: "Tu teléfono es:",
    value: phone,
    id: "phone",
  });
  const onClickNo = () => {
    setFieldData({ ...fieldData, value: "" });
    setIsDisabled(!isDisabled);
  };

  const toast = useToast();

  const onClickYes = async () => {
    setLoading(true);
    const { data, status } = await confirmPhone({ phone: phone });
    if (data.status) {
      globalEffectUpdate();
    } else if (data.error_code === 1) {
      toast({
        position: "top",
        title: "Teléfono ya existente",
        description: "Ingresa un teléfono diferente",
        status: "error",
        duration: DEFAULT_TOAST_TIME,
        isClosable: true,
      });
    } else if (status === 400 && data.error_code === 3) {
      toast({
        title: "Teléfono con formato incorrecto",
        description: "Ingrese un teléfono válido",
        status: "error",
        duration: DEFAULT_TOAST_TIME,
        isClosable: true,
      });
      return;
    }
    setLoading(false);
  };

  const onClick = async () => {
    setLoading(true);
    const { data, status } = await confirmPhone({ phone: phone });
    if (data.status) {
      globalEffectUpdate();
    } else if (data.error_code === 1) {
      toast({
        position: "top",
        title: "Teléfono ya existente",
        description: "Ingresa un teléfono diferente",
        status: "error",
        duration: DEFAULT_TOAST_TIME,
        isClosable: true,
      });
    } else if (status === 400 && data.error_code === 3) {
      toast({
        title: "Teléfono con formato incorrecto",
        description: "Ingrese un teléfono válido",
        status: "error",
        duration: DEFAULT_TOAST_TIME,
        isClosable: true,
      });
      return;
    }
    setLoading(false);
  };

  const onCancel = () => {
    setIsDisabled(true);
    setPhone(user.phone);
  };

  if (loading) return <LoaderBasic />;

  return (
    <Flex p={8} flex={1} align={"center"} justify={"center"}>
      <Stack spacing={4} w={"full"} maxW={"md"}>
        <Heading
          color={"blue.700"}
          w="100%"
          textAlign={"center"}
          fontWeight="normal"
          mb="2%"
        >
          Confirma que tu teléfono sea correcto
        </Heading>
        <FormFieldValidation
          fieldData={fieldData}
          isDisabled={isDisabled}
          setValue={setPhone}
        />
        {(isDisabled && (
          <ButtonValidations onClickYes={onClickYes} onClickNo={onClickNo} />
        )) || (
          <>
            <Button colorScheme={"blue"} onClick={onClick}>
              Actualizar
            </Button>
            <Button colorScheme={"red"} onClick={onCancel}>
              Cancelar
            </Button>
          </>
        )}
      </Stack>
    </Flex>
  );
};
