import Actions from "./Actions";

class Auth extends Actions {
  state;

  constructor() {
    super();
    this.state = {
      isAuthenticated: null,
      user: null,
      loading: false,
    };
  }

  isAuthenticated() {
    return this.state.user !== null || localStorage.getItem("access");
  }

  loadUser() {
    return this.get(`/clients/`, USER_LOADED_SUCCES);
  }

  login(email, password) {
    const body = {
      email,
      password,
    };
    return this.post("/users/login", body, LOGIN_SUCCESS);
  }

  checkAuthenticated() {
    const body = {
      token: localStorage.getItem("access"),
    };
    return this.post("/users/sessions/validate", body, AUTHENTICATED_SUCCESS);
  }

  refresh() {
    const body = {
      refresh: localStorage.getItem("refresh"),
    };
    return this.post("/auth/jwt/refresh/", body, REFRESH_SUCCESS);
  }

  resetPassword(email) {
    const body = {
      email,
    };
    return this.post("/users/password/reset", body, RESET_PASSWORD_SUCCESS);
  }

  resetPasswordConfirm(body) {
    return this.put(
      "/users/password/reset/confirm",
      body,
      RESET_PASSWORD_CONFIRM_SUCCESS
    );
  }

  email_confirm(uid, token) {
    const body = {
      uid,
      token,
    };
    return this.post("/auth/users/activation/", body, "EMAIL_CONFIRM");
  }

  logout() {
    return async (dispatch) => {
      this.state.user = null;
      localStorage.clear();
      dispatch({
        type: LOGOUT,
      });
    };
  }
}

export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FAIL = "LOGIN_FAIL";
export const RESET_PASSWORD_SUCCESS = "RESET_PASSWORD_SUCCESS";
export const RESET_PASSWORD_FAIL = "RESET_PASSWORD_FAI";
export const RESET_PASSWORD_CONFIRM_SUCCESS = "RESET_PASSWORD_CONFIRM_SUCCESS";
export const RESET_PASSWORD_CONFIRM_FAIL = "RESET_PASSWORD_CONFIRM_FAIL";
export const SET_AUTH_LOADING = "SET_AUTH_LOADING";
export const REMOVE_AUTH_LOADING = "REMOVE_AUTH_LOADING";
export const LOGOUT = "LOGOUT";
export const AUTHENTICATED_FAIL = "AUTHENTICATED_FAIL";
export const AUTHENTICATED_SUCCESS = "AUTHENTICATED_SUCCESS";
export const REFRESH_SUCCESS = "REFRESH_SUCCESS";
export const REFRESH_FAIL = "REFRESH_FAIL";
export const USER_LOADED_SUCCES = "USER_LOADED_SUCCES";
export const USER_LOADED_FAIL = "USER_LOADED_FAIL";

export default Auth;
