const API_URL = getApiUrl();
const ADMIN_TYPE = "1";
const CLIENT_TYPE = "2";
const DEFAULT_TOAST_TIME = 5000;
const ADMIN_HOME_ROUTE = "/admin";
const CLIENT_HOME_ROUTE = "/user";
const DEFAULT_EMAIL_PLACEHOLDER = "correo@dominio.com";
const DEFAULT_PASSWORD_PLACEHOLDER = "**********";

function getApiUrl() {
  let url = `${process.env.REACT_APP_BASE_URL}`;
  return process.env.REACT_APP_BASE_URL ? url : "http://10.1.0.110:81/";
}

const SITE_KEY = getSiteKey();

function getSiteKey() {
  let key = `${process.env.REACT_APP_SITE_KEY}`;
  return process.env.REACT_APP_SITE_KEY
    ? key
    : "6LfOXhkpAAAAAA67Zg6InuiPGdgmOeHSKq4qJHbw";
}

function getHeaders() {
  return {
    headers: {
      Authorization: `JWT ${localStorage.getItem("access")}`,
      "Content-Type": "application/json",
    },
  };
}

const BankDictionary = {
  "062": "AFIRME",
  "042": "MIFEL",
  133: "BANCO ACTINVER",
  127: "BANCO AZTECA",
  152: "BM BANCREA",
  145: "BANCO BASE SA",
  130: "BANCO COMPARTAMOS",
  "030": "BAJIO",
  "036": "BANCO INBURSA",
  150: "BANCO INMOBILIARIO",
  "059": "BANCO INVEX",
  "021": "BANCO HSBC",
  132: "BANCO MULTIVA",
  "058": "BANCO REGIONAL DE MONTERREY",
  156: "BANCO SABADELL",
  137: "BANCOPPEL",
  "014": "BANCO SANTANDER",
  113: "BANCO VE POR MAS SA",
  106: "BANK OF AMERICA",
  147: "BANKAOOL",
  "072": "BANORTE IXE",
  "012": "BBVA BANCOMER",
  136: "INTERCAM BANCO",
  "044": "SCOTIABANK",
  "002": "BANAMEX",
  "019": "BANJERCITO",
  143: "CIBanco",
};

const StepDictionary = {
  LOCKED: "0",
  CONFIRM_EMAIL: "1",
  UPLOAD_RFC_CURP: "2",
  SAVE_ADDRESS: "3",
  OCR_INE: "2.5",
  INE_MANUAL: "2.7",
  PARAMETRIC_MODEL: "3.5",
  BANK_DATA: "4",
  ENROLLED_PHONE: "5",
  PENDING_PHONE_ENROLLMENT: "5.5",
  CREATE_LOAD: "6",
  SIGN_CONTRACT: "7",
  SEE_CREDIT: "8",
  RENEW_LOAN: "9",
  NO_RENEWAL_LOAN: "9.5",
  REFUSED: "10",
  VALIDATE_PHONE: "11",
  VERIFY_RENEWAL_EMAIL_INFORMATION: "12",
  VERIFY_RENEWAL_PHONE_INFORMATION: "12.5",
  CONFIRM_OCR_INFO: "13",
  CAN_NOT_RENEW_LOAN: "14",
  VALIDATE_CODE_TO_RENEW: "15",
  INE_MANUAL_RENEWAL: "16",
  SAVE_ADDRESS_RENEWAL: "17",
  NUOVO_RENEWAL: "18",
  REFERENCE_NUOVO: "19",
  CONFIRM_BANK_DATA: "20",
  REFERENCE_LOAN: "21",
  CONFIRM_REFERENCE: "22",
};

const DEFAULT_INTERVAL = 1000;
const INTERVAL_INCREASE_STEP = 100;
const ERROR_INTERVAL = 30000;
const MAX_INTERVAL_1_MIN = 60000;
const MAX_INTERVAL_1_HOUR = 3600000;
const MAX_INTERVAL_24_HOURS = 86400000;

function IsCardNumber(cardNo) {
  let nDigits = cardNo.length;

  let nSum = 0;
  let isSecond = false;
  for (let i = nDigits - 1; i >= 0; i--) {
    let d = cardNo[i].charCodeAt() - "0".charCodeAt();

    if (isSecond) d = d * 2;

    // We add two digits to handle
    // cases that make two digits
    // after doubling
    nSum += parseInt(d / 10, 10);
    nSum += d % 10;

    isSecond = !isSecond;
  }
  return nSum % 10 === 0;
}

export {
  API_URL,
  ADMIN_TYPE,
  CLIENT_TYPE,
  DEFAULT_TOAST_TIME,
  ADMIN_HOME_ROUTE,
  CLIENT_HOME_ROUTE,
  DEFAULT_EMAIL_PLACEHOLDER,
  DEFAULT_PASSWORD_PLACEHOLDER,
  SITE_KEY,
  IsCardNumber,
  BankDictionary,
  StepDictionary,
  getHeaders,
  DEFAULT_INTERVAL,
  INTERVAL_INCREASE_STEP,
  MAX_INTERVAL_1_MIN,
  MAX_INTERVAL_1_HOUR,
  MAX_INTERVAL_24_HOURS,
  ERROR_INTERVAL,
};
