import { connect } from "react-redux";
import { useNavigate, useLocation, useParams } from "react-router";
import Defs from "actions/helpers/Defs";
import Client from "actions/Client";
import React from "react";

const DefsClient = new Client();

const actions = [{ className: Client, object: DefsClient }, ...Defs];

export const withRouter = (Component) => {
  const Wrapper = (props) => {
    const history = useNavigate();
    const location = useLocation();
    const params = useParams();

    return (
      <Component
        history={history}
        params={params}
        location={location}
        {...props}
      />
    );
  };
  return Wrapper;
};

const stateToProps = () => {
  return {
    state: "state",
  };
};

const dispToProps = (disp) => {
  let res = {};
  for (let action of actions) {
    let methods = Object.getOwnPropertyNames(action.className.prototype);
    for (let method of methods)
      if (method !== "constructor")
        res[method] = (...args) => disp(action.object[method](...args));
  }
  return res;
};

const mergeProps = (states, disps, props) =>
  Object.assign({}, states, disps, props);

const redux = (component) =>
  withRouter(connect(stateToProps, dispToProps, mergeProps)(component));

export default redux;
