import {
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  TableContainer,
  Stack,
  Box,
  Link,
} from "@chakra-ui/react";
import React  from 'react';

function Cat() {
  return (
    <>
      <Box mt={"10"} align={"center"}>
        <TableContainer maxW={"90%"}>
          <Table variant="striped">
            <Thead>
              <Tr>
                <Th>Producto</Th>
                <Th>Tasa de interés efectiva</Th>
                <Th>
                  Monto/Método de cálculo. Comisión por pago tardío o inoportuno
                </Th>
                <Th>CAT Promedio</Th>
              </Tr>
            </Thead>
            <Tbody>
              <Tr>
                <Td>Préstamo a 12 semanas</Td>
                <Td>22.42% sin IVA*</Td>
                <Td>Sin comisión y sin intereses moratorios</Td>
                <Td>429.60% sin IVA*</Td>
              </Tr>
            </Tbody>
          </Table>
        </TableContainer>
      </Box>
      <Stack my={5} mx={20}>
        <p>
          Para fines informativos y de comparación, el cálculo dependerá de la
          evaluación de crédito.
        </p>
        <p>Dato actualizado al 26 de septiembre de 2023.</p>
        <p>*La tasa debe ser adicionada con el IVA correspondiente</p>
        <p>
          Para más información, consulta{" "}
          <Link
            target={"_blank"}
            href="https://www.banxico.org.mx/CAT/"
            color="blue.700"
          >
            https://www.banxico.org.mx/CAT/
          </Link>
        </p>
      </Stack>
    </>
  );
}

export default Cat;
