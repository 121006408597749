import { connect } from "react-redux";
import {
  Container,
  Center,
  ListItem,
  List,
  UnorderedList,
  Button,
  Flex,
  Box,
  Text,
} from "@chakra-ui/react";
import PDFViewer from "components/PDFViewer/PDF";
import { useState } from "react";
import React  from 'react';

const Tutoriales = () => {
  const [pdfUrl, setPdfUrl] = useState("/pdfs/Motorola.pdf");
  return (
    <Container py={5} minW={"95vw"}>
      <Center color={"blue.700"} fontSize={"5xl"} fontWeight={"bold"}>
        Tutoriales
      </Center>
      <Flex
        mt={5}
        align="center"
        justifyContent="space-around"
        flexWrap="wrap"
        gap="1.5rem"
      >
        <Box>
          <List>
            <ListItem>
              <Text fontWeight={"semibold"}>
                Manuales de enrolamiento a Nuovo:{" "}
              </Text>
              <UnorderedList color="blue.500" ml="3rem">
                <ListItem>
                  <Button
                    colorScheme="blue"
                    variant="link"
                    onClick={() => setPdfUrl("/pdfs/Motorola.pdf")}
                  >
                    Con Motorola
                  </Button>
                </ListItem>
                <ListItem>
                  <Button
                    colorScheme="blue"
                    variant="link"
                    onClick={() => setPdfUrl("/pdfs/OPPO.pdf")}
                  >
                    Con Oppo
                  </Button>
                </ListItem>
                <ListItem>
                  <Button
                    colorScheme="blue"
                    variant="link"
                    onClick={() => setPdfUrl("/pdfs/Realme.pdf")}
                  >
                    Con Realme
                  </Button>
                </ListItem>
                <ListItem>
                  <Button
                    colorScheme="blue"
                    variant="link"
                    onClick={() => setPdfUrl("/pdfs/Samsung.pdf")}
                  >
                    Con Samsung anterior a 2020
                  </Button>
                </ListItem>
                <ListItem>
                  <Button
                    colorScheme="blue"
                    variant="link"
                    onClick={() => setPdfUrl("/pdfs/Samsung_Knox.pdf")}
                  >
                    Con Samsung de 2020 en adelante
                  </Button>
                </ListItem>
                <ListItem>
                  <Button
                    colorScheme="blue"
                    variant="link"
                    onClick={() => setPdfUrl("/pdfs/Xiaomi.pdf")}
                  >
                    Con Xiaomi
                  </Button>
                </ListItem>
              </UnorderedList>
            </ListItem>
            <ListItem mt="1.5rem">
              <Text fontWeight={"semibold"}>Respalda tu información: </Text>
              <UnorderedList color="blue.500" ml="3rem">
                <ListItem>
                  <Button
                    colorScheme="blue"
                    variant="link"
                    onClick={() => setPdfUrl("/pdfs/Backup.pdf")}
                  >
                    Backup con Google One
                  </Button>
                </ListItem>
                <ListItem>
                  <Button
                    colorScheme="blue"
                    variant="link"
                    onClick={() => setPdfUrl("/pdfs/Backup_Xiaomi.pdf")}
                  >
                    Backup con Xiaomi Cloud
                  </Button>
                </ListItem>
                <ListItem>
                  <Button
                    colorScheme="blue"
                    variant="link"
                    onClick={() => setPdfUrl("/pdfs/Backup_Samsung.pdf")}
                  >
                    Backup con Samsung Cloud
                  </Button>
                </ListItem>
              </UnorderedList>
            </ListItem>
          </List>
        </Box>
        <Box>
          <PDFViewer pdfUrl={pdfUrl} />
        </Box>
      </Flex>
    </Container>
  );
};

const mapStateToProp = (state) => ({});

export default connect(mapStateToProp, {})(Tutoriales);
