import {
  EMAILAGE_SUCCESS,
  EMAILAGE_FAIL,
  SMS_SUCCESS,
  SMS_FAIL,
  PHONE_SUCCESS,
  PHONE_FAIL,
} from "../actions/infoPersonal/types";

const initialState = {
  emailage: null,
  sms: null,
  phone: null,
};

export default function infoPersonal(state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case EMAILAGE_SUCCESS:
      return {
        ...state,
        emailage: payload,
      };
    case PHONE_SUCCESS:
      return {
        ...state,
        phone: payload,
      };
    case EMAILAGE_FAIL:
      return {
        ...state,
        emailage: null,
      };
    case PHONE_FAIL:
      return {
        ...state,
        phone: null,
      };
    case SMS_SUCCESS:
      return {
        ...state,
        sms: payload,
      };
    case SMS_FAIL:
      return {
        ...state,
        sms: null,
      };
    default:
      return state;
  }
}
