import {
  GET_MY_PAYMENTS_FAIL,
  GET_MY_PAYMENTS_SUCCESS,
} from "redux/actions/pagos/types";

const initialState = {
  misPagos: null,
};

export default function pagos(state = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case GET_MY_PAYMENTS_SUCCESS:
      return {
        ...state,
        misPagos: payload,
      };
    case GET_MY_PAYMENTS_FAIL:
      return {
        ...state,
        misPagos: null,
      };
    default:
      return state;
  }
}
