import { useEffect, useCallback } from "react";
import { Heading, Text, Flex } from "@chakra-ui/react";
import redux from "reducers/utils/Redux";
import retryWithIntervals from "helpers/retry";
import {
  DEFAULT_INTERVAL,
  MAX_INTERVAL_1_MIN,
  MAX_INTERVAL_1_HOUR,
} from "configuration";
import { useState } from "react";
import "styles/loader.css";
import { LoaderBasic } from "components/LoaderBasic";
import React from "react";

const ParametricModelPage = ({ globalEffectUpdate, loadClient, user }) => {
  const [loading, setLoading] = useState(true);
  const [client, setClient] = useState(user);
  const [error, setError] = useState(null);
  let onTimeout = useCallback(() => {
    setLoading(false);
    setError("Timeout");
  }, []);

  let onRetry = useCallback(async () => {
    setLoading(true);
    setError(null);
    let { data } = await loadClient();
    if (data.step !== client.step) {
      setClient(data);
      return true;
    }
    return false;
  }, [setClient, loadClient, client]);

  useEffect(() => {
    retryWithIntervals(
      onRetry,
      [
        DEFAULT_INTERVAL,
        5 * DEFAULT_INTERVAL,
        10 * DEFAULT_INTERVAL,
        15 * DEFAULT_INTERVAL,
        20 * DEFAULT_INTERVAL,
        25 * DEFAULT_INTERVAL,
        30 * DEFAULT_INTERVAL,
        35 * DEFAULT_INTERVAL,
        40 * DEFAULT_INTERVAL,
        45 * DEFAULT_INTERVAL,
        MAX_INTERVAL_1_MIN,
        3 * MAX_INTERVAL_1_MIN,
        5 * MAX_INTERVAL_1_MIN,
        MAX_INTERVAL_1_HOUR,
      ],
      globalEffectUpdate,
      onTimeout
    );
  }, [onRetry, onTimeout, globalEffectUpdate]);
  if (loading) {
    return <LoaderBasic />;
  }

  if (error) {
    return (
      <Flex direction="column" align="center" mx="10" my="10">
        <Heading as="h2" size="xl">
          Error
        </Heading>
        <Text alignText="center" color={"gray.500"}>
          Ocurrió un error al cargar la información, por favor intente de nuevo.
        </Text>
      </Flex>
    );
  }

  return <></>;
};

export default redux(ParametricModelPage);
