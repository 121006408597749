import { Box, Heading, Input, Text } from "@chakra-ui/react";
import { ButtonValidations } from "./ButtonValidations";

export const BankAcountConfirmation = ({ bankData, onClickYes, onClickNo }) => {
  const { number, bank } = bankData;

  return (
    <Box textAlign="center" m="1%">
      <Heading
        color={"blue.700"}
        textAlign={"center"}
        fontWeight="normal"
        my="2%"
      >
        ¿Tu cuenta bancaria sigue siendo la misma?
      </Heading>
      <Text>
        Esta es tu cuenta bancaria que tenemos registrada. Si tus datos siguen
        siendo los mismos, presiona "Sí" para continuar con el proceso. En caso
        contrario, presiona "No" para actualizar tus datos.
      </Text>
      <Input
        my={2}
        placeholder="Bank account number"
        size="sm"
        rounded="md"
        disabled={true}
        defaultValue={number}
      />
      <Input
        my={2}
        placeholder="Bank"
        defaultValue={bank}
        size="sm"
        rounded="md"
        disabled={true}
      />
      <ButtonValidations onClickYes={onClickYes} onClickNo={onClickNo} />
    </Box>
  );
};
