import { useEffect, useState } from "react";
import MoneyOnTheWay from "pages/user/MoneyOnTheWay";
import MiCredito from "components/nuovo/MiCredito";
import redux from "reducers/utils/Redux";
import { LoaderBasic } from "components/LoaderBasic";
import React from "react";

function CreditoPage({ getMyCredit, globalEffectUpdate, creditRenewal }) {
  const [payments, setPayments] = useState([]);
  const [credit, setCredit] = useState(null);

  useEffect(() => {
    async function fetchData() {
      let { data, status } = await getMyCredit();
      if (data && status === 200) {
        setCredit(data);

        // Ordenar los pagos correctamente
        const unOrderedPayments = data.payments;
        const orderedPayments = unOrderedPayments.sort((a, b) => {
          const dateA = new Date(a.payment_date);
          const dateB = new Date(b.payment_date);
          return dateA - dateB;
        });

        setPayments(orderedPayments);
      }
    }
    fetchData();
  }, [getMyCredit]);

  if (credit === null) {
    return <LoaderBasic />;
  }

  if (!credit.has_been_withdrawn) {
    return <MoneyOnTheWay />;
  }

  // Cálculo de tasa de interés
  const tasaInteres = (0.8966 / 52) * 12;
  let today = new Date();

  // Formatear las fechas correctamente
  const formatPaymentDate = (dateString) => {
    let date = new Date(dateString);
    date = new Date(date.setDate(date.getDate() + 1));
    const isToday = today.toDateString() === date.toDateString();

    // Usar la fecha de hoy si coincide con el pago
    return isToday
      ? `${String(today.getDate()).padStart(2, "0")}/${String(
          today.getMonth() + 1
        ).padStart(2, "0")}/${today.getFullYear()}`
      : `${String(date.getDate()).padStart(2, "0")}/${String(
          date.getMonth() + 1
        ).padStart(2, "0")}/${date.getFullYear()}`;
  };

  // Buscar el próximo pago, o usar la fecha de hoy si no hay pagos futuros
  const next_payment = payments?.find(
    (element) => Date.parse(element?.payment_date) >= today && !element.is_paid
  );

  let nextPaymentDate;
  if (next_payment) {
    nextPaymentDate = formatPaymentDate(next_payment.payment_date);
  } else {
    nextPaymentDate = formatPaymentDate(today);
  }

  const interesIVA = credit?.amount * tasaInteres * 0.16;
  const pagados = payments?.filter((pago) => pago.is_paid).length;
  const { is_paid, external_id } = credit;

  return (
    <MiCredito
      external_id={external_id}
      globalEffectUpdate={globalEffectUpdate}
      nextPaymentDate={nextPaymentDate}
      interesIVA={interesIVA}
      credit={credit}
      pagados={pagados}
      tasaInteres={tasaInteres}
      misPagos={payments}
      payment_day={credit.day_payment}
      is_paid={is_paid}
      creditRenewal={creditRenewal}
    />
  );
}

export default redux(CreditoPage);
