import {
  Center,
  Container,
  Text,
  UnorderedList,
  ListItem,
} from "@chakra-ui/react";
import React from "react";

function TerminosCondiciones() {
  return (
    <>
      <Center m={5} fontSize={"4xl"} color={"blue.700"} fontWeight="normal">
        Términos y Condiciones de uso del sitio web
      </Center>
      <Container mb={10} minW={"95vw"}>
        <Text align={"justify"}>
          PROMOTORA BUND, SOCIEDAD ANÓNIMA PROMOTORA DE INVERSIÓN DE CAPITAL
          VARIABLE (en adelante “BUND”), ofrece al público en general los
          productos y servicios financieros disponibles en este portal (el
          “Portal”), sujeto a los siguientes términos y condiciones. <br />
          <br />
          <Text as="b">
            SI NO ESTÁS DE ACUERDO CON LOS TÉRMINOS Y CONDICIONES DEL PRESENTE
            CONTRATO, NO NAVEGUE A TRAVÉS DE ESTE PORTAL.
          </Text>{" "}
          <br />
          <br />
          <Text as="b">1. DEFINICIONES</Text> <br />
          Para los efectos del presente Contrato, los términos escritos con
          inicial mayúscula en el texto de este documento, son términos
          definidos y tendrán el significado que correlativamente se les asigna
          a continuación, independientemente de que sean empleados en singular o
          en plural, salvo que de manera específica se les atribuya un
          significado distinto:
          <br />
          <Text as="b">Banners</Text> significan todas las impresiones que se
          incluyan en cualquier Sitio, mismas que contendrán publicidad
          relacionada con los Servicios o bien, los bienes y/o servicios
          comercializados por cualquier Tercero. <br />
          <Text as="b">BUND</Text> significa la sociedad denominada PROMOTORA
          BUND, SOCIEDAD ANÓNIMA PROMOTORA DE INVERSIÓN DE CAPITAL VARIABLE y/o
          sus subsidiarias, afiliadas y/o causahabientes.
          <br />
          <Text as="b">Cookies</Text> significan los archivos de datos que se
          almacenan en el disco duro de la computadora del Usuario cuando éste
          tiene acceso al Portal.
          <br />
          <Text as="b">Condiciones Particulares</Text> tiene el significado que
          a dicho término se le asigna en la sección 13 del presente Contrato.{" "}
          <br />
          <Text as="b">Contenidos</Text> significa sin limitación, todos los
          textos, imágenes, audiogramas, videogramas, gráficas, marcas y diseños
          (así como todas sus mejoras, suplementos, actualizaciones, revisiones
          y/o correcciones) incluidos en un Marco o en una Página de Internet.
          <br /> <Text as="b">Contrato</Text> significa los presentes Términos y
          Condiciones de Uso respecto del Portal, según los mismos sean
          modificados, adicionados o complementados, de tiempo en tiempo.
          <br />
          <Text as="b">Contrato de Crédito</Text> significa el contrato por
          virtud del cual se perfeccione el otorgamiento de cualquier Crédito
          por parte de BUND en favor de cualquier Usuario, mismo que será
          celebrado por las Partes en términos sustancialmente iguales al
          formato de contrato de crédito que BUND publique en el Portal. <br />
          <Text as="b">Crédito</Text> significa cualquier crédito que en su
          caso, sea otorgado por BUND al Usuario, previo cumplimiento de los
          requisitos establecidos al efecto en el presente Contrato, en el
          Portal y en el Contrato de Crédito que al efecto celebren las Partes.
          <br />
          <Text as="b">Destinatario </Text>significa la persona designada por
          cualquier persona para recibir un Mensaje de Datos. <br />
          <Text as="b">Firma Electrónica</Text> significa, de conformidad con lo
          previsto en el artículo 89 del Código de Comercio, los datos en forma
          electrónica consignados en un Mensaje de Datos, o adjuntados o
          lógicamente asociados al mismo por cualquier tecnología, que sean
          utilizados para identificar al Firmante en relación con el Mensaje de
          Datos e indicar que el Firmante aprueba la información contenida en el
          Mensaje de Datos, y que produce los mismos efectos jurídicos que la
          firma autógrafa, siendo admisible como prueba en juicio. <br />
          <Text as="b">Firmante</Text> significa la persona que posee los datos
          para la creación de la Firma Electrónica y que actúa en nombre propio
          o de la persona que representa, datos que pueden consistir, en forma
          enunciativa más no limitativa, claves de acceso, contraseñas
          personales y/o números confidenciales. <br />
          <Text as="b">Internet</Text> significa el conjunto global de redes de
          cómputo que opera como un sistema integrado de comunicación pública de
          acceso o difusión masiva, que permite a sus usuarios el intercambio de
          información y el acceso a contenidos almacenados en una computadora
          remota utilizando el protocolo TCP/IP.
          <br /> <Text as="b">Marco</Text> significa el recuadro, utilizado
          generalmente para fines publicitarios y/o con el objeto de modificar
          la imagen de una Página de Internet, sobrepuesta a dicha Página de
          Internet, permitiendo visualizar la misma fuera del dominio principal
          en que se encuentre hospedada. <br />
          <Text as="b">Mensaje de Datos</Text> significa la información
          generada, enviada, recibida o archivada por medios electrónicos,
          ópticos o cualquier otra tecnología. <br />
          <Text as="b">México</Text> significa, los Estados Unidos Mexicanos.{" "}
          <br />
          <Text as="b">Política de Privacidad</Text> significa, conjuntamente,
          los lineamientos adoptados unilateralmente por BUND (según los mismos
          sean modificados de tiempo en tiempo), incluyendo el Aviso de
          Privacidad, y que forman parte integrante del presente Contrato, cuyo
          propósito es precisamente, asegurar la protección de la información
          personal proporcionada por de cada uno de los Usuarios a BUND a través
          del Portal. <br />
          <Text as="b">Servicios</Text> significa, conjuntamente, cualesquiera
          servicios que sean ofrecidos al Usuario, por parte de BUND, a través
          del Portal. <br />
          <Text as="b">Solicitud de Crédito</Text> significa el documento por
          virtud del cual el Usuario solicita a BUND el otorgamiento de un
          Crédito, misma que será debidamente requisitada por el Usuario y
          enviada por éste a BUND, a través del Portal. <br />
          <Text as="b">Terceros</Text> significa, cualquier persona distinta a
          las Partes.
          <br /> <Text as="b">Página</Text>significa cualquier documento o
          diseño electrónico en formato HTML o cualquier otro formato sucesivo
          al mismo, accesible a través de Internet. <br />
          <Text as="b">Página de Inicio</Text> significa la Página de Internet
          principal del Portal, a la que el Usuario accede inmediatamente, al
          ingresar a la dirección URL en la que está hospedado dicho Portal.
          <br /> <Text as="b">Sitio</Text> significa un conjunto de Páginas de
          Internet ubicadas o identificadas bajo un mismo dominio principal URL{" "}
          <br />
          <Text as="b">Parte que Confía</Text> significa, de conformidad con lo
          previsto en el artículo 89 del Código de Comercio, la persona que,
          siendo o no el Destinatario, actúa sobre la base de un Certificado o
          de una Firma Electrónica.
          <br /> <Text as="b">Partes</Text>significa, conjuntamente BUND y el
          Usuario.
          <br /> <Text as="b">Portal</Text> significa el Portal, que diseña,
          publica y hospeda directa o indirectamente BUND bajo el dominio global
          URL www.bund.com.mx <br />
          <Text as="b">URL</Text> significa, por sus siglas en inglés Uniform
          Resource Locator. <br />
          <Text as="b">Usuario</Text> significa toda persona que accede al
          Portal en su carácter de destinatario final, con el propósito de
          recibir o no de BUND, cualesquiera Servicios. <br />
          <Text as="b">Vínculo</Text>significa el hipervínculo inanimado que
          aparece intermitentemente con una alta prioridad en cualquier Página,
          consistente en el símbolo o icono que represente un acceso directo al
          Portal o bien, a la Página o Sitio de cualquier Tercero y cuyo diseño
          podrá contener cualquier signo distintivo cuya titularidad corresponda
          al propietario de la Página o Sitio destino del citado hipervínculo.
          <br />
          <Text as="b">
            2. ACCESO AL PORTAL Y ACEPTACIÓN DE LOS TÉRMINOS Y CONDICIONES DE
            USO
          </Text>
          <br />
          El acceso a, o la utilización del Portal, por parte del Usuario,
          manifiesta indubitablemente la adhesión plena y sin reservas de este
          último a los términos y condiciones previstos en el presente Contrato.
          A través del Portal, el Usuario accederá y/o utilizará los Servicios y
          Contenidos, puestos a disposición de los Usuarios por BUND y/o por
          Terceros proveedores de servicios y Contenidos. BUND tendrá el derecho
          a negar, restringir o condicionar al Usuario el acceso al Portal,
          total o parcialmente, a su entera discreción, así como a modificar los
          Servicios y Contenidos ofrecidos o desplegados, según aplique, a
          través del Portal, en cualquier momento y sin necesidad de aviso
          previo. Todos los derechos no conferidos expresamente al Usuario
          conforme al presente Contrato, se entenderán como prohibiciones a
          cargo de éste y por tanto, el ejercicio de tales derechos estará
          reservado exclusivamente a BUND.
          <br />{" "}
          <Text as="b">3. DERECHOS DE AUTOR Y PROPIEDAD INDUSTRIAL </Text>
          <br />
          Los derechos de propiedad intelectual respecto de los Servicios y
          Contenidos y los signos distintivos y dominios prestados a través de,
          contenidos en o atingentes a, el Portal, así como los derechos de uso
          y explotación de los mismos, incluyendo en forma enunciativa pero no
          limitativa, su divulgación, modificación, transmisión, publicación,
          venta o comercialización, reproducción y/o distribución, son propiedad
          exclusiva de BUND. El Usuario no adquiere derecho alguno de propiedad
          intelectual por el simple uso de los Servicios y Contenidos del Portal
          y en ningún momento dicho uso será considerado como una autorización o
          una licencia para utilizar los Servicios y Contenidos con fines
          distintos a los expresamente previstos en los presentes Términos y
          Condiciones de Uso. En consecuencia, el Usuario EN NINGÚN CASO estará
          facultado para crear nuevas versiones, distribuir, exhibir o de
          cualquier forma explotar, cualquiera de los Contenidos desplegados a
          través de este Portal y que son propiedad exclusiva de BUND o, de
          cualesquiera Terceros y que sean utilizados por BUND bajo licencia de
          sus respectivos titulares. Los elementos del Portal están protegidos
          por “trade dress” (la apariencia comercial) entre otras leyes y
          consecuentemente, no pueden ser imitados o reproducidos en todo o en
          parte y constituyen, indistintamente, propiedad de BUND, derechos
          licenciados a favor de BUND, o utilizados por ésta conforme a los
          principios de “Fair Use”. Las marcas, diseños, gráficas, sonidos o
          imágenes del Portal, propiedad de BUND, pueden ser reproducidas o
          distribuidas, únicamente cuando medie la autorización previa y por
          escrito de BUND. <br />
          <Text as="b">4. USOS PERMITIDOS </Text>
          <br />
          <Text as="b">4.1.</Text> Salvo indicación contraria en el Portal, el
          Usuario estará facultado para ver, imprimir, copiar y distribuir los
          documentos desplegados en el mismo, siempre que cumplan con las
          siguientes condiciones:{" "}
          <UnorderedList pl={10}>
            {" "}
            <ListItem>
              Que el documento sea utilizado única y exclusivamente con
              propósitos informativos, personales y no-comerciales;{" "}
            </ListItem>
            <ListItem>
              {" "}
              Que cualquier copia del documento o cualquier porción del mismo,
              incluya en forma ostensible la información relativa a los derechos
              de propiedad intelectual y/o industrial de los mismos, en la misma
              forma en que dicha información sea expresada en el documento
              original desplegado en el Portal, y{" "}
            </ListItem>
            <ListItem>
              Que el documento no pueda ser modificado en forma alguna.
            </ListItem>
          </UnorderedList>
          <Text as="b">4.2.</Text> BUND se reserva el derecho de revocar la
          autorización a que se refiere el inciso 4.1 anterior en cualquier
          tiempo, y por tanto, el uso por parte del Usuario deberá interrumpirse
          inmediatamente a partir del momento en que reciba la notificación
          correspondiente de parte de BUND.
          <br /> <Text as="b">4.3.</Text> El aprovechamiento de los Servicios y
          Contenidos ofrecidos o desplegados en el Portal, es exclusiva
          responsabilidad del Usuario, quien en todo caso, deberá servirse de
          ellos acorde a las funcionalidades permitidas en el propio Portal y a
          los usos autorizados en el presente Contrato, por lo que el Usuario se
          obliga a utilizarlos de modo tal que no atenten contra las normas de
          uso y convivencia en Internet, las leyes de los Estados Unidos
          Mexicanos y la legislación vigente en el país en que el Usuario se
          encuentre al usarlos, las buenas costumbres, la dignidad de la persona
          y los derechos de Terceros. El Portal es para el uso personal y del
          Usuario por lo que en ningún caso podrá comercializar de manera alguna
          los Servicios y Contenidos. <br />
          <Text as="b">5. PROHIBICIONES</Text>
          <br /> El Usuario en ningún caso estará facultado para: <br />
          <Text as="b">5.1.</Text> Utilizar el Portal para obtener información
          confidencial para fines distintos que el de acceder a información
          respecto de los Servicios, o para fines de solicitar negocios de otros
          Usuarios del Portal;
          <br />
          <Text as="b">5.2.</Text> Revender o redistribuir la información
          contenida en el Portal; <br />
          <Text as="b">5.3.</Text> Promover o anunciar la habilidad de proveer
          productos o servicios que el Usuario no tenga la intención de proveer
          o que sean ilícitos o prácticamente imposibles de proveer sin contar
          con un plazo razonable para tales efectos;
          <br /> <Text as="b">5.4.</Text> Usar este Portal o cualquiera de la
          información disponible en el mismo, de cualquier forma que pudiera
          resultar ilícita, ilegal o que constituya una violación a las leyes de
          México, ya sean federales, estatales o municipales. <br />{" "}
          <Text as="b">5.5.</Text> Copiar o almacenar cualquier Contenido con
          propósitos diferentes de los mencionados expresamente en la sección 4.
          que antecede, sin contar con la autorización previa y por escrito de
          BUND, o en su caso, del titular de los derechos de autor de que se
          trate. <br />
          <Text as="b">6. SERVICIOS DE TERCEROS</Text> <br />
          El Portal, podrá contener Vínculos a los Sitios de cualesquiera
          Terceros que no están bajo control de BUND y en tal virtud, esta
          última no hace declaración alguna respecto de los Sitios de
          cualesquiera Terceros o los Contenidos desplegados en los mismos.
          Cuando el Usuario ingrese a cualquier Sitio que no pertenezca a BUND,{" "}
          <Text as="b">LO HARÁ BAJO SU PROPIO RIESGO</Text> y por tanto, BUND no
          será responsable de la confiabilidad de cualquier información,
          opinión, consejo o enunciado contenido en los Sitios de cualquier
          Tercero. El Portal refiere productos que son ofrecidos por, o marcas
          cuya titularidad pertenece a, Terceros, dentro o mediante enlaces al
          Portal, tales como Vínculos o Banners. BUND se limita exclusivamente,
          para conveniencia del Usuario a: (i) informar al Usuario sobre la
          existencia de los mismos y (ii) a proporcionar un medio para poner al
          Usuario en contacto con los proveedores de tales servicios. Los
          productos y/o servicios que se comercializan dentro del Portal y/o en
          los Sitios de Terceros enlazados, son suministrados por entidades
          independientes a BUND y por ende, esta última no es ni podrá ser
          considerada, como proveedor de tales productos y/o servicios. La
          inclusión en el Portal, por parte de BUND, de las Páginas o Sitios por
          virtud de los cuales cualesquiera Terceros ofrezcan al público
          cualesquiera productos y/o servicios, o de cualesquiera enlaces a
          dichas Páginas o Sitios, según corresponda, no implica aprobación,
          respaldo, patrocinio, recomendación o garantía alguna, por parte de
          BUND, respecto de los productos y/o servicios comercializados a través
          de dichas Páginas o Sitios por parte de sus respectivos titulares, ni
          del contenido de tales Páginas o Sitios. No existe relación laboral
          alguna, asociación en participación o sociedad alguna entre BUND y los
          Terceros proveedores de los referidos productos y/o servicios. Toda
          asesoría, consejo, declaración, información y/o Contenido otorgado por
          o desplegado en, las Páginas o Sitios de cualesquiera Terceros,
          enlazadas a, o desplegadas dentro del Portal, representan las
          opiniones y juicios de dichos Terceros y consecuentemente, BUND en
          ningún caso será responsable de cualesquiera daños y/o perjuicios que
          sufra el Usuario como consecuencia de los mismos, incluyendo en forma
          enunciativa pero no limitativa, los daños causados al Usuario por la
          pérdida de datos o cualquier información electrónica. BUND no
          garantiza en forma alguna, la certeza, suficiencia, veracidad y/o
          integridad de la información u otorga garantía alguna expresa o
          implícita, respecto de los resultados que sean obtenidos con motivo
          del uso, por parte del Usuario de la información referida en el
          párrafo anterior. <br />
          <Text as="b">
            7. GARANTÍAS Y RENUNCIAS; LÍMITES DE LA RESPONSABILIDAD. EL USUARIO
            EXPRESAMENTE RECONOCE Y ACEPTA QUE EL USO DE ESTE PORTAL ES BAJO SU
            PROPIO RIESGO.
          </Text>{" "}
          <br /> Y en consecuencia, BUND no será responsable por cualesquiera
          daños de carácter especial, incidental directos o indirectos
          (incluyendo de manera enunciativa pero no limitativa, aquéllos que
          resulten de: (i) la relevancia de los materiales presentados, (ii) los
          costos por reemplazo de bienes, (iii) la perdida de uso, información o
          utilidades, (iv) las demoras o interrupciones comerciales, (v) y
          cualquier teoría de responsabilidad, que surja con motivo de, o esté
          relacionada con, el uso o desempeño de la información,
          independientemente de que BUND haya notificado o no, la posible
          causación de dichos daños. Este Portal puede incluir fallas incluyendo
          aquéllas de carácter técnico. Periódicamente se efectuarán cambios a
          la información contenida en el Portal.
          <br />
          <Text as="b"> 8. DISPONIBILIDAD DE PRODUCTOS Y SERVICIOS</Text>
          <br /> Este Portal puede ser accesado desde diversos países alrededor
          del mundo y puede contener referencias o vínculos a los Servicios y
          Contenidos, que no han sido anunciados o autorizados en tales
          jurisdicciones. Dichas referencias en ningún caso implicarán que BUND
          pretenda anunciar o hacer disponibles dichos Servicios y Contenidos en
          tales países. Los Servicios ofrecidos por BUND a través del Portal
          serán válidos única y exclusivamente, en el territorio de México y
          sujeto a la disponibilidad ofrecida por BUND en las entidades
          federativas de la República Mexicana, con base en el plan de cobertura
          de prestación de los Servicios de BUND, mismo que podrá ser modificado
          unilateralmente por esta última a su entera y absoluta discreción, sin
          necesidad de aviso previo. Asimismo, los Servicios ofrecidos por BUND
          a través del Portal están dirigidos única y exclusivamente a personas
          físicas. No existe la garantía de que el Portal será accesible en un
          momento o momentos determinados. <br />
          <Text as="b">9. OBLIGACIONES DE BUND</Text>
          <br /> La pre-aprobación de cualquier Solicitud de Crédito a través
          del Portal, no generará obligación alguna a cargo de BUND, toda vez
          que la aprobación definitiva de la misma, en todo momento estará
          sujeta a (i) la verificación, por parte de esta última, de la
          información proporcionada por el Usuario a través del Portal, mediante
          la entrega de la documentación que al efecto requiera BUND al Usuario,
          ya sea mediante un aviso publicado o de cualquier otra forma
          desplegado en el Portal, o bien, por conducto de un Mensaje de Datos
          enviado al Usuario, o por cualquier otro medio que BUND juzgue
          conveniente y (ii) la celebración por escrito por parte del Usuario y
          BUND del Contrato de Crédito respectivo en el que se hagan constar las
          condiciones generales aplicables al Crédito que en su caso, otorgue
          BUND al Usuario. En tal virtud y hasta en tanto no se haya
          perfeccionado el otorgamiento del Crédito de que se trate en favor del
          Usuario respectivo, este último reconoce que la información
          relacionada a las Condiciones Generales del Crédito obtenida a través
          del Portal tendrá, únicamente, efectos ilustrativos y por tanto (i) NO
          representará ofrecimiento formal alguno por parte de BUND, (ii) NO
          implicará que el Crédito haya sido autorizado o que los cálculos en
          ella contenidos sean exactos, ni (iii) establecerá o creará obligación
          alguna a cargo de BUND. El Usuario reconoce que las condiciones de
          crédito obtenidas por éste a través del Portal, están sujetas a cambio
          sin previo aviso. <br />
          <Text as="b">10. COOKIES </Text>
          <br /> El Usuario que tenga acceso al Portal, acuerda recibir
          las Cookies que sean transmitidas por los servidores de BUND.
          Asimismo, el Usuario reconoce expresamente que las Cookies pueden
          contener información relativa a la identificación proporcionada por el
          Usuario o información para rastrear las Páginas que el Usuario ha
          visitado dentro del Portal, en el entendido, sin embargo, de que
          una Cookie no puede leer los datos o información del disco duro del
          Usuario, ni leer las Cookies creadas por otros Sitios o Páginas.{" "}
          <br />
          <Text as="b">11. CLAVES DE ACCESO Y NÚMEROS CONFIDENCIALES </Text>En
          todo momento, el Usuario será el único responsable de mantener en
          secreto el número de sus cuentas, su clave de acceso, contraseñas
          personales y números confidenciales con los cuales tenga acceso al
          Portal y consecuentemente, a los Servicios y Contenidos ofrecidos por
          BUND a través del mismo, así como a las Páginas o Sitios de Terceros.
          En tal virtud, el Usuario reconoce que dichas claves de acceso,
          contraseñas personales y números confidenciales, tienen el carácter de
          una Firma Electrónica conforme a lo previsto en los artículos 89 y
          siguientes del Código de Comercio. En virtud de lo anterior, BUND
          actuará en todo momento como una Parte que Confía, al tiempo de
          recibir cualquier Mensaje de Datos o instrucción por parte del Usuario
          a través del Portal, cuando éste hubiese accesado al mismo mediante el
          uso de tales claves de acceso, contraseñas personales y/o números
          confidenciales y en consecuencia:
          <UnorderedList pl={10}>
            {" "}
            <ListItem>
              {" "}
              BUND, actuará conforme a tales instrucciones recibidas a través
              del Portal o cualquier Mensaje de Datos, independientemente de que
              dicha instrucción hubiese sido girada por cualquier Tercero
              distinto del Usuario, mediante el uso de las referidas claves de
              acceso, contraseñas personales y/o números confidenciales; y
            </ListItem>{" "}
            <ListItem>
              {" "}
              el Usuario quedará obligado a cumplir cabalmente con cualesquiera
              obligaciones que a su cargo, hubiesen nacido con motivo de la
              implementación, por parte de BUND, de las instrucciones recibidas
              a través del Portal o cualesquiera Mensajes de Datos, mediante el
              uso de tales claves de acceso, contraseñas personales y/o números
              confidenciales.{" "}
            </ListItem>
          </UnorderedList>
          Por tal motivo, el Usuario deberá cambiar periódicamente sus claves de
          acceso, contraseñas personales y/o números confidenciales, a efecto de
          reducir los riesgos de que cualquier Tercero llegare a tener
          conocimiento de las mismas.
          <br />
          <Text as="b"> 12. LIGAS AL PORTAL</Text> <br />
          BUND en este acto autoriza a cualquier persona para incluir un Vínculo
          al Portal, sujeto al cumplimiento de los términos y condiciones
          previstos en esta cláusula. En cualquier caso, el Sitio que ligue al
          Usuario con el Portal: <br />
          <Text as="b">12.1.</Text>Únicamente podrá ligar al Usuario al Portal,
          pero en ningún caso podrá replicar o de cualquier otra forma
          reproducir, el Contenido del Portal; <br />
          <Text as="b">12.2.</Text>No podrá crear un Marco (frame) en el
          navegador con el Contenido del Portal y deberá únicamente ligar a la
          Página de Inicio del Portal; <br />
          <Text as="b">12.3.</Text> No podrá exhibir información falsa respecto
          de los productos o Servicios ofrecidos en a través del Portal; <br />
          <Text as="b">12.4.</Text> No deberá simular relación alguna con BUND;{" "}
          <br />
          <Text as="b">12.5.</Text> No deberá implicar que BUND está
          patrocinando otros productos y servicios, distintos de aquéllos
          ofrecidos a través del Portal; <br />
          <Text as="b">12.6.</Text> No podrá utilizar los diseños, logotipos ni
          cualquier propiedad intelectual y/o industrial de BUND sin obtener el
          consentimiento previo y por escrito de esta última; <br />
          <Text as="b">12.7.</Text> No deberá desplegar información o Contenidos
          que puedan ser considerados como obscenos, difamatorios, pornográficos
          o inapropiados para todas las edades; <br />
          <Text as="b">12.8.</Text> No deberá contener materiales que
          transgredan las leyes;
          <br />
          <Text as="b">12.9.</Text> Deberá consentir que ese Vínculo será
          removido en cualquier tiempo a petición de BUND, de conformidad con
          los derechos que esta última se reserva para rescindir la autorización
          que permita la existencia de dicho Vínculo. <br />
          <Text as="b">13. MODIFICACIONES</Text>
          <br /> BUND tendrá el derecho de modificar unilateralmente y en
          cualquier momento, parte o la totalidad de los presentes Términos y
          Condiciones. En consecuencia, el Usuario debe leer atentamente los
          Términos y Condiciones de Uso cada vez que pretenda accesar al, o
          utilizar el Portal. Ciertos Servicios y Contenidos ofrecidos a los
          Usuarios en y/o a través del Portal, están sujetos a condiciones
          particulares propias que sustituyen, complementan, y/o modifican,
          según aplique, los presentes Términos y Condiciones de Uso (en lo
          sucesivo, las “Condiciones Particulares”). En consecuencia, el Usuario
          también deberá leer atentamente, las Condiciones Particulares
          relacionadas a cualesquiera Servicios, antes de accesar a los mismos.
          <br />
          <Text as="b">14. COMUNICACIONES</Text>
          <br /> BUND, sus accionistas, afiliadas, subsidiarias y
          causahabientes, se reservan el derecho de enviar comunicaciones al
          Usuario que contengan información promocional de productos y/o
          servicios ofrecidos por estas últimas, situación que es en este acto
          consentida expresamente por el Usuario y en tal virtud, el Usuario
          autoriza a BUND para enviar dichas comunicaciones por cualquier medio
          electrónico o físico, para los efectos previstos en el artículo 18 Bis
          de la Ley Federal de Protección al Consumidor. Asimismo, BUND, sus
          accionistas, afiliadas, subsidiarias y causahabientes, podrán pero no
          estarán obligadas a, enviar cualesquiera comunicaciones al Usuario, ya
          sea por medio de un Mensaje de Datos a la dirección de correo
          electrónico proporcionada a BUND por parte del Usuario, por correo
          ordinario o por cualquier otro medio, cuyo propósito sea informar al
          Usuario cualquier cambio o modificación al Portal o a los Términos y
          Condiciones de Uso, comunicaciones que el Usuario en este acto acepta
          recibir. Cualquier revelación de información relativa al Usuario por
          parte de BUND, se sujetará a las disposiciones previstas en la Ley
          Federal de Protección al Consumidor, en la Ley de Protección al
          Usuario de Servicios Financieros, en cualquier otra disposición legal
          o reglamentaria que fuere aplicable a BUND o a los Servicios prestados
          por esta última a través del Portal y a los términos previstos en la
          Política de Privacidad de BUND. El Usuario conviene en reportar a BUND
          cualquier violación a este Contrato incluyendo violaciones en materia
          de derechos de autor y propiedad industrial, tan pronto como tenga
          conocimiento de dichas violaciones. En caso de suscitarse cualquier
          reclamación relativa a violaciones de derechos de autor, respecto de
          los materiales contenidos en este Portal, favor de notificar a la
          Unidad Especializada de Atención a Usuarios con domicilio en Avenida
          Narciso Mendoza 43-7, Naucalpan de Juárez, Estado de México, 53910,
          número telefónico: (55)2341-2346, correo
          electrónico: contacto@bund.com.mx
          <br />
          <Text as="b">15. LEY APLICABLE Y JURISDICCIÓN </Text>
          <br />
          El presente Contrato se rige por las leyes de México. Para la
          interpretación, cumplimiento y ejecución del presente Contrato, las
          Partes se someten a los tribunales competentes de la Ciudad de México,
          renunciando expresamente a cualquier otro fuero que por razón de sus
          domicilios, presentes o futuros, o por cualquier otra causa, pudiere
          corresponderles. BUND no realiza declaración u otorga garantía alguna
          en el sentido de que la información contenida en este Portal sea
          apropiada o disponible para ser utilizada en todos los países o
          jurisdicciones, y prohíbe accesar materiales desde territorios en
          donde los Servicios y Contenidos del Portal sean ilícitos. Aquellos
          Usuarios que accesen a este Portal, lo hacen por iniciativa propia y
          serán responsables de cumplir con las leyes aplicables en los lugares
          desde los cuales accesen al Portal.
          <br />
          FECHA DE ÚLTIMA ACTUALIZACIÓN: 12 de enero de 2023.
        </Text>
      </Container>
    </>
  );
}

export default TerminosCondiciones;
